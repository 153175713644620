import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {EntryCardMediumComponentModule} from '../../components/entry-card-medium/entry-card-medium.component.module';
import {EntryCardSmallComponentModule} from '../../components/entry-card-small/entry-card-small.component.module';
import {EntryDetailsLargeComponentModule} from '../../components/entry-details-large/entry-details-large.component.module';
import {FloatingCardComponentModule} from '../../components/floating-card/floating-card.component.module';
import {FloatingFooterComponentModule} from '../../components/floating-footer/floating-footer.component.module';
import {FloatingHeaderComponentModule} from '../../components/floating-header/floating-header.component.module';
import {FooterSpacerComponentModule} from '../../components/footer-spacer/footer-spacer.component.module';
import {HeaderSpacerComponentModule} from '../../components/header-spacer/header-spacer.component.module';
import {ImagePreloaderComponentModule} from '../../components/image-preloader/image-preloader.component.module';
import {LoadingComponentModule} from '../../components/loading/loading.component.module';
import {MapComponentModule} from '../../components/map/map.component.module';
import {SliderCardComponentModule} from '../../components/slider-card/slider-card.component.module';
import {SlideshowBackgroundComponentModule} from '../../components/slideshow-background/slideshow-background.component.module';
import {DayPage} from './day-page.page';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FloatingFooterComponentModule,
        FloatingCardComponentModule,
        LoadingComponentModule,
        EntryCardSmallComponentModule,
        SliderCardComponentModule,
        HeaderSpacerComponentModule,
        FloatingHeaderComponentModule,
        FooterSpacerComponentModule,
        SlideshowBackgroundComponentModule,
        EntryDetailsLargeComponentModule,
        EntryCardMediumComponentModule,
        ImagePreloaderComponentModule,
        RouterModule.forChild([
            {
                path: '',
                component: DayPage,
            },
        ]),
        MapComponentModule,
    ],
  declarations: [DayPage],
})
export class DayPageModule { }
