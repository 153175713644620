import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AssetService } from '../../services/asset.service';

@Component({
  selector: 'app-image-icon',
  templateUrl: './image-icon.component.html',
  styleUrls: ['./image-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageIconComponent implements OnInit {

  @Input()
  icon = 'missing-image';

  constructor(readonly assets: AssetService) { }

  ngOnInit() {
  }

  getBackground() {
    return this.assets.getSvgPath('tripigo-background-blue');
  }
}
