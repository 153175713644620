import { Crud, Entry, triggerSingleEffect } from '@didgigo/lib-ts';
import { Option } from 'funfix-core';
import { Set } from 'immutable';
import { from, Observable } from 'rxjs';
import { UpdatingStorage } from './updating-storage';

export class StorageCache extends Crud<string, string> {

  constructor(readonly storage: UpdatingStorage) {
    super();
  }

  delete(k: string): Promise<Option<string>> {
    return this.storage.remove(k);
  }

  deleteAll(): Promise<void> {
    return this.storage.clear();
  }

  entries(): Promise<Set<Entry<string, string>>> {
    return this.storage.entries();
  }

  keys(): Promise<Set<string>> {
    return this.storage.keys();
  }

  observe(k: string): Observable<Option<string>> {
    return this.storage.observe(k);
  }

  set(k: string, v: string): Promise<Option<string>> {
    return this.storage.set(k, v);
  }
}
