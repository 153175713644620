import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {ImageBackgroundComponentModule} from '../../components/image-background/image-background.component.module';
import {LandingPage} from './landing.page';

@NgModule({
  imports: [
    ImageBackgroundComponentModule,
    CommonModule,
    IonicModule,
    RouterModule.forChild([
      { path: '', component: LandingPage },
    ]),
  ],
  declarations: [LandingPage],
})
export class LandingPageModule { }
