import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EntryTypeFilter, parseDate, parseNumber, ProposalWithMeta} from '@didgigo/lib-ts';
import {Option} from 'funfix-core';
import {Moment} from 'moment';
import {MapComponent} from '../../components/map/map.component';
import {BaseComponent} from '../../lib-ionic/base-component';
import {AssetService} from '../../services/asset.service';
import {LoadingMonitorService} from '../../services/loading-monitor.service';
import {LoggingService} from '../../services/logging.service';
import {NavigatorService} from '../../services/navigator.service';
import {ProposalService} from '../../services/proposal.service';
import {UserService} from '../../services/user.service';

@Component({
    selector: 'app-proposal-map',
    templateUrl: './day-map.page.html',
    styleUrls: ['./day-map.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DayMapPage extends BaseComponent implements OnInit, AfterViewInit {

    constructor(
        private route: ActivatedRoute,
        readonly navigator: NavigatorService,
        readonly assets: AssetService,
        readonly logger: LoggingService,
        readonly loading: LoadingMonitorService,
        readonly user: UserService,
        readonly change: ChangeDetectorRef,
        readonly self: ElementRef,
        readonly proposalService: ProposalService) {
        super('day_map', change, self, loading);
    }

    @ViewChild('AgmMap', {static: false})
    agmMap: MapComponent;

    day: Option<Moment>;

    meta: ProposalWithMeta;

    async ngOnInit(): Promise<void> {
        this.logger.setPage('map');
        const ps = this.route.snapshot.params;
        this.navigator.setProposalId(ps.id);
        this.day = parseDate(ps.day);
        this.logger.logEventWithProposalAndUser('load_page', {page: 'day_map'}, parseNumber(ps.id));
        this.meta = await this.navigator.getCurrentProposalForIdNonOptional(parseNumber(ps.id).get());
        super.ngOnInit();
    }

    toggleVisible(tpe: EntryTypeFilter): void {
        const current = this.agmMap.proposalMapWhiteList.getValue();
        if (current.has(tpe)) {
            this.agmMap.setWhiteList(current.delete(tpe));
        } else {
            this.agmMap.setWhiteList(current.add(tpe));
        }
    }
}
