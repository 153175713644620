import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Company, User} from '@didgigo/lib-ts';
import {Option} from 'funfix-core';
import {Observable} from 'rxjs';
import {openCloseAnimation} from '../../animations/open-close';
import {DidgigoApiService, UserService} from '../../services';

@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss'],
    animations: [openCloseAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SideMenuComponent {

    constructor(
        readonly user: UserService,
        readonly api: DidgigoApiService,
    ) {
    }

    userState: Observable<User> = this.user.userState;

    companyLabeller: (c: Company) => string = c => c.getName().getOrElse('Missing company name');

    companyNameExtractor: (c: Company) => Option<string> = c => c.getName();

    option<T>(a: T | null | undefined): Option<T> {
        return Option.of(a);
    }
}
