import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {Plugins} from '@capacitor/core';
import {ContextualImageSet, modelThrottle, RecentProposals} from '@didgigo/lib-ts';
import {List} from 'immutable';
import {BehaviorSubject, combineLatest, Observable, ReplaySubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {BaseComponent} from '../../lib-ionic/base-component';
import {AssetService} from '../../services/asset.service';
import {BrowserService} from '../../services/browser.service';
import {LoadingMonitorService} from '../../services/loading-monitor.service';
import {LoggingService} from '../../services/logging.service';
import {NavigatorService} from '../../services/navigator.service';
import {ProposalDownloaderService} from '../../services/proposal-downloader.service';
import {ProposalService} from '../../services/proposal.service';
import {RecentsService} from '../../services/recents.service';
import {UserService} from '../../services/user.service';

const {Keyboard} = Plugins;

@Component({
  selector: 'app-admin',
  templateUrl: './admin.page.html',
  styleUrls: ['./admin.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminPage extends BaseComponent implements OnInit {

  constructor(
    readonly navigator: NavigatorService,
    readonly downloader: ProposalDownloaderService,
    readonly assets: AssetService,
    readonly logger: LoggingService,
    readonly recent: RecentsService,
    readonly user: UserService,
    readonly browser: BrowserService,
    readonly loading: LoadingMonitorService,
    readonly change: ChangeDetectorRef,
    readonly self: ElementRef,
    readonly proposalService: ProposalService) {
    super('admin_page', change, self, loading);
  }

  filter: BehaviorSubject<string> = new BehaviorSubject<string>('');
  filterObservable: Observable<string> = this.filter.pipe(modelThrottle(this.unsubscriberObs));

  recents: ReplaySubject<List<RecentProposals>> = new ReplaySubject<List<RecentProposals>>(1);
  recentsObservable: Observable<List<RecentProposals>> =
    combineLatest(this.recents.asObservable(), this.filterObservable)
      .pipe(map(arr => arr[0].filter(r => r.name.exists(n => n.toLowerCase().includes(arr[1].toLowerCase())))))
      .pipe(modelThrottle(this.unsubscriberObs));

  shouldShowRecents: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  shouldShowRecentsObservable: Observable<boolean> = this.shouldShowRecents.pipe(modelThrottle(this.unsubscriberObs));

  download(qsid: string): void {
    this.downloader.downloadByQsid(qsid);
  }

  getImages(): ContextualImageSet {
    return this.assets.getBlueBackground();
  }

  hideKeyboard(): void {
    Keyboard.hide();
  }

  ngOnInit(): void {
    const f = () => super.ngOnInit();

    this.populateRecents();

    this.logger.setPage('admin');
    this.logger.logEventWithProposalAndUser(
      'load_page',
        {page: 'admin'});
    f();
  }

  populateRecents(): void {
    this.recent.getRecents().subscribe(rs => this.recents.next(rs));
  }

  searchFor(e: any): void {
    this.filter.next(e.target.value);
  }

  showRecents(): void {
    this.shouldShowRecents.next(!this.shouldShowRecents.getValue());
  }
}
