import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ContextualImageSet, modelDebounce, ProposalWithMeta, travellerDidgigoPrefix} from '@didgigo/lib-ts';
import {Option} from 'funfix-core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {AssetService} from '../../services/asset.service';
import {ChatService} from '../../services/chat.service';

@Component({
    selector: 'app-proposal-card-medium',
    templateUrl: './proposal-card-medium.component.html',
    styleUrls: ['./proposal-card-medium.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProposalCardMediumComponent implements OnInit {

    count: Observable<Option<number>>;

    @Input()
    model: ProposalMediumCardModel;

    constructor(
        readonly assets: AssetService,
        readonly chat: ChatService) {
    }

    // HACK Use Option<Number> as 0 is treated as false
    getTravellerCount(): Observable<Option<number>> {
        return this.model.id
            .map(i => this.chat.observeTravellerCountInRoom(travellerDidgigoPrefix + i))
            .map(o => o.pipe(map(c => Option.of(c))))
            .getOrElse(of(Option.of(0)))
            .pipe(modelDebounce());
    }

    ngOnInit() {
        this.count = this.getTravellerCount();
    }
}

export class ProposalMediumCardModel {
    constructor(
        readonly id: Option<number>,
        readonly meta: ProposalWithMeta,
        readonly title: Option<string>,
        readonly reference: Option<string>,
        readonly status: Option<string>,
        readonly agentName: Option<string>,
        readonly days: Option<string>,
        readonly agentPhoto: Option<ContextualImageSet>) {
    }

    getDayRange(): Option<string> {
        return this.meta.getDisplayProposalDayRange();
    }

    getMonthRange(): Option<string> {
        return this.meta.getDisplayProposalMonthRange();
    }

    isTravelling(): boolean {
        return this.status.contains('Travelling') || this.meta.getProposal().isCurrentlyInProgress();
    }
}
