import {Injectable} from '@angular/core';
import {ContextualImageSet, Image, Url} from '@didgigo/lib-ts';
import {File} from '@ionic-native/file/ngx';
import {Platform} from '@ionic/angular';
import {None, Option, Some} from 'funfix-core';
import {AssetService} from './asset.service';
import {ConfigurationService} from './configuration.service';
import * as _s from 'underscore.string';

@Injectable({
    providedIn: 'root',
})
export class MediaService {

    constructor(
        readonly file: File,
        readonly platform: Platform,
        readonly configuration: ConfigurationService,
        readonly assets: AssetService,
    ) {
    }

    getAgentImage(fileName: string): ContextualImageSet {
        return this.getImagesAtEndpoint(new Image(Url.parse(fileName)), 'ProposalAgentImages', true, false);
    }

    getDirectionMap(image: string): ContextualImageSet {
        return this.getImagesAtEndpoint(new Image(Url.parse(image)), 'product_directions', false, false, true);
    }

    getImagesAtEndpoint(
        image: Image,
        endpoint: string,
        hasLowRes: boolean,
        hasThumbnails: boolean,
        isRaw: boolean = false): ContextualImageSet {
        return ContextualImageSet.buildFrom(
            image,
            endpoint,
            hasLowRes,
            hasThumbnails,
            isRaw,
            this.configuration.didgigoServer,
            this.imagedir());
    }

    getParticipantImage(s: string): ContextualImageSet {
        return this.getImagesAtEndpoint(new Image(Url.parse(s)), 'ParticipantImages', true, false);
    }

    getProductImage(image: Image): ContextualImageSet {
        return this.getImagesAtEndpoint(image, 'ProductImages', true, false);
    }

    getProductImageAsBackground(image: Option<Image>): ContextualImageSet {
        return image.map(i => this.getProductImage(i))
            .getOrElse(this.assets.getBlueBackground());
    }

    getProductLogo(image: Image): ContextualImageSet {
        return this.getImagesAtEndpoint(image, 'ProductLogos', true, false);
    }

    getProductMap(image: string): ContextualImageSet {
        return this.getImagesAtEndpoint(new Image(Url.parse(image)), 'Product_Maps', false, false, true);
    }

    getProposalCompanyLogo(image: string): ContextualImageSet {
        return this.getImagesAtEndpoint(new Image(Url.parse(image)), 'ProposalCompanyImages/Logos', true, false);
    }

    getProposalCompanyLogoFromImage(image: Image): ContextualImageSet {
        return this.getProposalCompanyLogo(image.getHref().getOrElse(''));
    }

    getProposalMap(image: string): ContextualImageSet {
        return this.getImagesAtEndpoint(new Image(Url.parse(image)), 'proposal_map', false, false, true);
    }

    getSupplierLogo(image: Image): ContextualImageSet {
        return this.getImagesAtEndpoint(image, 'CompanyLogos', false, false);
    }

    getWelcomeImage(image: Image): ContextualImageSet {
        return this.getImagesAtEndpoint(image, 'ProposalImages/WelcomeImages', true, false);
    }

    getWelcomeImageAsBackground(image: Option<Image>): ContextualImageSet {
        return image.map(i => this.getWelcomeImage(i))
            .getOrElse(this.assets.getBlueBackground());
    }

    imagedir(): Option<string> {
        if (this.platform.is('cordova')) {
            return Some(_s.rtrim(this.file.dataDirectory, '/'));
        } else {
            return None;
        }
    }
}
