import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {ComparisonUtils, ContextualImageSet, parseString, Proposal, ProposalWithMeta} from '@didgigo/lib-ts';
import {ActionSheetController} from '@ionic/angular';
import {BaseComponent} from '../../lib-ionic/base-component';
import {AssetService} from '../../services/asset.service';
import {LoadingMonitorService} from '../../services/loading-monitor.service';
import {LoggingService} from '../../services/logging.service';
import {MediaService} from '../../services/media.service';
import {NavigatorService} from '../../services/navigator.service';
import {ProposalDownloaderService} from '../../services/proposal-downloader.service';
import {ProposalService} from '../../services/proposal.service';
import {SizingService} from '../../services/sizing.service';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-proposals',
  templateUrl: './proposals.page.html',
  styleUrls: ['./proposals.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProposalsPage extends BaseComponent implements OnInit {

  constructor(
    readonly proposalService: ProposalService,
    readonly downloader: ProposalDownloaderService,
    readonly actionSheetController: ActionSheetController,
    readonly logging: LoggingService,
    readonly assets: AssetService,
    readonly navigator: NavigatorService,
    readonly sizing: SizingService,
    readonly loading: LoadingMonitorService,
    readonly user: UserService,
    readonly change: ChangeDetectorRef,
    readonly self: ElementRef,
    readonly mediaService: MediaService) {
    super('proposal_page', change, self, loading);
  }

  cards: ProposalWithMeta[];

  slideOpts = {
    slidesPerView: 1.2,
    autoplay: false,
    centeredSlides: true,
  };

  download(encoded: any): void {
    parseString(encoded).forEach(x => this.downloader.downloadByQsid(x));
  }

  getBackground(): ContextualImageSet {
    return this.assets.getBackground();
  }

  private async getCards(): Promise<ProposalWithMeta[]> {
    const proposals = await this.proposalService.values();
    return proposals
        .sort((a, b) => ComparisonUtils.getOptionComparator(Proposal.proposalStartComparator).compare(a.proposal, b.proposal))
        .toArray();
  }

  gotoDownload(id: string): void {
    this.navigator.setProposalId(id);
    this.navigator.gotoDownload();
  }

  gotoWelcome(id: string): void {
    this.navigator.setProposalId(id);
    this.navigator.gotoWelcome(true);
  }

  loadDemoProposal(): void {
    this.actionSheetController.create({
      header: 'Demo\'s',
      translucent: true,
      buttons: [{
        text: 'Oceania',
        cssClass: 'primaryToDark',
        handler: () => { this.gotoDownload(this.downloader.oceaniaDemo); },
      }, {
        text: 'China',
        cssClass: 'primaryToDark',
        handler: () => { this.gotoDownload(this.downloader.chinaDemo); },
      }, {
        text: 'Africa',
        cssClass: 'primaryToDark',
        handler: () => { this.gotoDownload(this.downloader.africaDemo); },
      }, {
        text: 'Canada',
        cssClass: 'primaryToDark',
        handler: () => { this.gotoDownload(this.downloader.canadaDemo); },
      }, {
        text: 'France',
        cssClass: 'primaryToDark',
        handler: () => { this.gotoDownload(this.downloader.franceDemo); },
      }, {
        text: 'Europe',
        cssClass: 'primaryToDark',
        handler: () => { this.gotoDownload(this.downloader.europeDemo); },
      }, {
        text: 'United States of America',
        cssClass: 'primaryToDark',
        handler: () => { this.gotoDownload(this.downloader.usaDemo); },
      }, {
        text: 'Tropics',
        cssClass: 'primaryToDark',
        handler: () => { this.gotoDownload(this.downloader.tropicsDemo); },
      }, {
        text: 'Roadbook New Zealand',
        cssClass: 'primaryToDark',
        handler: () => {
          this.gotoDownload(this.downloader.newZealandRoadbook);
        },
      }, {
        text: 'Roadbook Australia',
        cssClass: 'primaryToDark',
        handler: () => {
          this.gotoDownload(this.downloader.australiaRoadbook);
        },
      }, {
        text: 'Roadbook America (French)',
        cssClass: 'primaryToDark',
        handler: () => {
          this.gotoDownload(this.downloader.frenchLARoadbookDemo);
        },
      }, {
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'primaryToDanger',
        handler: () => { },
      }],
    }).then(s => s.present())
        .catch(err => console.error(err));
  }

  async ngOnInit(): Promise<void> {
    this.navigator.clearIds();
    this.cards = await this.getCards();
    this.logging.setPage('proposals');
    this.logging.logEventWithUser(
      'load_page',
      { page: 'proposals' },
      this.user.currentIdentity);
    super.ngOnInit();
  }
}
