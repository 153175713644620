import {AgmCoreModule} from '@agm/core';
import {AgmJsMarkerClustererModule} from '@agm/js-marker-clusterer';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SmartTextAreaComponentModule} from '@didgigo/lib-angular';
import {IonicModule} from '@ionic/angular';
import {AgmDirectionModule} from 'agm-direction';
import {NgxSpinnerModule} from 'ngx-spinner';
import {FooterSpacerComponentModule} from '../footer-spacer/footer-spacer.component.module';
import {ImageBackgroundComponentModule} from '../image-background/image-background.component.module';
import {SliderCardComponentModule} from '../slider-card/slider-card.component.module';
import {MapComponent} from './map.component';

@NgModule({
    imports: [
        CommonModule,
        SliderCardComponentModule,
        ImageBackgroundComponentModule,
        SmartTextAreaComponentModule,
        FooterSpacerComponentModule,
        NgxSpinnerModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBxDv_Vy8BK4HQUg8MQvGfjmtcwkCAdWD8',
        }),
        AgmJsMarkerClustererModule,
        IonicModule.forRoot(),
        AgmDirectionModule,
    ],
  declarations: [MapComponent],
  exports: [MapComponent],
})
export class MapComponentModule { }
