import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ContextualImageSet } from '@didgigo/lib-ts';

@Component({
  selector: 'app-image-background',
  templateUrl: './image-background.component.html',
  styleUrls: ['./image-background.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageBackgroundComponent implements OnInit {

  @Input()
  images : ContextualImageSet;

  constructor() { }

  ngOnInit() {
  }

}
