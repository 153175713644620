import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-floating-card',
  templateUrl: './floating-card.component.html',
  styleUrls: ['./floating-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatingCardComponent implements OnInit {

  @Input()
  clickthroughFiller = false;

  @Input()
  showShadow;

  @Input()
  noPadding;

  @Input()
  noMargin;

  constructor() { }

  ngOnInit() {
  }
}
