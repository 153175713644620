import {Injectable} from '@angular/core';
import {CallNumber} from '@ionic-native/call-number/ngx';
import {ConfigurationService} from './configuration.service';
import {MediaService} from './media.service';
import {NavigatorService} from './navigator.service';

@Injectable({
  providedIn: 'root',
})
export class ContactService {

  constructor(
    readonly callNumber: CallNumber,
    readonly navigator: NavigatorService,
    readonly config: ConfigurationService,
    readonly mediaService: MediaService) { }

  callPhone(phone: string): void {
    const number = phone.replace('[^0-9+]', '');
    this.callNumber.callNumber(number, false).catch(err => console.error(err));
  }
}
