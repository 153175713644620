import {Component, OnInit} from '@angular/core';
import {ContextualImageSet} from '@didgigo/lib-ts';
import {AssetService} from '../../services/asset.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {

  constructor(
      readonly assets: AssetService) {
  }

  getBackground(): ContextualImageSet {
    return this.assets.getBlueBackground();
  }

  ngOnInit() {
  }
}
