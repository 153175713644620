import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FooterSpacerComponent } from './footer-spacer.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule.forRoot(),
  ],
  declarations: [FooterSpacerComponent],
  exports: [FooterSpacerComponent],
})
export class FooterSpacerComponentModule { }
