import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ProposalWithMeta} from '@didgigo/lib-ts';

@Component({
  selector: 'app-proposal-card-details',
  templateUrl: './proposal-card-details.component.html',
  styleUrls: ['./proposal-card-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProposalCardDetailsComponent implements OnInit {

  constructor() { }

  @Input()
  model: ProposalWithMeta;
  
  ngOnInit(): void {
  }
}
