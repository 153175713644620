import {Injectable} from '@angular/core';
import {CollectionUtils, StringUtils} from '@didgigo/lib-ts';
import * as Color from 'color';
import {Option} from 'funfix-core';
import {List, Map, Set} from 'immutable';
import {Observable, of} from 'rxjs';

export abstract class Colored {
  abstract getColours(): List<Palette>;
}

const whiteColor = Color('#FFFFFF');
const blackColor = Color('#000000');

function contrast(c: Color): Color {
  return c.isLight() ? blackColor : whiteColor;
}

export class Palette {
  constructor(readonly name: string,
              readonly color: Color) { }

  variable = `--ion-color-${this.name}`;
  cssColor = `var(${this.variable}, ${this.color.hex()})`;

  // Mirrors Ionics color generator
  css: string =
      StringUtils.stripMargin(
      `${this.variable}: ${this.color.hex()}
        |${this.variable}-rgb: ${this.color.rgb().array()}
        |${this.variable}-contrast: ${contrast(this.color).hex()}
        |${this.variable}-contrast-rgb: ${contrast(this.color).rgb().array()}
        |${this.variable}-shade: ${this.color.darken(0.12).hex()}
        |${this.variable}-tint: ${this.color.lighten(0.1).hex()}`);

  asObject(): object {
    const obj = {};

    obj[this.variable] = this.color.hex();
    obj[`${this.variable}-rgb`] = this.color.rgb().array();
    obj[`${this.variable}-contrast`] = contrast(this.color).hex();
    obj[`${this.variable}-contrast-rgb`] = contrast(this.color).rgb().array();
    obj[`${this.variable}-shade`] = this.color.darken(0.12).hex();
    obj[`${this.variable}-tint`] = this.color.lighten(0.1).hex();
    return obj;
  }
}

// tslint:disable:no-unused-variable
export const didgigoGreen = new Palette('didgigo-green', Color('#99cc33'));
export const didgigoDarkGreen = new Palette('didgigo-dark-green', Color('#314b18'));

export const charcoal = new Palette('charcoal', Color('#48484d'));
export const ulyssesBlue = new Palette('ulysses-blue', Color('#00a1e4'));

export const tripigoCharcoal = new Palette('charcoal', Color('#454545'));
export const tripigoYellow = new Palette('tripigo-yellow', Color('#ffdd00'));
export const tripigoLightBlue = new Palette('tripigo-light-blue', Color('#4ebdec'));
export const tripigoOrange = new Palette('tripigo-orange', Color('#f68b1f'));
export const tripigoPurple = new Palette('tripigo-purple', Color('#92278f'));
// tslint:enable:no-unused-variable

export const white = new Palette('white', whiteColor);
export const black = new Palette('black', whiteColor);

export class DualTheme implements Colored {

  static fromBaseText(base: BaseTheme): DualTheme {
    return new DualTheme(base.text, base.secondary);
  }

  static fromBaseColor(base: BaseTheme): DualTheme {
    return new DualTheme(base.secondary, base.primary);
  }

  constructor(
    readonly text: Palette,
    readonly background: Palette) { }

  invert() {
    return new DualTheme(this.background, this.text);
  }

  getCss(): object {
    return {
      color: this.text.cssColor,
      background: this.background.cssColor,
    };
  }

  getColours(): List<Palette> {
    return List.of(this.text, this.background);
  }
}

export class AgentContactTheme implements Colored {
  constructor(
    base: BaseTheme,
    readonly name: Palette = base.text,
    readonly company: Palette = base.text,
    readonly number: Palette = base.text,
    readonly email: Palette = base.text) { }

  getColours(): List<Palette> {
    return List.of(this.name, this.company, this.number, this.email);
  }
}

export class WelcomeTheme implements Colored {
  constructor(
    base: BaseTheme,
    readonly header: DualTheme = DualTheme.fromBaseColor(base),
    readonly card: DualTheme = DualTheme.fromBaseText(base),
    readonly started: DualTheme = DualTheme.fromBaseText(base),
    readonly pax: DualTheme = DualTheme.fromBaseText(base),
    readonly agent: AgentContactTheme = new AgentContactTheme(base),
  ) { }

  getColours(): List<Palette> {
    return List.of<Colored>(this.header, this.card, this.started, this.pax, this.agent)
      .flatMap((c) => c.getColours());
  }
}

export class AppTheme implements Colored {
  constructor(
    base: BaseTheme,
    readonly welcome: WelcomeTheme = new WelcomeTheme(base)) { }

  getColours(): List<Palette> {
    return List.of<Colored>(this.welcome)
      .flatMap((c) => c.getColours());
  }
}

export class BaseTheme implements Colored {
  constructor(
    readonly primary: Palette,
    readonly secondary: Palette,
    readonly dark: Palette = white,
    readonly light: Palette = black,
    readonly text: Palette = dark,
    readonly background: Palette = light,
    readonly buttons: DualTheme = new DualTheme(secondary, light),
    readonly icons: DualTheme = new DualTheme(secondary, light)) { }

  getColours(): List<Palette> {
    const simple: List<Palette> =
      List.of(this.primary, this.secondary, this.dark, this.light, this.text, this.background);
    const nested = List.of<Colored>(this.buttons, this.icons)
      .flatMap((c) => c.getColours());
    return simple.concat(nested);
  }
}

export class Theme implements Colored {
  constructor(
    readonly base: BaseTheme,
    readonly app: AppTheme = new AppTheme(base)) { }

  getColours(): List<Palette> {
    return List.of<Colored>(this.base, this.app)
      .flatMap((c) => c.getColours());
  }

  getColourSet(): Set<Palette> {
    return this.getColours().toSet();
  }

  getPaletteObject(): object {
    return Object.assign({}, ...this.getColourSet().map((x) => x.asObject()).toArray());
  }

  getPaletteString(): string {
    return this.getColourSet().map((x) => x.css).reduce((a, b) => a ? a + '\n' + b : b, '');
  }
}

const tripigoBaseTheme = new BaseTheme(tripigoYellow, ulyssesBlue, tripigoCharcoal, white);

const tripigoWelcomeTheme = new WelcomeTheme(
  tripigoBaseTheme,
  DualTheme.fromBaseColor(tripigoBaseTheme),
  DualTheme.fromBaseText(tripigoBaseTheme),
  new DualTheme(tripigoBaseTheme.secondary, tripigoBaseTheme.light),
  new DualTheme(tripigoOrange, tripigoBaseTheme.light));

const tripigoTheme: Theme = new Theme(
  tripigoBaseTheme,
  new AppTheme(tripigoBaseTheme, tripigoWelcomeTheme));

@Injectable({
  providedIn: 'root',
})
export class ThemeService {

  colourMap: Map<number, [string, string]> = this.getColourMap();

  constructor() { }

  getTheme(): Observable<Theme> {
    return of(tripigoTheme);
  }

  getLoadedThemes(): Observable<List<Theme>> {
    return of(List.of(tripigoTheme));
  }

  getFGBGColourStyle(idx: number) {
    return Option.of(this.colourMap.get(idx))
      .map(([fg, bg]) => {
        return {
          'color': fg,
          'background-color': bg,
        };
      }).getOrElse({
        'color': '#FFF',
        'background-color': '#000',
      });
  }

  // Creates a map of all colours combinations in the form [1, [a, b]]
  // Ensures removal of combinations where foreground and background are equal
  getColourMap(seed: number = 321678): Map<number, [string, string]> {
    const fgcols = this.fgColours().toList();
    const bgcols = this.bgColours().toList();
    const combos: List<[string, string]> = fgcols
      .flatMap((outer) => bgcols.toList().map<[string, string]>((inner) => [outer, inner]));
    return Map(this.getIndexedColourCombinations(combos, seed));
  }

  // Verbose because typescripts type inference sucks
  // Seeded shuffle so all devices use the same colours
  private getIndexedColourCombinations(
    combinations: List<[string, string]>, seed: number): Array<[number, [string, string]]> {
    return CollectionUtils.shuffle(combinations.filter(([a, b]) => a !== b).toArray(), seed)
      .map<[number, [string, string]]>((v, k) => [k, v]);
  }

  // TODO: Make themeable?
  private fgColours(): Set<string> {
    return Set.of(
      didgigoGreen,
      tripigoLightBlue,
      tripigoOrange,
      tripigoPurple,
      tripigoYellow,
      ulyssesBlue,
      white)
      .map((c) => c.color.hex());
  }

  private bgColours(): Set<string> {
    return Set.of(
      white,
      didgigoDarkGreen,
      tripigoOrange,
      tripigoPurple,
      ulyssesBlue)
      .map((c) => c.color.hex());
  }
}
