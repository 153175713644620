import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-smart-text-area',
  templateUrl: './smart-text-area.component.html',
  styleUrls: ['./smart-text-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartTextAreaComponent {

  @Input()
  text: string;

  constructor() { }

}
