import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {parseNumber, ProposalWithMeta} from '@didgigo/lib-ts';
import {BaseComponent} from '../../lib-ionic/base-component';
import {AssetService} from '../../services/asset.service';
import {LoadingMonitorService} from '../../services/loading-monitor.service';
import {LoggingService} from '../../services/logging.service';
import {MediaService} from '../../services/media.service';
import {NavigatorService} from '../../services/navigator.service';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.page.html',
  styleUrls: ['./about.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutPage extends BaseComponent implements OnInit {

  constructor(
      readonly route: ActivatedRoute,
      readonly media: MediaService,
      readonly navigator: NavigatorService,
      readonly assets: AssetService,
      readonly logger: LoggingService,
      readonly loading: LoadingMonitorService,
      readonly user: UserService,
      readonly change: ChangeDetectorRef,
      readonly self: ElementRef,
  ) {
    super('about_page', change, self, loading);
  }

  model: ProposalWithMeta;

  async ngOnInit(): Promise<void> {
    this.logger.setPage('about');
    const ps = this.route.snapshot.params;
    this.navigator.setProposalId(ps.id);
    this.logger.logEventWithProposalAndUser('load_page', {page: 'about'}, parseNumber(ps.id));
    this.model = await this.navigator.getCurrentProposalForIdNonOptional(parseNumber(ps.id).get());
    super.ngOnInit();
  }
}
