import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DownloadType, ProposalWithMeta} from '@didgigo/lib-ts';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-download-size-modal',
    templateUrl: './download-size-modal.component.html',
    styleUrls: ['./download-size-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadSizeModalComponent {

    constructor(readonly modal: ModalController) {
    }

    @Input()
    proposalWithMeta: ProposalWithMeta;

    getInformationText(value: DownloadType): string {
        switch (value) {
            case 'Minimal':
                return 'Minimal is the same as compact, except only one image will be downloaded per hotel/tour etc.';
            case 'Compact':
                return 'Compact uses space-efficient imagery to save on download size and storage space. Wifi is highly recommended';
            case 'Optimal':
                return 'Optimal ensures ultra sharp, high quality images to ensure the most immersive experience possible. Wifi is highly recommended';
        }
    }

    getSizeText(value: DownloadType): string {
        return `Estimated download size: ${this.proposalWithMeta.getProposal().getEstimatedDownloadSize(value).get().toFixed(0)} MiB`;
    }

    select(value: DownloadType): void {
        this.modal.dismiss(value);
    }
}
