import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HighlightMatchPipe } from './highlight-match.pipe';

@NgModule({
    declarations: [
        HighlightMatchPipe,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
    ],
    exports: [HighlightMatchPipe],
})
export class HighlightMatchPipeModule {
}
