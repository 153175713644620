import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ImagePreloaderComponentModule } from '../image-preloader/image-preloader.component.module';
import { ImageBackgroundComponent } from './image-background.component';

@NgModule({
  imports: [
    CommonModule,
    ImagePreloaderComponentModule,
    IonicModule.forRoot(),
  ],
  declarations: [ImageBackgroundComponent],
  exports: [ImageBackgroundComponent],
})
export class ImageBackgroundComponentModule { }
