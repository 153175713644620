import {Injectable} from '@angular/core';
import {Crud} from '@didgigo/lib-ts';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map, take} from 'rxjs/operators';
import {StorageCacheProviderService} from './cache-provider.service';

const hiResKey = 'download_high_res';
const isMetricKey = 'is_metric';
const isAdminKey = 'is_admin';
const hiResOnMobileKey = 'download_high_res_on_mobile';

const trueString = 'true';
const falseString = 'false';

const nativeNavigator: any = navigator;

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {

  constructor(cacheProvider: StorageCacheProviderService) {
    this.cache = cacheProvider.getPrefixedCache('settings');
  }

  cache: Crud<string, string>;

  async canDownloadHighRes(): Promise<boolean> {
    const res = await this.cache.getOrElseSet(hiResKey, trueString);
    return res.contains(trueString);
  }

  async canDownloadHighResOnMobile(): Promise<boolean> {
    const res = await this.cache.getOrElseSet(hiResOnMobileKey, trueString);
    return res.contains(trueString);
  }

  async isAdmin(): Promise<boolean> {
    const res = await this.cache.getOrElseSet(isAdminKey, trueString);
    return res.contains(trueString);
  }

  // Relatively untested
  private isDeviceMetric(): boolean {
    return nativeNavigator.language && nativeNavigator.language !== 'en-US';
  }

  async isMetric(): Promise<boolean> {
    const res = await this.cache.getOrElseSet(isMetricKey, '' + this.isDeviceMetric());
    return res.contains(trueString);
  }

  async setCanDownloadHighRes(value: boolean): Promise<boolean> {
    await this.cache.set(hiResKey, '' + value);
    return value;
  }

  async setCanDownloadHighResOnMobile(value: boolean): Promise<boolean> {
    await this.cache.set(hiResOnMobileKey, '' + value);
    return value;
  }

  async setIsAdmin(value: boolean): Promise<boolean> {
    await this.cache.set(isAdminKey, '' + value);
    return value;
  }

  async setIsMetric(value: boolean): Promise<boolean> {
    await this.cache.set(isMetricKey, '' + value);
    return value;
  }
}
