import {Injectable, NgZone} from '@angular/core';
import {DidgigoApiService} from '@didgigo/lib-angular';
import {
  adminPrefix,
  agentPrefix,
  ContextualImage,
  ContextualImageSet,
  DownloadType,
  Image,
  now,
  OptionUtils,
  PromiseUtils,
  Proposal,
  proposalAgentRoomPrefix,
  ProposalEntry,
  proposalTravellerRoomPrefix,
  ProposalWithMeta,
  Url,
} from '@didgigo/lib-ts';
import {FileTransfer, FileTransferObject} from '@ionic-native/file-transfer/ngx';
import {DirectoryEntry, File} from '@ionic-native/file/ngx';
import {ModalController, Platform, ToastController} from '@ionic/angular';
import {None, Option} from 'funfix-core';
import {List, Set} from 'immutable';
import {Moment} from 'moment';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {DownloadSizeModalComponent} from '../components/download-size-modal/download-size-modal.component';
import {ChatService} from './chat.service';
import {ConfigurationService} from './configuration.service';
import {DialogService} from './dialog-service';
import {LoggingService} from './logging.service';
import {MediaService} from './media.service';
import {NavigatorService} from './navigator.service';
import {ProposalService} from './proposal.service';
import {RestService} from './rest.service';
import {UserSettingsService} from './user-settings.service';
import {UserService} from './user.service';
import {Capacitor} from '@capacitor/core';
import * as _s from 'underscore.string';

export class Job {
  constructor(
      readonly type: string,
      readonly detail: string,
      readonly run: () => Promise<Option<ContextualImageSet>>) {
  }
}

export class ProposalDownloadStatus {
  constructor(
      readonly total: number = 0,
      readonly current: number = 0,
      readonly processing: List<string> = List(),
      readonly newImageToShow: Option<ContextualImageSet> = None) {
  }

  getPercentage(): number {
    if (this.current === 0) {
      return 0;
    }
    return this.current / this.total * 100;
  }

  getProcessingSet(): Set<string> {
    return this.processing.toSet();
  }

  hasStarted(): boolean {
    return this.current !== 0;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProposalDownloaderService {

  constructor(
      readonly didgigo: DidgigoApiService,
      readonly rest: RestService,
      readonly proposals: ProposalService,
      readonly logging: LoggingService,
      readonly dialog: DialogService,
      readonly file: File,
      readonly zone: NgZone,
      readonly platform: Platform,
      readonly navigator: NavigatorService,
      readonly settings: UserSettingsService,
      readonly user: UserService,
      readonly toast: ToastController,
      readonly media: MediaService,
      readonly chat: ChatService,
      readonly modals: ModalController,
      readonly transfer: FileTransfer,
      readonly configuration: ConfigurationService,
  ) {
  }

  africaDemo = '45488';

  australiaRoadbook = '105809';

  canadaDemo = '45122';

  chinaDemo = '45476';

  europeDemo = '62712';

  franceDemo = '45155';

  frenchLARoadbookDemo = '86265';

  newZealandRoadbook = '105835';

  oceaniaDemo = '45410';

  status: BehaviorSubject<ProposalDownloadStatus> =
      new BehaviorSubject<ProposalDownloadStatus>(new ProposalDownloadStatus());

  tropicsDemo = '45329';

  usaDemo = '45410';

  async actuallyDownloadTheImage(fullUrl: Url, image: Image, ci: ContextualImage): Promise<Option<ContextualImageSet>> {
    const toReturn: ContextualImageSet = new ContextualImageSet(image, Option.of(ci));
    const fileTransfer: FileTransferObject = this.transfer.create();
    const dir = await this.file.resolveDirectoryUrl(this.file.dataDirectory);
    const dirToAdd = await this.ensureDirectoriesExist(fullUrl.relativeTo(Url.parse(this.file.dataDirectory).get()), dir);
    console.log('Data directory ' + this.file.dataDirectory)
    console.log('DirToAss ' + dirToAdd.toURL())
    const file = await this.file.getFile(dirToAdd, fullUrl.getFileName(), {create: true, exclusive: false});
    const startTime = now();
    await fileTransfer.download(ci.remoteUrl, file.toURL(), true).catch(err => console.error("Whoops: " + err));
    this.logging.logPerformanceObject('download_image', this.getImageAnalytic(image), startTime);
    return Option.of(toReturn);
  }

  async downloadById(id: string): Promise<void> {
    const exists = await this.proposals.hasKey(id);
    this.navigator.setProposalId(id);
    if (exists) {
      this.navigator.gotoWelcome(true);
    } else {
      this.navigator.gotoDownload();
    }
  }

  downloadByQsid(encoded: string): void {
    const result = atob(encoded);
    const id = result.substr(2);
    if (result.startsWith('PR')) {
      this.downloadById(id);
    } else if (result.startsWith(adminPrefix)) {
      this.user.addDidgigoAdmin(id);
    } else if (result.startsWith(agentPrefix)) {
      this.user.addDidgigoAgent(id);
    } else {
      this.dialog.showToast(`'${encoded}' is not a valid Quickstart Id`);
    }
  }

  private async downloadData(id: string, startTime: Moment): Promise<ProposalWithMeta> {
    this.status.next(new ProposalDownloadStatus());
    const propOpt = await this.didgigo.getProposalWithMetaById(+id);
    if (propOpt.isLeft()) {
      throw new Error(propOpt.value);
    } else {
      const prop = propOpt.get();
      this.logging.logPerformance('download_proposal_prepare', id, startTime);
      this.proposals.set(id, prop);
      return prop;
    }
  }

  async downloadImage(image: Image, ci: ContextualImage): Promise<Option<ContextualImageSet>> {
    const toReturn: ContextualImageSet = new ContextualImageSet(image, Option.of(ci));
    const urlOption = ci.storageUrl.flatMap(x => Url.parse(x));
    try {
      if (urlOption.nonEmpty()) {
        const fullUrl = urlOption.get().withOrigin(_s.rtrim(this.file.dataDirectory, '/'));
        const doesFileAlreadyExist = await this.file.checkFile(fullUrl.getHrefWithoutFile(), fullUrl.getFileName())
            .catch(err => {
              return false;
            });
        if (doesFileAlreadyExist) {
          return Option.of(toReturn);
        } else {
          await this.actuallyDownloadTheImage(urlOption.get(), image, ci);
          return Option.of(toReturn);
        }
      }

      // Dev Environment
      await this.rest.getImageAt(ci.remoteUrl);
      return Option.of(toReturn);
    } catch (err) {
      console.error('Failed image download', ci.remoteUrl);
      return None;
    }
  }

  // Returns whether of not the itinerary downloaded successfully
  async downloadProposal(id: string): Promise<boolean> {
    const start = now();
    const proposalWithMeta = await this.downloadData(id, start);
    const downloadType = await this.getDownloadType(proposalWithMeta);
    await this.ensureFreeEnoughSpace(proposalWithMeta, downloadType);
    this.logging.logPerformance('prepare_proposal', id, start);
    return from(this.processJobs(List.of<Job>()
        .concat(this.getSelectUserJob(proposalWithMeta))
        .concat(this.getDownloadWelcomeImageJob(proposalWithMeta))
        .concat(this.getDownloadAgentPhotoJob(proposalWithMeta))
        .concat(this.getDownloadPresentAsCompanyLogoJob(proposalWithMeta))
        .concat(this.getDownloadProposalCompanyLogoJob(proposalWithMeta))
        .concat(this.getDownloadProposalMapJob(proposalWithMeta))
        .concat(this.getEntriesJobs(proposalWithMeta, downloadType))))
        .pipe(tap(_ => {
          this.reset();
        }, _ => {
        }, () => this.logging.logPerformance('download_proposal', id, start)))
        .pipe(catchError(err => {
          if (err === 'Cancelling') {
            console.warn('Cancelling download');
            this.reset();
            return of(false);
          }
          console.error('Recovering from global error during job processing');
          console.error(err);
          this.reset();
          return of(false);
        })).toPromise();
  }

  private ensureDirectoriesExist(fullUrl: Url, dir: DirectoryEntry): Promise<DirectoryEntry> {
    return fullUrl.getSegments().reduce<Observable<DirectoryEntry>>(
        (a, b) => a.pipe(switchMap((d: DirectoryEntry) =>
            from(this.file.getDirectory(d, b, {create: true, exclusive: false})),
        )), of(dir))
        .toPromise();
  }

  private async ensureFreeEnoughSpace(prop: ProposalWithMeta, downloadSize: DownloadType): Promise<ProposalWithMeta> {
    const estimatedDownloadSize = prop.proposal.getOrElse(new Proposal()).getEstimatedDownloadSize(downloadSize);
    // If we are not on a physical device, fake the number of bytes
    const freeDiskSpace = this.platform.is('cordova') ? await this.file.getFreeDiskSpace() : Number.MAX_VALUE;
    if (freeDiskSpace > estimatedDownloadSize.getOrElse(0)) {
      return prop;
    } else {
      const result = await this.dialog.showConfirmationDialog(
          'Not enough space on device',
          `We have detected there may not be enough storage on this device in
            order to download the imagery, if you choose to continue some images may end up missing`);
      if (result.contains(false)) {
        throw new Error('Cancelling');
      } else {
        return prop;
      }
    }
  }

  getAllDemos(): List<string> {
    return List.of(
        this.chinaDemo,
        this.oceaniaDemo,
        this.africaDemo,
        this.usaDemo,
        this.tropicsDemo,
        this.europeDemo,
        this.franceDemo,
        this.canadaDemo,
        this.newZealandRoadbook,
        this.frenchLARoadbookDemo,
        this.australiaRoadbook);
  }

  getDownloadAgentPhotoJob(p: ProposalWithMeta): List<Job> {
    const imageOption = p.proposal.flatMap(x => x.agent).flatMap(a => a.image);
    return OptionUtils.toList(imageOption).flatMap(i => this.media.getAgentImage(i).getContextualImagesUnderMB())
        .map(ci => new Job(
            `Downloading Photo of ${p.proposal.flatMap(x => x.agent).flatMap(a => a.getFullName()).getOrElse('Unknown')}`,
            '',
            () => this.downloadImage(Image.fromString(imageOption.get()), ci).then(_ => None)));
  }

  getDownloadPresentAsCompanyLogoJob(p: ProposalWithMeta): List<Job> {
    const imageOption = p.proposal.flatMap(x => x.getPresentAsCompany()).flatMap(c => c.getLogo()).flatMap(x => x.getHref());
    return OptionUtils.toList(imageOption)
        .flatMap(i => this.media.getProposalCompanyLogo(i).getContextualImagesUnderMB())
        .map(ci => new Job(
            `Download ${p.proposal.flatMap(x => x.getPresentAsCompany()).flatMap(c => c.getName()).getOrElse('Unknown')} Logo`,
            '',
            () => this.downloadImage(Image.fromString(imageOption.get()), ci).then(_ => None)));
  }

  getDownloadProductMapJob(e: ProposalEntry): List<Job> {
    const prodMapImages = e.getAllProducts().map(p => p.map.flatMap(m => m.image));
    return OptionUtils.flattenList(prodMapImages)
        .map(i => {
          const contextualImage = this.media.getProductMap(i).highres;

          return new Job(
              e.getTitle().getOrElse('Unknown'),
              'Download Map',
              () => this.downloadImage(Image.fromString(i), contextualImage.get()).then(_ => None));
        });
  }

  getDownloadProposalCompanyLogoJob(p: ProposalWithMeta): List<Job> {
    const imageOption = p.proposal.flatMap(x => x.company).flatMap(c => c.getLogo()).flatMap(x => x.getHref());
    return OptionUtils.toList(imageOption).flatMap(i => this.media.getProposalCompanyLogo(i).getContextualImagesUnderMB())
        .map(ci => new Job(
            `Download ${p.proposal.flatMap(x => x.company).flatMap(c => c.getName()).getOrElse('Unknown')} Logo`,
            '',
            () => this.downloadImage(Image.fromString(imageOption.get()), ci).then(_ => None)));
  }

  getDownloadProposalMapJob(p: ProposalWithMeta): List<Job> {
    const imageOption = p.proposal.flatMap(x => x.map).flatMap(m => m.image);
    return OptionUtils.toList(imageOption.flatMap(i => this.media.getProposalMap(i).highres))
        .map(ci => new Job(
            `${p.proposal.flatMap(x => x.welcome).flatMap(w => w.title).getOrElse('Unknown')}`,
            'Download Map',
            () => this.downloadImage(Image.fromString(imageOption.get()), ci).then(_ => None)));
  }

  getDownloadSupplierLogoJob(e: ProposalEntry): List<Job> {
    const imageOption = e.getAllProducts().map(p => p.logo);
    return OptionUtils.flattenList(imageOption)
        .map(i => {
          // Last is highest resolution
          const ci = Option.of(this.media.getProductLogo(i).getContextualImagesUnderMB().last());
          return new Job(
              e.getTitle().getOrElse('Unknown'),
              `Download ${e.getTitle().getOrElse('Unknown')} Logo`,
              () => this.downloadImage(i, ci.get()).then(_ => None));
        });
  }

  async getDownloadType(proposalWithMeta: ProposalWithMeta): Promise<DownloadType> {
    const modal = await this.modals.create({
      component: DownloadSizeModalComponent,
      componentProps: {proposalWithMeta},
      animated: true,
      showBackdrop: false,
    });

    await modal.present();
    const res = await modal.onDidDismiss();
    return res.data as DownloadType;
  }

  getDownloadWelcomeImageJob(p: ProposalWithMeta): List<Job> {
    const imageOption = p.proposal.flatMap(x => x.welcome).flatMap(w => w.image);
    return OptionUtils.toList(imageOption).flatMap(i => this.media.getWelcomeImage(i).getContextualImagesUnderMB())
        .map(ci =>
            new Job(`Welcome Image`,
                `${ci.remoteUrl}`,
                () => this.downloadImage(imageOption.get(), ci).then(_ => None)));
  }

  getEntriesJobs(p: ProposalWithMeta, downloadType: DownloadType): List<Job> {
    return p.proposal.getOrElse(new Proposal()).entries.flatMap(e => this.getEntryJobs(e, downloadType));
  }

  getEntryImageJobs(title: string, i: Image, count: number, downloadType: DownloadType): List<Job> {
    const productImage = this.media.getProductImage(i);
    if (productImage.isEmpty()) {
      console.log('Product Image empty');
      return List();
    }
    const images = productImage.getContextualImagesUnderMB(true, 5, 3, downloadType);
    return images
        .filter(ci => ci.storageUrl.nonEmpty() || !this.platform.is('cordova'))
        .map((ci, k) => {
            return new Job(
              title,
              `Download Image ${count + 1} - ${ci.remoteUrl}`,
              () =>
                  this.downloadImage(i, ci)
                      .then(x => x.filter(_ => i.isHeroImage() && k === images.size - 1)));
        });
  }

  getEntryImagesJobs(e: ProposalEntry, downloadType: DownloadType): List<Job> {
    return e.getImagesToDownload(downloadType)
        .flatMap((i, k) => this.getEntryImageJobs(e.getTitle().getOrElse('Unknown'), i, k, downloadType));
  }

  getEntryJobs(e: ProposalEntry, downloadType: DownloadType): List<Job> {
    return this.getEntryImagesJobs(e, downloadType)
        .concat(this.getDownloadProductMapJob(e))
        .concat(this.getDownloadSupplierLogoJob(e));
  }

  private getImageAnalytic(image: Image): object {
    const obj = {};
    image.bytes.forEach(b => obj['bytes'] = b);
    obj['hero'] = image.isHeroImage();
    image.uri.forEach(b => obj['filename'] = b.getHref());
    return obj;
  }

  getSelectUserJob(p: ProposalWithMeta): List<Job> {
    if (p.getProposalId().exists(i => this.getAllDemos().contains('' + i))) {
      return List();
    }

    return List.of(new Job(
        'Adding user',
        '',
        () => this.user.getOrAskForIdentity(p.proposal.map(x => x.people).getOrElse(List()))
            .pipe(take(1))
            .pipe(tap(optPerson =>
                Option.map2(
                    optPerson,
                    p.getProposalId(),
                    (per, id) => this.registerInChannels(id.toString()))))
            .toPromise()
            .then(_ => None),
    ));
  }

  private handleJobError<T>(err): Observable<Option<T>> {
    console.error('Recovering from job during job processing');
    console.error(err);
    const v = this.status.getValue();
    this.status.next(new ProposalDownloadStatus(v.total, v.current + 1));
    return of(None);
  }

  private markStatusCompleted(j: Job, image: Option<ContextualImageSet>): void {
    const v = this.status.getValue();
    this.status.next(
        new ProposalDownloadStatus(
            v.total,
            v.current + 1,
            v.processing.remove(v.processing.indexOf(j.type)),
            image));
  }

  private markStatusStarted(j: Job): void {
    const stat = this.status.getValue();
    this.status.next(
        new ProposalDownloadStatus(
            stat.total,
            stat.current,
            stat.processing.push(j.type),
            None));
  }

  async processJobs(jobs: List<Job>): Promise<boolean> {
    this.status.next(new ProposalDownloadStatus(jobs.size));
    await PromiseUtils.allListThrottled(jobs, j => this.runJobTimed(j), 5);
    this.reset();
    return true;
  }

  // Note: Is not actually part of the job queue, this happens behind the scenes after person is selected.
  registerInChannels(proposalId: string): void {
    if (this.getAllDemos().contains(proposalId)) {
      return;
    }
    this.user.currentIdentity
        .pipe(filter(x => x.exists(ip => !ip.isGuest())))
        .pipe(take(1))
        .subscribe(x => {
          const id = x.flatMap(ip => ip.identity).getOrElse('Unknown');

          if (x.exists(ip => ip.isGuest())) {
            console.warn('Tried to add guest to channel, this should not be possible');
            return;
          }

          if (x.exists(ip => ip.isTraveller())) {
            this.chat.registerInChannel(proposalTravellerRoomPrefix + proposalId, id);
          }

          if (x.exists(ip => ip.isTraveller() || ip.isAgent())) {
            return this.chat.registerInChannel(proposalAgentRoomPrefix + proposalId, id);
          }

          // Should early return before this point
          console.warn('Failed to register in channel');
        });
  }

  reset(): void {
    if (this.status.getValue().hasStarted()) {
      console.warn('Resetting in progress download');
    }

    this.status.next(new ProposalDownloadStatus());
  }

  async runJobTimed(j: Job): Promise<Option<ContextualImageSet>> {
    this.markStatusStarted(j);
    const startTime = now();
    try {
      const res = await j.run();
      this.logging.logPerformanceObject('run_job', {detail: j.detail, type: j.type}, startTime);
      this.markStatusCompleted(j, res);
      return res;
    } catch (err) {
      this.handleJobError(err);
      return None;
    }
  }
}
