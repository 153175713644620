import {Inject, Injectable} from '@angular/core';
import * as LocalForage from 'localforage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

@Injectable({
    providedIn: 'root',
})
export class Storage {

    /**
     * Create a new Storage instance using the order of drivers and any additional config
     * options to pass to LocalForage.
     *
     * Possible driver options are: ['sqlite', 'indexeddb', 'websql', 'localstorage'] and the
     * default is that exact ordering.
     */
    constructor(
        @Inject('storageConfig') public config: StorageConfig) {
        this.forage = this.getDb();
    }

    /**
     * Get the name of the driver being used.
     * @returns Name of the driver
     */
    get driver(): string | null {
        return this._driver;
    }

    private _driver: string | null;

    private forage: Promise<LocalForage>;

    /** @hidden */
    private _getDriverOrder(driverOrder): any {
        return driverOrder.map(driver => {
            switch (driver) {
                case 'sqlite':
                    return CordovaSQLiteDriver._driver;
                case 'indexeddb':
                    return LocalForage.INDEXEDDB;
                case 'websql':
                    return LocalForage.WEBSQL;
                case 'localstorage':
                    return LocalForage.LOCALSTORAGE;
            }
        });
    }

    /**
     * Clear the entire key value store. WARNING: HOT!
     * @returns Returns a promise that resolves when the store is cleared
     */
    clear(): Promise<void> {
        return this.forage.then(db => db.clear());
    }

    /**
     * Iterate through each key,value pair.
     * @param iteratorCallback a callback of the form (value, key, iterationNumber)
     * @returns Returns a promise that resolves when the iteration has finished.
     */
    forEach(
        iteratorCallback: (value: any, key: string, iterationNumber: number) => any,
    ): Promise<void> {
        return this.forage.then(db => db.iterate(iteratorCallback));
    }

    /**
     * Get the value associated with the given key.
     * @param key the key to identify this value
     * @returns Returns a promise with the value of the given key
     */
    get(key: string): Promise<any> {
        return this.forage.then(db => db.getItem(key));
    }

    private async getDb(): Promise<LocalForage> {
        await LocalForage.defineDriver(CordovaSQLiteDriver);
        const db = LocalForage.createInstance(this.config);
        await db.setDriver(this._getDriverOrder(this.config.driverOrder));
        this._driver = db.driver();

        return db;
    }

    /**
     * @returns Returns a promise that resolves with the keys in the store.
     */
    keys(): Promise<string[]> {
        return this.forage.then(db => db.keys());
    }

    /**
     * @returns Returns a promise that resolves with the number of keys stored.
     */
    length(): Promise<number> {
        return this.forage.then(db => db.length());
    }

    /**
     * Reflect the readiness of the store.
     * @returns Returns a promise that resolves when the store is ready
     */
    ready(): Promise<LocalForage> {
        return this.forage;
    }

    /**
     * Remove any value associated with this key.
     * @param key the key to identify this value
     * @returns Returns a promise that resolves when the value is removed
     */
    remove(key: string): Promise<any> {
        return this.forage.then(db => db.removeItem(key));
    }

    /**
     * Set the value for the given key.
     * @param key the key to identify this value
     * @param value the value for this key
     * @returns Returns a promise that resolves when the key and value are set
     */
    set(key: string, value: any): Promise<any> {
        return this.forage.then(db => db.setItem(key, value));
    }
}

/** @hidden */
export function getDefaultConfig(): StorageConfig {
    return {
        name: '_ionicstorage',
        storeName: '_ionickv',
        dbKey: '_ionickey',
        driverOrder: ['sqlite', 'indexeddb', 'websql', 'localstorage'],
    };
}

/** @hidden */
export interface StorageConfig {
    dbKey?: string;
    description?: string;
    driverOrder?: string[];
    name?: string;
    size?: number;
    storeName?: string;
    version?: number;
}
