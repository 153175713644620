import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SmartTextAreaComponentModule} from '@didgigo/lib-angular';
import {IonicModule} from '@ionic/angular';
import {EntryDetailsLargeComponent} from './entry-details-large.component';

@NgModule({
  imports: [
    CommonModule,
    SmartTextAreaComponentModule,
    IonicModule.forRoot(),
  ],
  declarations: [EntryDetailsLargeComponent],
  exports: [EntryDetailsLargeComponent],
})
export class EntryDetailsLargeComponentModule { }
