import {Injectable} from '@angular/core';
import {parseListSerializable, RecentProposals, RecentProposalsJsonSerializer} from '@didgigo/lib-ts';
import {List} from 'immutable';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConfigurationService} from './configuration.service';
import {RestService} from './rest.service';

@Injectable({
  providedIn: 'root',
})
export class RecentsService {

  constructor(
    readonly configuration : ConfigurationService,
    readonly rest : RestService,
  ) { }

  getRecents() : Observable<List<RecentProposals>> {
    return from(this.rest.getJsonFrom(`${this.configuration.readApiServer}/recents`))
      .pipe(map((obj) => parseListSerializable(obj, RecentProposalsJsonSerializer.instance)));
  }
}
