import {Injectable} from '@angular/core';
import {List} from 'immutable';
import {environment} from '../../environments/environment';

const liveDidgigo = 'https://didgigo.com';

export enum Feature {
  Chat,
}

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {

  constructor() { }

  readonly didgigoServer: string = liveDidgigo;
  readonly featureBlackList: List<Feature> = List.of();
  readonly readApiServer: string = environment.apiServer;
  readonly testing = true;
  readonly version = '1.4.1';

  getFirestoreRoomsCollectionId(): string {
    return this.isProduction() ? 'rooms' : 'test_rooms';
  }

  getFirestoreUsersCollectionId(): string {
    return this.isProduction() ? 'users' : 'test_users';
  }

  isEnabled(feature: Feature): boolean {
    return this.isTesting() || !this.featureBlackList.contains(feature);
  }

  isProduction(): boolean {
    return environment.production;
  }

  isTesting(): boolean {
    return !this.isProduction();
  }
}
