import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-spacer',
  templateUrl: './header-spacer.component.html',
  styleUrls: ['./header-spacer.component.scss'],
})
export class HeaderSpacerComponent implements OnInit {

  constructor() { }

  @Input()
  full = false;

  ngOnInit() {
  }

}
