import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {EntryCardMediumComponentModule} from '../../components/entry-card-medium/entry-card-medium.component.module';
import {EntryCardSmallComponentModule} from '../../components/entry-card-small/entry-card-small.component.module';
import {FloatingCardComponentModule} from '../../components/floating-card/floating-card.component.module';
import {FloatingFooterComponentModule} from '../../components/floating-footer/floating-footer.component.module';
import {FloatingHeaderComponentModule} from '../../components/floating-header/floating-header.component.module';
import {FooterSpacerComponentModule} from '../../components/footer-spacer/footer-spacer.component.module';
import {HeaderSpacerComponentModule} from '../../components/header-spacer/header-spacer.component.module';
import {LoadingComponentModule} from '../../components/loading/loading.component.module';
import {SnapshotSubproductPage} from './snapshot-subproducts-page.page';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FloatingFooterComponentModule,
        FloatingCardComponentModule,
        LoadingComponentModule,
        EntryCardSmallComponentModule,
        HeaderSpacerComponentModule,
        FooterSpacerComponentModule,
        FloatingHeaderComponentModule,
        EntryCardMediumComponentModule,
        RouterModule.forChild([
            {
                path: '',
                component: SnapshotSubproductPage,
            },
        ]),
    ],
    declarations: [SnapshotSubproductPage],
})
export class SnapshotSubproductsPageModule {
}
