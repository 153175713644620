import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CollectionUtils, now} from '@didgigo/lib-ts';
import {Map} from 'immutable';
import {retry, take, tap} from 'rxjs/operators';
import {LoggingService} from './logging.service';

@Injectable({
  providedIn: 'root',
})
export class RestService {

  constructor(
    private http: HttpClient,
    private logger: LoggingService,
  ) { }

  requestCache: Map<string, Promise<object>> = Map();

  getImageAt(fullUrl: string): Promise<object> {
    return CollectionUtils.safeGet(this.requestCache, fullUrl)
      .getOrElseL(() => this.runArrayBufferRequest(fullUrl))
  }

  // Caching Layer
  getJsonFrom(fullUrl: string): Promise<object> {
    return CollectionUtils.safeGet(this.requestCache, fullUrl)
        .getOrElseL(() => this.runJsonRequest(fullUrl));
  }

  // TODO: Consider exponential backoff
  private runArrayBufferRequest(fullUrl: string): Promise<object> {
    const start = now();
    const response: Promise<object> = this.http.get(fullUrl, {responseType: 'arraybuffer'})
      .pipe(tap(_ => this.logger.logPerformance('rest', fullUrl, start)))
      .pipe(retry(3))
      .pipe(take(1))
        .toPromise();

    this.setupCache(fullUrl, response);

    return response;
  }

  private runJsonRequest(fullUrl: string): Promise<object> {
    const start = now();
    const response: Promise<object> = this.http.get(fullUrl)
      .pipe(tap(_ => this.logger.logPerformance('rest', fullUrl, start)))
      .pipe(retry(3))
      .pipe(take(1))
        .toPromise();

    this.setupCache(fullUrl, response);

    return response;
  }

  // Populates cache and ensures removal from cache after completion
  private setupCache(fullUrl: string, response: Promise<object>): void {
    this.requestCache = this.requestCache.set(fullUrl, response);
    response.then(v => this.requestCache.delete(fullUrl));
  }
}
