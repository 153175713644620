import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {IconComponentModule} from '../icon/icon.component.module';
import {DropdownModule} from '../simple-dropdown/dropdown.module';
import {SideMenuComponent} from './side-menu.component';

@NgModule({
    declarations: [
        SideMenuComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        DropdownModule,
        NgbModule,
        CommonModule,
        IconComponentModule,
    ],
    exports: [SideMenuComponent],
})
export class SideMenuComponentModule {
}
