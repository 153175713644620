import {Injectable} from '@angular/core';
import {Plugins} from '@capacitor/core';
import {IdentifiablePerson, logTimePreCalculated, now, preciseTimeDiff, Proposal, ProposalWithMeta} from '@didgigo/lib-ts';
import {Platform} from '@ionic/angular';
import {None, Option} from 'funfix-core';
import {Moment} from 'moment';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import {filter, take, tap} from 'rxjs/operators';
import {ConfigurationService} from './configuration.service';

const {CapacitorFirebaseAnalytics} = Plugins;

@Injectable({
  providedIn: 'root',
})
export class LoggingService {

  constructor(
      readonly configuration: ConfigurationService,
      readonly platform: Platform) {
    this.currentPageObservable.subscribe();
  }

  currentPage: ReplaySubject<string> = new ReplaySubject(1);
  currentPageObservable: Observable<string> =
      this.currentPage.asObservable()
          .pipe(tap(page => this.platform.is('cordova') ? CapacitorFirebaseAnalytics.setScreenName({screenName: page}) : {}));

  currentProposal: ReplaySubject<ProposalWithMeta> = new ReplaySubject<ProposalWithMeta>(1);
  currentUser: ReplaySubject<IdentifiablePerson> = new ReplaySubject<IdentifiablePerson>(1);

  getCurrentPage(): Observable<string> {
    return this.currentPage.pipe(take(1));
  }

  getCurrentProposal(): Observable<ProposalWithMeta> {
    return this.currentProposal.pipe(take(1));
  }

  getCurrentProposalForId(id: number): Observable<ProposalWithMeta> {
    return this.currentProposal
        .pipe(filter(x => x.getProposalId().contains(id)))
        .pipe(take(1));
  }

  getProposalAnalyticData(proposal: Proposal): object {
    const obj: object = {};
    proposal.id.forEach(ds => obj['proposal_id'] = ds);
    obj['state'] = proposal.getCurrentState();
    proposal.getAnalyticDays().forEach(ds => obj['days'] = ds);
    proposal.getCompanyName().forEach(ds => obj['company'] = ds);
    proposal.getAgentFullName().forEach(ds => obj['agent'] = ds);
    proposal.status.forEach(s => obj['proposal_status'] = s);
    return obj;
  }

  getUserAnalyticData(user: IdentifiablePerson): object {
    const obj: object = {};
    user.identity.forEach(ds => obj['user_id'] = ds);
    obj['user_type'] = user.getPersonType();
    return obj;
  }

  logDebug(data: any): void {
    // console.debug(data); // uncomment to enable debug logging
  }

  logError(data: any): void {
    console.error(data);
  }

  logEvent(key: string, data: object): void {
    if (this.platform.is('cordova')) {
      CapacitorFirebaseAnalytics.logEvent({name: key, parameters: data});
    }
  }

  logEventWithProposalAndUser(key: string, data: object, proposalId: Option<number> = None): void {
    const proposalObs = proposalId.nonEmpty() ? this.getCurrentProposalForId(proposalId.get()) : this.getCurrentProposal();
    combineLatest(proposalObs, this.currentUser.pipe(take(1)))
        .subscribe(([prop, usr]) => {
          const proposalAnalyticData = prop.proposal.map(p => this.getProposalAnalyticData(p)).getOrElse({});
          const userAnalyticData = this.getUserAnalyticData(usr);
          this.logEvent(key, {...proposalAnalyticData, ...userAnalyticData, ...data});
        });
  }

  logEventWithUser(key: string, data: object, user: Observable<Option<IdentifiablePerson>>): void {
    this.logEventWithProposalAndUser(key, data, None);
  }

  logPerformance(key: string, id: string, startTime: Moment): void {
    const time = preciseTimeDiff(startTime);
    this.logEvent(key, {
      id,
      time,
    });

    if (this.configuration.isTesting()) {
      logTimePreCalculated(key + ':' + id, time);
    }
  }

  logPerformanceLambda<T>(key: string, id: string, f: () => T): T {
    const startTime = now();
    const res = f();
    this.logPerformance(key, id, startTime);
    return res;
  }

  logPerformanceObject(key: string, object: object, startTime: Moment): void {
    const time = preciseTimeDiff(startTime);
    this.logEvent(key, {...object, time});
    if (this.configuration.isTesting()) {
      logTimePreCalculated(key, time);
    }
  }

  // Note: returns a value
  logPerformanceObjectSupplier<T>(key: string, obj: object, f: () => T): T {
    const startTime = now();
    const res = f();
    this.logPerformanceObject(key, obj, startTime);
    return res;
  }

  observeCurrentPage(): Observable<string> {
    return this.currentPage;
  }

  setPage(name: string): void {
    this.currentPage.next(name);
  }
}
