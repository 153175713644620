import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FloatingHeaderComponent } from './floating-header.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule.forRoot(),
  ],
  declarations: [FloatingHeaderComponent],
  exports: [FloatingHeaderComponent],
})
export class FloatingHeaderComponentModule { }
