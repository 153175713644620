import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {CollectionUtils, ContextualImageSet} from '@didgigo/lib-ts';
import {Observable, of, timer} from 'rxjs';
import {flatMap, map, repeat, shareReplay, take} from 'rxjs/operators';
import {BaseComponent} from '../../lib-ionic/base-component';
import {AssetService} from '../../services/asset.service';
import {LoadingMonitorService} from '../../services/loading-monitor.service';
import {LoggingService} from '../../services/logging.service';
import {NavigatorService} from '../../services/navigator.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.page.html',
  styleUrls: ['./landing.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingPage extends BaseComponent implements OnInit {

  constructor(
    readonly logger: LoggingService,
    readonly navigator: NavigatorService,
    readonly assets: AssetService,
    readonly change: ChangeDetectorRef,
    readonly self: ElementRef,
    readonly loading: LoadingMonitorService) {
    super('landing_page', change, self, loading);
  }

  didYouKnow: string[] = [
    'Tripigo is developed at the Great Barrier Reef, Australia',
    'Tripigo is designed to be fully immersive, focusing on beautiful imagery and ease of use.',
    'Tripigo has over 100,000 high resolution images of the most beautiful locations worldwide.',
    'Tripigo is the only itinerary app to support high resolution imagery.',
    'Tripigo supports self drive directions, for fully offline travel itineraries.',
    'Tripigo allows you to contact someone when in need, no matter where you are in the world.',
    'Tripigo allows you to turn on aeroplane mapMode, and enjoy you trip, keeping everything at your fingertips.',
  ];

  didYouKnowObservable: Observable<string> = this.getDidYouKnowObservable();

  private getDidYouKnowObservable(): Observable<string> {
    return of(...CollectionUtils.shuffle(this.didYouKnow))
      .pipe(flatMap(x => timer(6000).pipe(take(1)).pipe(map(_ => x)), 1))
      .pipe(repeat())
      .pipe(shareReplay(1));
  }

  getIcon(): string {
    return this.assets.getSvgPath('tripigo-logo-colour');
  }

  getImages(): ContextualImageSet {
    return this.assets.getBackground();
  }

  ngOnInit(): void {
    this.navigator.clearIds();
    this.logger.setPage('landing');
    this.logger.logEvent(
        'load_page',
        {page: 'landing'});

    super.ngOnInit();
  }
}
