import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ProposalWithMeta} from '@didgigo/lib-ts';
import {MediaService} from '../../services/media.service';

@Component({
  selector: 'app-proposal-card',
  templateUrl: './proposal-card.component.html',
  styleUrls: ['./proposal-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProposalCardComponent implements OnInit {

  @Input()
  model: ProposalWithMeta = new ProposalWithMeta();

  constructor(
      readonly media: MediaService,
  ) {
  }

  ngOnInit() {
  }

}
