import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ImageIconComponentModule } from '../image-icon/image-icon.component.module';
import { ImagePreloaderComponent } from './image-preloader.component';

@NgModule({
  imports: [
    CommonModule,
    ImageIconComponentModule,
    IonicModule.forRoot(),
  ],
  declarations: [ImagePreloaderComponent],
  exports: [ImagePreloaderComponent],
})
export class ImagePreloaderComponentModule { }
