import {Pipe, PipeTransform} from '@angular/core';

/**
 * Bolds a matching string.
 * Note: must use this in innerHtml.
 */
@Pipe({name: 'nl'})
export class NewLinePipe implements PipeTransform {
    transform(value: string): string {
        if (value === undefined || value === null) {
            return value;
        }
        return value.replace(/\n/g, '<br>');
    }
}
