import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {ContextualImageSet, IdentifiablePerson, Image, modelDebounce, OptionUtils, ProposalWithMeta, tomorrow} from '@didgigo/lib-ts';
import {AlertController, ToastController} from '@ionic/angular';
import {None, Option} from 'funfix-core';
import {List} from 'immutable';
import {BehaviorSubject, combineLatest, from, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {BaseComponent} from '../../lib-ionic/base-component';
import {AssetService} from '../../services/asset.service';
import {BrowserService} from '../../services/browser.service';
import {ChatService} from '../../services/chat.service';
import {ConfigurationService} from '../../services/configuration.service';
import {LoadingMonitorService} from '../../services/loading-monitor.service';
import {MenuService} from '../../services/menu.service';
import {NavigatorService} from '../../services/navigator.service';
import {ProposalService} from '../../services/proposal.service';
import {UserSettingsService} from '../../services/user-settings.service';
import {UserService} from '../../services/user.service';

class Page {
    constructor(
        readonly name: string,
        readonly navigator: () => void,
        readonly notifications: Observable<number> = of(0)) {
    }
}

class UserData {
    constructor(
        readonly underlying: Option<IdentifiablePerson>,
        readonly id: string,
        readonly fullName: string,
        readonly personType: string,
        readonly company: string,
        readonly image: ContextualImageSet,
        readonly isGuest: boolean) {
    }

}

@Component({
    selector: 'app-proposal-menu',
    templateUrl: './proposal-menu.component.html',
    styleUrls: ['./proposal-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProposalMenuComponent extends BaseComponent implements OnInit {

    constructor(
        readonly alert: AlertController,
        readonly toast: ToastController,
        readonly proposalService: ProposalService,
        readonly navigator: NavigatorService,
        readonly menu: MenuService,
        readonly user: UserService,
        readonly assets: AssetService,
        readonly chat: ChatService,
        readonly settings: UserSettingsService,
        readonly change: ChangeDetectorRef,
        readonly self: ElementRef,
        readonly browser: BrowserService,
        readonly loading: LoadingMonitorService,
        readonly config: ConfigurationService) {
        super('menu_component', change, self, loading, false);
        this.navigator.observeEntryId().subscribe(x => this.eid = x);
        this.navigator.observeProposalId().subscribe(x => this.pid = x);
    }

    currentUser: Observable<Option<UserData>> =
        this.user.currentIdentity
            .pipe(map(id => id.flatMap(i => i.person).map(p =>
                new UserData(
                    id,
                    id.flatMap(i => i.identity).getOrElse('???'),
                    p.getFullName().getOrElse('Unknown'),
                    id.map(ident => ident.getPersonType()).getOrElse(''),
                    id.flatMap(ident => ident.company).flatMap(c => c.getName()).getOrElse(''),
                    id.flatMap(ident => ident.image).getOrElse(new ContextualImageSet(new Image(), None, None)),
                    id.exists(x => x.isGuest())))))
            .pipe(modelDebounce(this.unsubscriberObs));

    eid: Option<number>;

    items: BehaviorSubject<List<Page>> = new BehaviorSubject<List<Page>>(List());

    pid: Option<string>;

    private async deleteItinerary(): Promise<void> {
        if (this.pid.isEmpty()) {
            await this.proposalService.delete(this.pid.get());
            this.navigator.gotoItineraries();
            const toast = await this.toast.create({
                message: 'Itinerary deleted successfully',
                duration: 2000,
                position: 'bottom',
            });
            await toast.present();
        }
    }

    navigateTo(f: () => void): void {
        this.menu.close();
        f();
    }

    navigateToSettings(): void {
        this.navigateTo(() => this.navigator.gotoSettings());
    }

    navigateToTripigo(): void {
        this.navigateTo(() => this.navigator.gotoTripigo());
    }

    ngOnInit(): void {
        combineLatest(
            this.navigator.observeCurrentProposal(),
            this.user.currentIdentity)
            .subscribe(([optProposal, optUser]) => {
                const meta = optProposal.getOrElse(new ProposalWithMeta());

                const todayPage =
                    Option.of(new Page(meta.getDisplayTitleTodayPage(), () => this.navigator.gotoToday()))
                        .filter(_ => optProposal.exists(p => p.proposal.exists(x => x.isCurrentlyTravelling())));

                const tomorrowPage =
                    Option.of(new Page(meta.getDisplayTitleTomorrowPage(), () => this.navigator.gotoTomorrow()))
                        .filter(_ => optProposal.exists(p => p.proposal.exists(x => x.isDuring(tomorrow()))));

                const mapPage =
                    Option.of(new Page(meta.getDisplayTitleMapPage(), () => this.navigator.gotoMap()))
                        .filter(_ => optProposal.exists(x => x.isMapPageVisible()));

                const pricingPage =
                    Option.of(new Page(meta.getDisplayTitlePricingPage(), () => this.navigator.gotoPricing()))
                        .filter(_ => optProposal.flatMap(x => x.proposal).exists(p =>
                            p.isQuote()
                            && (p.priceHeader.nonEmpty() || p.priceDescription.nonEmpty())));

                const termsPage =
                    Option.of(new Page(meta.getDisplayTitleTermsAndConditionsPage(), () => this.navigator.gotoProposalTerms()))
                        .filter(_ => optProposal.flatMap(x => x.proposal).exists(p => p.isQuote() && p.terms.nonEmpty()));

                const helpfulHintsPage =
                    Option.of(new Page(meta.getDisplayProposalHelpfulHintsPageTitle(),
                        () => this.navigator.gotoHelpfulHints()))
                        .filter(_ => optProposal.exists(x => x.isHelpfulHintsPageVisible()));

                const importantContactsPage =
                    Option.of(new Page(
                        meta.getDisplayProposalImportantContactsPageTitle(),
                        () => this.navigator.gotoImportantContacts()))
                        .filter(_ => optProposal.exists(x => x.isImportantContactsPageVisible()));

                const adminPage =
                    Option.of(new Page(meta.getDisplayTitleAdminPage(), () => this.navigator.gotoAdmin()))
                        .filter(_ => optUser.exists(u => u.isAdmin()) || this.config.isTesting());

                const agentPage =
                    Option.of(new Page(meta.getDisplayTitleAgentPage(), () => this.navigator.gotoAgent()))
                        .filter(_ => optUser.exists(u => u.isAgent()));

                const dayByDayPage =
                    Option.of(new Page(meta.getDisplayTitleDayByDayPage(), () => this.navigator.gotoDayByDay()))
                        .filter(_ => optProposal.nonEmpty() && todayPage.isEmpty() && tomorrowPage.isEmpty());

                const snapshotPage =
                    Option.of(new Page(meta.getDisplayTitleSnapshotPage(), () => this.navigator.gotoSnapshot()))
                        .filter(_ => optProposal.nonEmpty());

                const infoPage =
                    Option.of(new Page(meta.getDisplayTitleInfoPage(), () => this.navigator.gotoAbout()))
                        .filter(_ => optProposal.flatMap(x => x.proposal).exists(x => x.hasCustomInfo()));

                const messagesPage =
                    Option.of(new Page(meta.getDisplayTitleMessagesPage(),
                        () => this.navigator.gotoMessages(), this.chat.observeNewMessageCount()))
                        .filter(_ => optUser.exists(u => u.canChat()));

                const contactPage =
                    Option.of(new Page(meta.getDisplayTitleContactPage(), () => this.navigator.gotoContact()))
                        .filter(_ => optProposal.exists(x => !x.getContactCards().isEmpty()));

                const welcomePage =
                    Option.of(new Page(meta.getDisplayTitleWelcomePage(), () => this.navigator.gotoWelcome()))
                        .filter(_ => optProposal.nonEmpty());

                const itinerariesPage =
                    Option.of(new Page(meta.getDisplayTitleItinerariesPage(), () => this.navigator.gotoItineraries()));

                const viewPdf =
                    Option.of(new Page(
                        meta.getDisplayPdfLinkLabel(),
                        () => this.browser.browseTo(optProposal.get().getDisplayPdfLink().get())))
                        .filter(_ => optProposal.exists(x => x.isPdfLinkVisible()));

                const standard = OptionUtils.toList(
                    todayPage,
                    tomorrowPage,
                    dayByDayPage,
                    snapshotPage,
                    infoPage,
                    mapPage,
                    importantContactsPage,
                    helpfulHintsPage,
                    welcomePage,
                    pricingPage,
                    termsPage,
                    itinerariesPage,
                    messagesPage,
                    contactPage,
                    viewPdf,
                );

                this.items.next(standard);
            });
    }

    requestDeleteItinerary(): void {
        from(this.alert.create({
            header: 'Delete',
            message: 'Are you sure you want to delete this itinerary?',
            cssClass: 'primaryToDark',
            buttons: [
                {
                    text: 'No',
                    cssClass: 'primaryToDark',
                },
                {
                    text: 'Yes',
                    cssClass: 'primaryToDark',
                    handler: () => {
                        this.deleteItinerary();
                    },
                },
            ],
        }))
            .pipe(switchMap(a => this.menu.close()
                .pipe(switchMap(_ => from(a.present())))))
            .subscribe();
    }

    requestReloadItinerary(): void {
        this.menu.close();
        this.navigator.gotoDownload();
    }
}
