import {Injectable} from '@angular/core';
import {AlertController, ToastController} from '@ionic/angular';
import {None, Option, Some} from 'funfix-core';

@Injectable({
  providedIn: 'root',
})
export class DialogService {

  constructor(
      readonly alert: AlertController,
      readonly toast: ToastController) {
  }

  // Shows Ok/Cancel, returns true/false if button clicked, returns None if dismissed without clicking
  async showConfirmationDialog(header: string, message: string): Promise<Option<boolean>> {
    let result: Option<boolean> = None;

    const alert = await this.alert.create({
      header,
      message,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'primaryToDark',
          handler: () => result = Some(false),
        },
        {
          text: 'Ok',
          cssClass: 'primaryToDark',
          handler: () => result = Some(true),
        },
      ],
    });

    await alert.present();
    return result;
  }

  async showToast(message: string): Promise<any> {
    const toast = await this.toast.create({
      message,
      duration: 2000,
      position: 'bottom',
    });

    return toast.present();
  }
}
