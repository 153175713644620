import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SmartTextAreaComponentModule} from '@didgigo/lib-angular';
import {IonicModule} from '@ionic/angular';
import {FloatingCardComponentModule} from '../floating-card/floating-card.component.module';
import {HeaderSpacerComponentModule} from '../header-spacer/header-spacer.component.module';
import {ImagePreloaderComponentModule} from '../image-preloader/image-preloader.component.module';
import {DownloadSizeModalComponent} from './download-size-modal.component';

@NgModule({
    imports: [
        CommonModule,
        HeaderSpacerComponentModule,
        ImagePreloaderComponentModule,
        FloatingCardComponentModule,
        IonicModule.forRoot(),
        SmartTextAreaComponentModule,
    ],
    declarations: [DownloadSizeModalComponent],
    exports: [DownloadSizeModalComponent],
    entryComponents: [DownloadSizeModalComponent],
})
export class DownloadSizeModalComponentModule {
}
