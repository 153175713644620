import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HighlightMatchPipeModule} from '../../pipes';
import {DropdownComponent} from './dropdown.component';

@NgModule({
    declarations: [
        DropdownComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HighlightMatchPipeModule,
        NgbModule,
        CommonModule,
    ],
    exports: [DropdownComponent],
})
export class DropdownModule {
    constructor() {
    }
}
