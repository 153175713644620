import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ImagePreloaderComponentModule } from '../image-preloader/image-preloader.component.module';
import { LoadingComponent } from './loading.component';

@NgModule({
  imports: [
    CommonModule,
    ImagePreloaderComponentModule,
    IonicModule.forRoot(),
  ],
  declarations: [LoadingComponent],
  exports: [LoadingComponent],
})
export class LoadingComponentModule { }
