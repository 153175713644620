import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {ChannelCardComponentModule} from '../../components/channel-card/channel-card.component.module';
import {FloatingCardComponentModule} from '../../components/floating-card/floating-card.component.module';
import {FloatingHeaderComponentModule} from '../../components/floating-header/floating-header.component.module';
import {HeaderSpacerComponentModule} from '../../components/header-spacer/header-spacer.component.module';
import {ImageBackgroundComponentModule} from '../../components/image-background/image-background.component.module';
import {ImagePreloaderComponentModule} from '../../components/image-preloader/image-preloader.component.module';
import {LoadingComponentModule} from '../../components/loading/loading.component.module';
import {MessagesPage} from './messages.page';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FloatingHeaderComponentModule,
    FloatingCardComponentModule,
    HeaderSpacerComponentModule,
    ImageBackgroundComponentModule,
    ImagePreloaderComponentModule,
    ChannelCardComponentModule,
    LoadingComponentModule,
    RouterModule.forChild([
      { path: '', component: MessagesPage },
    ]),
  ],
  declarations: [MessagesPage],
})
export class MessagesPageModule { }
