import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SmartTextAreaComponentModule} from '@didgigo/lib-angular';
import {IonicModule} from '@ionic/angular';
import {FloatingCardComponentModule} from '../../components/floating-card/floating-card.component.module';
import {FloatingHeaderComponentModule} from '../../components/floating-header/floating-header.component.module';
import {HeaderSpacerComponentModule} from '../../components/header-spacer/header-spacer.component.module';
import {ImageBackgroundComponentModule} from '../../components/image-background/image-background.component.module';
import {ImagePreloaderComponentModule} from '../../components/image-preloader/image-preloader.component.module';
import {LoadingComponentModule} from '../../components/loading/loading.component.module';
import {UserIconComponentModule} from '../../components/user-icon/user-icon.component.module';
import {ChatPage} from './chat.page';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FloatingHeaderComponentModule,
    FloatingCardComponentModule,
    HeaderSpacerComponentModule,
    SmartTextAreaComponentModule,
    FloatingHeaderComponentModule,
    UserIconComponentModule,
    ImagePreloaderComponentModule,
    ImageBackgroundComponentModule,
    LoadingComponentModule,
    RouterModule.forChild([
      { path: '', component: ChatPage },
    ]),
  ],
  declarations: [ChatPage],
})
export class ChatPageModule { }
