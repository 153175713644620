import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
  agentPrefix,
  ContextualImageSet,
  IdentifiablePerson,
  modelDebounce,
  OptionUtils,
  proposalAgentRoomPrefix,
  proposalTravellerRoomPrefix,
  ProposalWithMeta,
} from '@didgigo/lib-ts';
import {Option} from 'funfix-core';
import {combineLatest, Observable} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {BaseComponent} from '../../lib-ionic/base-component';
import {AssetService} from '../../services/asset.service';
import {ChatService} from '../../services/chat.service';
import {ContactService} from '../../services/contact.service';
import {LoadingMonitorService} from '../../services/loading-monitor.service';
import {LoggingService} from '../../services/logging.service';
import {NavigatorService} from '../../services/navigator.service';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.page.html',
  styleUrls: ['./contact.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactPage extends BaseComponent implements OnInit {

  constructor(
      private route: ActivatedRoute,
      readonly loading: LoadingMonitorService,
      readonly contact: ContactService,
      readonly logger: LoggingService,
      readonly assets: AssetService,
      readonly user: UserService,
      readonly chat: ChatService,
      readonly navigator: NavigatorService,
      readonly change: ChangeDetectorRef,
      readonly self: ElementRef) {
    super('contact_page', change, self, loading);
  }

  meta: ProposalWithMeta;

  userObs: Observable<IdentifiablePerson> =
      this.user.currentIdentity
          .pipe(filter(x => x.nonEmpty()))
          .pipe(map(x => x.get()))
          .pipe(modelDebounce(this.unsubscriberObs));

  getImages(): ContextualImageSet {
    return this.assets.getBlueBackground();
  }

  gotoAgentAnnouncementChat(): void {
    this.navigator.pidObservable
      .pipe(take(1))
        .subscribe(id => id.forEach(i => this.navigator.gotoChat(proposalAgentRoomPrefix + i)));
  }

  gotoAgentChat(): void {
    combineLatest(this.user.currentIdentity, this.navigator.getCurrentProposal())
      .pipe(take(1))
      .subscribe(([ou, op]) => {
        const oDetails = op.flatMap(x => x.proposal).map(p => p.getAgentDetails());
        const oAgentId = op.flatMap(x => x.proposal).flatMap(p => p.agent).flatMap(a => a.id).map(i => agentPrefix + i);
        const oUserId = ou.flatMap(u => u.identity);
        this.user.ensureAgentHasAccount(oDetails)
            .then(_ => this.navigator.gotoPrivateMessage(OptionUtils.toSet(oAgentId, oUserId)));
      });
  }

  gotoGroupChat(): void {
    this.navigator.pidObservable
        .pipe(take(1))
        .subscribe(id => id.forEach(i => this.navigator.gotoChat(proposalTravellerRoomPrefix + i)));
  }

  async ngOnInit(): Promise<void> {
    this.meta = await this.navigator.getCurrentProposalNonOptional();
    this.logger.setPage('contact');
    const ps = this.route.snapshot.params;
    this.navigator.setProposalId(ps['id']);
    this.logger.logEventWithProposalAndUser(
        'load_page',
        {page: 'contact'});
    super.ngOnInit();
  }

  sendPrivateMessage(): void {
    combineLatest(this.user.currentIdentity, this.navigator.getCurrentProposal())
        .pipe(take(1))
        .subscribe(([ou, op]) => {
          Option.map2(op.flatMap(x => x.proposal), op.flatMap(x => x.proposal).flatMap(p => p.id), (p, id) =>
              this.chat.choosePeopleAndCreateRoomFor(
                  id.toString(),
                  p,
                  ou.exists(u => u.isAgent()), rm => rm.forEach(r => this.navigator.gotoChat(r))));
        });
  }
}
