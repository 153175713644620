import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {ChartsModule} from 'ng2-charts';
import {FloatingCardComponentModule} from '../../components/floating-card/floating-card.component.module';
import {FloatingHeaderComponentModule} from '../../components/floating-header/floating-header.component.module';
import {HeaderSpacerComponentModule} from '../../components/header-spacer/header-spacer.component.module';
import {ImageBackgroundComponentModule} from '../../components/image-background/image-background.component.module';
import {ImagePreloaderComponentModule} from '../../components/image-preloader/image-preloader.component.module';
import {LoadingComponentModule} from '../../components/loading/loading.component.module';
import {ProposalCardMediumComponentModule} from '../../components/proposal-card-medium/proposal-card-medium.component.module';
import {AgentPage} from './agent.page';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    LoadingComponentModule,
    ChartsModule,
    ImageBackgroundComponentModule,
    FloatingHeaderComponentModule,
    ImagePreloaderComponentModule,
    FloatingCardComponentModule,
    ProposalCardMediumComponentModule,
    HeaderSpacerComponentModule,
    RouterModule.forChild([
      { path: '', component: AgentPage },
    ]),
  ],
  declarations: [AgentPage],
})
export class AgentPageModule { }
