import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {parseNumber, ProposalWithMeta} from '@didgigo/lib-ts';
import {BaseComponent} from '../../lib-ionic/base-component';
import {AssetService} from '../../services/asset.service';
import {LoadingMonitorService} from '../../services/loading-monitor.service';
import {LoggingService} from '../../services/logging.service';
import {MediaService} from '../../services/media.service';
import {NavigatorService} from '../../services/navigator.service';
import {ProposalService} from '../../services/proposal.service';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-prices',
  templateUrl: './prices.page.html',
  styleUrls: ['./prices.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricesPage extends BaseComponent implements OnInit {

  model: ProposalWithMeta;

  constructor(
      private route: ActivatedRoute,
      readonly navigator: NavigatorService,
      readonly media: MediaService,
      readonly assets: AssetService,
      readonly user: UserService,
      readonly logger: LoggingService,
      readonly loading: LoadingMonitorService,
      readonly change: ChangeDetectorRef,
      readonly self: ElementRef,
      readonly proposalService: ProposalService) {
    super('pricing_page', change, self, loading);
  }

  async ngOnInit(): Promise<void> {
    this.logger.setPage('pricing');
    const ps = this.route.snapshot.params;
    this.navigator.setProposalId(ps['id']);
    this.logger.logEventWithProposalAndUser(
        'load_page',
        {page: 'prices'},
        parseNumber(ps.id));
    this.model = await this.navigator.getCurrentProposalForIdNonOptional(parseNumber(ps.id).get());
    super.ngOnInit();
  }
}
