import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ImageIconComponent } from './image-icon.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule.forRoot(),
  ],
  declarations: [ImageIconComponent],
  exports: [ImageIconComponent],
})
export class ImageIconComponentModule { }
