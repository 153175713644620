import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
    ContextualImageSet,
    Image,
    isDayBetween,
    isMidnight,
    Language,
    Metadata,
    now,
    parseDate,
    parseNumber,
    ProposalEntry,
    ProposalEntryWithMeta,
    ProposalWithMeta,
    today,
    tomorrow,
} from '@didgigo/lib-ts';
import {IonContent} from '@ionic/angular';
import {None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {Moment} from 'moment';
import {BasicComparator, ValueComparator} from 'ts-comparators';
import {SlideshowBackgroundComponent} from '../../components/slideshow-background/slideshow-background.component';
import {BaseComponent} from '../../lib-ionic/base-component';
import {AssetService} from '../../services/asset.service';
import {LoadingMonitorService} from '../../services/loading-monitor.service';
import {LoggingService} from '../../services/logging.service';
import {MediaService} from '../../services/media.service';
import {NavigatorService} from '../../services/navigator.service';
import {ProposalService} from '../../services/proposal.service';
import {UserService} from '../../services/user.service';
import {WeatherService} from '../../services/weather.service';

class DayModel {
    constructor(
        readonly firstDay: Option<Moment>,
        readonly lastDay: Option<Moment>,
        readonly morningAccom: Option<ProposalEntryWithMeta>,
        readonly eveningAccom: Option<ProposalEntryWithMeta>,
        readonly day: Moment,
        readonly entryWithMeta: List<ProposalEntryWithMeta>,
        readonly images: List<ContextualImageSet>) {
    }

    getAccommodationLeaveRundown(): string {
        const checkoutTime = this.morningAccom.flatMap(e => e.getEntry().getDropoff().flatMap(en => en.time));
        if (this.morningAccom.exists(a => a.getEntry().isLastDay(this.day)) && checkoutTime.exists(t => !isMidnight(t))) {
            return `Don't sleep in too long because you checkout at ${checkoutTime.get().format('h:mm a')}. `;
        }
        return '';
    }

    private getCity(e: ProposalEntryWithMeta): Option<string> {
        return e.getEntry()
            .getProduct()
            .flatMap(x => x.getLocation())
            .flatMap(x => x.getCity());
    }

    getDay(): Moment {
        return this.day;
    }

    getDayEndLocation(): Option<string> {
        const accomAndDest = Option.of(this.entryWithMeta.reverse().find(e => this.isUsefulEndLocation(e)))
            .flatMap(e => this.getCity(e));

        return accomAndDest.orElse(
            Option.of(this.entryWithMeta.find(e => this.getCity(e).nonEmpty()))
                .flatMap(e => this.getCity(e)),
        );
    }

    getDayOfMonth(): string {
        return this.getMeta().formatDate(this.day, 'DayOfMonth', '2DO', this.getLanguage(), None);
    }

    getDayOfWeek(): string {
        return this.getMeta().formatDate(this.day, 'DayOfWeek', '3D', this.getLanguage(), None);
    }

    getDayOption(): Option<Moment> {
        return Some(this.day);
    }

    getDayRundown(): string {
        if (this.isFirstDay()) {
            return 'Welcome, your trip begins today, we hope you enjoy every moment.';
        } else if (this.isLastDay()) {
            return 'Sadly, your trip has come to an end.' +
                ' We hope you have some amazing memories and we look forward to hearing from you. ';
        }

        return '';
    }

    getDayStartLocation(): Option<string> {
        const accomAndDest = Option.of(this.getEntryWithMeta().find(e => this.isUsefulStartLocation(e)))
            .flatMap(e => this.getCity(e));
        return accomAndDest.orElse(
            Option.of(this.getEntryWithMeta().find(e => this.getCity(e).nonEmpty()))
                .flatMap(e => this.getCity(e)));
    }

    getDayText(m: Moment): string {
        if (m.isSame(today(), 'd')) {
            return this.getMeta().getTranslatedLabelForField('Today', 'Today');
        } else if (m.isSame(tomorrow(), 'd')) {
            return this.getMeta().getTranslatedLabelForField('Tomorrow', 'Tomorrow');
        }

        return this.getMeta().formatDate(m, 'DayText', 'DOMMM', this.getLanguage(), None);
    }

    getDayTitleText(): string {
        if (Option.map2(this.getDayStartLocation(), this.getDayEndLocation(), (s, e) => s !== e).contains(true)) {
            return this.getDayStartLocation().get() + ' to ' + this.getDayEndLocation().get();
        } else if (this.getDayStartLocation().nonEmpty()) {
            return 'In ' + this.getDayStartLocation().get() + ' today';
        } else if (this.isFlightDay() && this.getDayEndLocation().nonEmpty()) {
            return 'Flying to ' + this.getDayEndLocation().get();
        } else if (this.isFlightDay()) {
            return 'Flying today';
        } else if (this.isTransportDay() && this.getDayEndLocation().nonEmpty()) {
            return 'Travelling to ' + this.getDayEndLocation().get();
        } else if (this.isTransportDay()) {
            return 'Travelling today';
        } else if (this.isFirstDay()) {
            return 'Day 1';
        }
        return this.day.format('Do MMM');
    }

    getEntryWithMeta(): List<ProposalEntryWithMeta> {
        return this.entryWithMeta;
    }

    getEveningAccom(): Option<ProposalEntryWithMeta> {
        return this.eveningAccom;
    }

    getEveningAccommodationName(): Option<string> {
        return this.eveningAccom.flatMap(a => a.getDisplayProposalEntryProductName());
    }

    getFirstDay(): Option<Moment> {
        return this.firstDay;
    }

    getHireVehicle(): Option<ProposalEntryWithMeta> {
        return Option.of(this.getEntryWithMeta().find(e => e.isHireVehicle()));
    }

    getHireVehicleLabel(): string {
        return this.getMeta().getTranslatedLabelForField('HireVehicle', 'Hire Vehicle');
    }

    getHireVehicleLeaveRundown(): string {
        const dropoffTime = this.getHireVehicle().flatMap(e => e.getEntry().getDropoff().flatMap(en => en.time));
        if (this.getHireVehicle().exists(v => v.isLastDay(now())) && dropoffTime.exists(t => !isMidnight(t))) {
            return `You must return your hire vehicle before ${dropoffTime.get().format('h:mm a')}. `;
        }
        return '';
    }

    getImages(): List<ContextualImageSet> {
        return this.images;
    }

    getImportantTours(): List<ProposalEntryWithMeta> {
        return this.entryWithMeta.filter(e => this.isImportantTour(e.getEntry()));
    }

    getLanguage(): Option<Language> {
        return Option.of(this.entryWithMeta.first()).flatMap(x => x.getDisplayLanguage());
    }

    getLastDay(): Option<Moment> {
        return this.lastDay;
    }

    getMeta(): Metadata {
        return Option.of(this.entryWithMeta.first()).map(x => x.getMeta()).getOrElse(new Metadata());
    }

    getMonth(): string {
        return this.day.format('MMM');
    }

    getMorningAccom(): Option<ProposalEntryWithMeta> {
        return this.morningAccom;
    }

    getMorningAccommodationName(): Option<string> {
        return this.morningAccom.flatMap(a => a.getDisplayProposalEntryProductName());
    }

    getNewAccommodationRundown(): string {
        if (this.eveningAccom.exists(a => a.isFirstDay(this.day))) {
            const title = this.eveningAccom.flatMap(e => e.getDisplayProposalEntryProductName()).getOrElse('');
            return `${title} is looking forward to welcoming you. `;
        }
        return '';
    }

    getNextDay(): string {
        return this.getDayText(this.day.clone().add(1, 'd'));
    }

    getOvernightRundown(): string {
        if (this.lastNightFlight()) {
            return 'We hope you enjoyed your rest last night on your long haul flight. ';
        } else if (this.lastNightTransit()) {
            return 'We hope you enjoyed your rest last night while in transit. ';
        } else if (this.lastNightTour().exists(t => t.isFirstDay(this.day))) {
            return `We hope you are enjoying your experience on ${this.lastNightTour().flatMap(t => t.getDisplayProposalEntryProductName()).get()}. `;
        } else if (this.morningAccom.exists(x => x.isFirstDay(this.day))) {
            return `We hope you enjoyed your rest last night at ${this.getMorningAccommodationName().get()}. `;
        }
        return '';
    }

    getPreviousDay(): string {
        return this.getDayText(this.day.clone().subtract(1, 'd'));
    }

    getProposalWithMeta(): Option<ProposalWithMeta> {
        return Option.of(this.entryWithMeta.first()).map(x => x.getProposalWithMeta());
    }

    getProposalWithMetaNonOptional(): ProposalWithMeta {
        return this.getProposalWithMeta()
            .getOrElse(new ProposalWithMeta());
    }

    getRundown(): string {
        return this.getDayRundown()
            + this.getOvernightRundown()
            + this.getAccommodationLeaveRundown()
            + this.getHireVehicleLeaveRundown()
            + this.getNewAccommodationRundown();
    }

    getTravelInsurance(): Option<ProposalEntryWithMeta> {
        return Option.of(this.getEntryWithMeta().find(e => e.isInsurance()));
    }

    getTravelInsuranceLabel(): string {
        return this.getMeta().getTranslatedLabelForField('TravelInsurance', 'Travel Insurance');
    }

    hasMultidayEntry(): boolean {
        return this.getEntryWithMeta().some(t => t.isMultipleDays());
    }

    isFirstDay(): boolean {
        return this.firstDay.exists(d => d.isSame(this.day, 'd'));
    }

    isFlightDay(): boolean {
        return this.isTransportDay() && this.getEntryWithMeta().some(t => t.isFlight());
    }

    isImportantTour(e: ProposalEntry): boolean {
        if (e.isTour()) {
            return true;
        }

        if (e.isDestination() || e.isInsurance()) {
            return e.isFirstDay(this.day);
        }

        if (e.isHireVehicle() || e.isAccommodation() || e.isTransport() || e.isMultipleDays()) {
            return e.isFirstDay(this.day) || e.isLastDay(this.day);
        }

        return true;
    }

    isLastDay(): boolean {
        return this.lastDay.exists(d => d.isSame(this.day, 'd'));
    }

    isNextDayOnTrip(): boolean {
        const nextDay = this.day.clone().add(1, 'day');
        return isDayBetween(this.firstDay, this.lastDay, nextDay);
    }

    isOnTrip(m: Moment): boolean {
        return isDayBetween(this.firstDay, this.lastDay, m);
    }

    isPrevDayOnTrip(): boolean {
        const prevDay = this.day.clone().subtract(1, 'day');
        return isDayBetween(this.firstDay, this.lastDay, prevDay);
    }

    isTransportDay(): boolean {
        return this.getEntryWithMeta().every(t => t.isTransport() || t.isCustom());
    }

    isTripInProgress(): boolean {
        return isDayBetween(this.firstDay, this.lastDay, this.day);
    }

    isUsefulEndLocation(e: ProposalEntryWithMeta): boolean {
        return (e.isDestination() || e.isAccommodation() || e.isMultipleDays()) && this.getCity(e).nonEmpty();
    }

    isUsefulStartLocation(e: ProposalEntryWithMeta): boolean {
        return (e.isDestination() || e.isAccommodation() || e.isMultipleDays()) && this.getCity(e).nonEmpty();
    }

    lastNightFlight(): boolean {
        return !this.getEntryWithMeta().filter(t => t.isFlight() && t.isMultipleDays() && !t.isFirstDay(this.day)).isEmpty();
    }

    lastNightTour(): Option<ProposalEntryWithMeta> {
        return Option.of(
            this.getEntryWithMeta().find(t =>
                !t.isTransport()
                && !t.isHireCar()
                && !t.isCampervan()
                && t.isMultidayTour()
                && !t.isFirstDay(this.day)));
    }

    lastNightTransit(): boolean {
        return !this.getEntryWithMeta().filter(t => t.isTransport() && t.isMultipleDays() && !t.isFirstDay(this.day)).isEmpty();
    }

    shouldShowMap(): boolean {
        return this.getProposalWithMeta().nonEmpty() && this.getEntryWithMeta().some(x => x.isUsefulMarkerOnMap());
    }
}

@Component({
    selector: 'app-day',
    templateUrl: './day-page.page.html',
    styleUrls: ['./day-page.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DayPage extends BaseComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        readonly mediaService: MediaService,
        readonly navigator: NavigatorService,
        readonly logger: LoggingService,
        readonly assets: AssetService,
        readonly user: UserService,
        readonly loading: LoadingMonitorService,
        readonly weather: WeatherService,
        readonly change: ChangeDetectorRef,
        readonly self: ElementRef,
        readonly proposalService: ProposalService) {
        super('day_page', change, self, loading);
    }

    @ViewChild('dayScroll', {static: false})
    content: IonContent;
    day: DayModel;
    meta: ProposalWithMeta;
    @ViewChild(SlideshowBackgroundComponent, {static: false})
    slides: SlideshowBackgroundComponent;

    getBestImages(entries: List<ProposalEntryWithMeta>): List<ContextualImageSet> {
        const booleanComparator = new BasicComparator<boolean>();
        const destinationComparator = new ValueComparator(e => e.isDestination(), booleanComparator).reverse();
        const tourComparator = new ValueComparator(e => e.isDayTour() || e.isMultidayTour(), booleanComparator).reverse();
        const accomComparator = new ValueComparator(e => e.isAccommodation(), booleanComparator).reverse();
        const bestImageComparator = destinationComparator.then(tourComparator).then(accomComparator);

        const all: List<List<ContextualImageSet>> = entries
            .filter(e => !e.isFlight() && !e.isHireVehicle())
            .sort((a, b) => bestImageComparator.compare(a, b))
            .map(e => e.getBestImages(10, true).map(i => this.getProductImage(i)));

        if (all.size === 0) {
            return List();
        }

        const first: List<ContextualImageSet> = Option.of(all.first()).get();
        if (all.size === 1) {
            return first;
        }

        const zipped: List<List<ContextualImageSet>> = first.zipAll(...all.shift().toArray());

        return zipped.flatMap(x => x).filter(l => l ? !l.isEmpty() : false);

    }

    private async getDay(date: Moment, proposal: ProposalWithMeta): Promise<DayModel> {
        const entriesOnDay = proposal.getSortedEntries().filter(x => x.getEntry().isDuring(date));
        const dayImages = await this.getDayImages(entriesOnDay);
        return new DayModel(
            proposal.getProposal().getStartDateTime(),
            proposal.getProposal().getEndDateTime(),
            this.getLastNightAccommodation(entriesOnDay, date),
            this.getTonightAccommodation(entriesOnDay, date),
            date,
            entriesOnDay,
            dayImages,
        );
    }

    async getDayImages(entries: List<ProposalEntryWithMeta>): Promise<List<ContextualImageSet>> {
        const productImages = this.getBestImages(entries);
        const currentWelcomeImage = await this.navigator.getCurrentWelcomeImage();
        const welcomeImage =
            List.of(currentWelcomeImage
                .map(i => this.mediaService.getWelcomeImage(i))
                .getOrElse(this.assets.getBlueBackground()));

        const imagesToShow = productImages.filter(inner => !inner.isEmpty()).take(20);

        return imagesToShow.isEmpty() ? welcomeImage : imagesToShow;
    }

    getLastNightAccommodation(entries: List<ProposalEntryWithMeta>, date: Moment): Option<ProposalEntryWithMeta> {
        return Option.of(entries.find(e => e.isAccommodation() && !e.isFirstDay(date)));
    }

    private getProductImage(i: Image): ContextualImageSet {
        return this.mediaService.getProductImage(i);
    }

    getTonightAccommodation(entries: List<ProposalEntryWithMeta>, date: Moment): Option<ProposalEntryWithMeta> {
        return Option.of(entries.findLast(e => e.isAccommodation() && !e.getEntry().isLastDay(date)));
    }

    gotoEntry(m: ProposalEntryWithMeta): void {
        m.getEntry()
            .getId()
            .forEach(id => this.navigator.gotoEntry(m.getProposalWithMeta().getProposalId().get(), id));
    }

    nextDay(): void {
        this.navigator.gotoDayByDayForDate(this.day.day.clone().startOf('day').add(1, 'd'));
    }

    async ngOnInit(): Promise<void> {
        const ps = this.route.snapshot.params;
        const dateOpt = parseDate(ps['date']);

        this.logger.setPage('day_by_day');
        this.logger.logEventWithProposalAndUser('load_page', {page: 'day_by_day'}, parseNumber(ps.id));
        this.meta = await this.navigator.getCurrentProposalForIdNonOptional(parseNumber(ps.id).get());
        this.day = await this.getDay(dateOpt.get(), this.meta);
        super.ngOnInit();
    }

    prevDay(): void {
        this.navigator.gotoDayByDayForDate(this.day.day.clone().startOf('day').subtract(1, 'd'));
    }
}
