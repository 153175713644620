import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {Plugins} from '@capacitor/core';
import {
  ComparisonUtils,
  ContextualImageSet,
  IdentifiablePerson,
  isWithinTheNextXMonths,
  modelDebounce,
  modelThrottle,
  OptionUtils,
  Proposal,
  ProposalWithMeta,
  triggerSingleEffect,
} from '@didgigo/lib-ts';
import {ActionSheetController} from '@ionic/angular';
import {Option} from 'funfix-core';
import {List} from 'immutable';
import {Moment} from 'moment';
import {BehaviorSubject, combineLatest, from, Observable, of, ReplaySubject} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {ValueComparator} from 'ts-comparators';
import {ProposalMediumCardModel} from '../../components/proposal-card-medium/proposal-card-medium.component';
import {BaseComponent} from '../../lib-ionic/base-component';
import {AssetService} from '../../services/asset.service';
import {BrowserService} from '../../services/browser.service';
import {ChatService} from '../../services/chat.service';
import {CompanyProposalsService} from '../../services/company-proposals.service';
import {LoadingMonitorService} from '../../services/loading-monitor.service';
import {LoggingService} from '../../services/logging.service';
import {MediaService} from '../../services/media.service';
import {NavigatorService} from '../../services/navigator.service';
import {ProposalDownloaderService} from '../../services/proposal-downloader.service';
import {ProposalService} from '../../services/proposal.service';
import {UserService} from '../../services/user.service';

const {Keyboard} = Plugins;

const proposalStartComparator =
    new ValueComparator<ProposalWithMeta, Option<Moment>>(p => p.proposal.flatMap(x => x.start).flatMap(s => s.time), ComparisonUtils.optionDayComparator);

const proposalEndComparator =
    new ValueComparator<ProposalWithMeta, Option<Moment>>(p => p.proposal.flatMap(x => x.end), ComparisonUtils.optionDayComparator);

const proposalCreatedComparator =
    new ValueComparator<ProposalWithMeta, Option<Moment>>(p => p.proposal.flatMap(x => x.created), ComparisonUtils.optionMomentComparator);

const proposalTravellingTimeComparator = proposalStartComparator.then(proposalEndComparator);

class AgentModel {
  constructor(
    readonly name: Option<string>,
    readonly photo: Option<ContextualImageSet>,
    readonly companyName: Option<string>,
    readonly companyLogo: Option<ContextualImageSet>,
  ) { }
}

class Filters {
  constructor(
    readonly text: string,
    readonly isShowAll: boolean,
    readonly isSortByTravelling: boolean) { }
}

class AnalyticsModel {
  constructor(
    readonly keys: string[],
    readonly months: number[],
    readonly currentlyTravelling: number,
  ) { }

  getChartOptions(): object {
    return {
      scaleShowVerticalLines: false,
      responsive: true,
      scales: {
        xAxes: [{
          gridLines: {
            display: false,
          },
        }],
        yAxes: [{
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
          },
        }],
      },
      title: {
        display: false,
      },
      fontFamily: 'Avenir-Book',
    };
  }

  getColours(): object[] {
    return [{
      backgroundColor: '#00B1FB',
      borderColor: '#00a1e4',
      pointBackgroundColor: '#00B1FB',
      pointBorderColor: '#00a1e4',
      pointHoverBackgroundColor: '#00a1e4',
      pointHoverBorderColor: '#00a1e4',
    }];
  }

  getData(): object[] {
    return [{
      data: this.months,
      label: null,
      borderWidth: 0,
      fill: false,
      fontFamily: 'Avenir-Book',
    }];
  }

  getLabels(): string[] {
    return this.keys;
  }
}

@Component({
  selector: 'app-agent',
  templateUrl: './agent.page.html',
  styleUrls: ['./agent.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentPage extends BaseComponent implements OnInit {

  constructor(
    readonly navigator: NavigatorService,
    readonly downloader: ProposalDownloaderService,
    readonly assets: AssetService,
    readonly media: MediaService,
    readonly user: UserService,
    readonly logger: LoggingService,
    readonly allProposals: CompanyProposalsService,
    readonly browser: BrowserService,
    readonly loading: LoadingMonitorService,
    readonly change: ChangeDetectorRef,
    readonly actionSheet: ActionSheetController,
    readonly route: ActivatedRoute,
    readonly chat: ChatService,
    readonly self: ElementRef,
    readonly proposalService: ProposalService) {
    super('agent_page', change, self, loading);

    this.proposalsObservable = this.getProposalObservable();
    this.agentObservable = this.getAgentObservable();
    this.analyticsObservable = this.getAnalyticsObservable();
    this.displayAllItineraries = this.filtersObservable.pipe(map(x => x.isShowAll));
  }

  agentObservable: Observable<AgentModel>;

  analyticsObservable: Observable<AnalyticsModel>;

  displayAllItineraries: Observable<boolean>;

  filters: BehaviorSubject<Filters> = new BehaviorSubject<Filters>(new Filters('', false, true));
  filtersObservable: Observable<Filters> = this.filters.pipe(modelThrottle(this.unsubscriberObs));

  proposals: ReplaySubject<List<ProposalWithMeta>> = new ReplaySubject<List<ProposalWithMeta>>(1);
  proposalsObservable: Observable<List<ProposalWithMeta>>;

  proposalsToDisplayObservable: Observable<List<ProposalWithMeta>> =
    this.proposalsObservable.pipe(map(ps => ps.take(20)))
      .pipe(modelDebounce(this.unsubscriberObs));
  sortByTravelling: Observable<boolean> = this.filtersObservable.pipe(map(x => x.isSortByTravelling));

  userObservable: Observable<Option<IdentifiablePerson>> =
      this.user.currentIdentity
          .pipe(map(ou => ou.filter(o => o.isAgent())))
          .pipe(modelDebounce(this.unsubscriberObs));

  computeMonthlyNumbers(ps: List<ProposalWithMeta>): [string[], number[]] {
    const groupedByMonth =
      ps
        .filter(p =>
            p.proposal.flatMap(x => x.start)
            .flatMap(s => s.time)
            .map(x => isWithinTheNextXMonths(x, 6)).contains(true))
        .groupBy(p =>
            p.proposal.flatMap(x => x.start)
            .flatMap(s => s.time)
            .map(t => t.format('MMM YY'))
            .getOrElse('Unknown'));
    const sizes = groupedByMonth.map(v => v.flatMap(p => OptionUtils.toList(p.proposal.flatMap(x => x.reference))).toSet().size);

    const ks: string[] = [];
    const vs: number[] = [];

    sizes.entrySeq()
      .forEach(e => {
        ks.push(e[0]);
        vs.push(e[1]);
      });

    return [ks, vs];
  }

  download(id: string): void {
    this.downloader.downloadById(id);
  }

  getAgentObservable(): Observable<AgentModel> {
    return combineLatest(this.userObservable, this.proposals.asObservable())
        .pipe(map(([user, proposals]) =>
            Option.of(proposals.find(p => p.proposal.flatMap(x => x.agent).exists(a => a.id === user.flatMap(u => u.getPersonId()))))))
        .pipe(map(op => new AgentModel(
            op.flatMap(x => x.proposal).flatMap(p => p.agent).flatMap(a => a.getFullName()),
            op.flatMap(x => x.proposal).flatMap(p => p.agent).flatMap(a => a.image).map(i => this.media.getAgentImage(i)),
            op.flatMap(x => x.proposal).flatMap(p => p.getDisplayCompanyName()),
            op.flatMap(x => x.proposal).flatMap(p => p.getDisplayCompanyLogo())
                .map(i => this.media.getProposalCompanyLogo(i.getHref().getOrElse(''))),
        )))
        .pipe(modelDebounce(this.unsubscriberObs));
  }

  getAnalyticsObservable(): Observable<AnalyticsModel> {
    return this.proposalsObservable
        .pipe(map(ps => {
          const data = this.computeMonthlyNumbers(ps);
          return new AnalyticsModel(
              data[0],
              data[1],
              this.getCurrentTravellers(ps),
          );
        }))
        .pipe(modelDebounce(this.unsubscriberObs));
  }

  private getCurrentTravellers(ps: List<ProposalWithMeta>): number {
    return ps
        .filter(p => p.proposal.exists(x => x.isCurrentlyTravelling()))
        .flatMap(p => OptionUtils.toList(p.proposal.flatMap(x => x.reference)))
      .toSet()
      .size;
  }

  getImages(): ContextualImageSet {
    return this.assets.getBlueBackground();
  }

  getModel(proposal: ProposalWithMeta): ProposalMediumCardModel {
    return new ProposalMediumCardModel(
        proposal.getProposalId(),
        proposal,
        proposal.proposal.flatMap(x => x.getTitle()),
        proposal.getDisplayApiReference(),
        proposal.proposal.flatMap(x => x.status),
        proposal.getDisplayAgentName(),
        proposal.getDisplayDaysString(),
        proposal.proposal.flatMap(x => x.agent).flatMap(a => a.getImage()).map(i => this.media.getAgentImage(i)),
    );
  }

  getProposalObservable(): Observable<List<ProposalWithMeta>> {
    return combineLatest(this.proposals.asObservable(), this.filtersObservable, this.userObservable)
        .pipe(map(([proposals, filters, self]) =>
            this.sortProposals(
                filters.isSortByTravelling,
                proposals.filter(p => this.shouldIncludeProposal(filters, p, self)))))
        .pipe(modelDebounce(this.unsubscriberObs));
  }

  hideKeyboard(): void {
    Keyboard.hide();
  }

  ngOnInit(): void {
    const f = () => super.ngOnInit();

    this.logger.setPage('agent');
    this.route.params
      .pipe(modelThrottle(this.unsubscriberObs))
      .subscribe(ps => {
        this.logger.logEventWithProposalAndUser(
          'load_page',
            {page: 'agent'});
        f();
      });

    this.populateProposals();
  }

  onProposalClick(id: string, model: Proposal): void {
    from(this.actionSheet.create({
      header: 'Choose Action',
      buttons: [{
        text: 'Download Proposal',
        cssClass: 'primaryToDark',
        handler: () => {
          this.download(id);
        },
      }, {
        text: 'Send private message to...',
        cssClass: 'primaryToDark',
        handler: () => {
          this.chat.choosePeopleAndCreateRoomFor(
            id,
            model,
            true, rm => rm.forEach(r => this.navigator.gotoChat(r)));
        },
      }, {
        text: 'Send message to all travellers',
        cssClass: 'primaryToDark',
        handler: () => {
          this.navigator.gotoChat('PRA' + id);
        },
      }, {
        text: 'Cancel',
        cssClass: 'primaryToDark',
        role: 'cancel',
        handler: () => { },
      }, {
        text: 'View in Didgigo',
        cssClass: 'primaryToDark',
        handler: () => {
          this.browser.browseTo(`http://didgigo.com/ProposalManagement.aspx?panel=Edit_Proposal&proposal_id=${id}`);
        },
      }],
    }))
      .pipe(switchMap(x => from(x.present())))
      .pipe(triggerSingleEffect());
  }

  populateProposals(): void {
    this.userObservable
      .pipe(take(1))
      .pipe(switchMap(ou =>
        ou.flatMap(u => u.getPersonId())
          .map(i => this.allProposals.getProposals(i.toString()))
            .getOrElse(of(List<ProposalWithMeta>()))))
      .subscribe(rs => this.proposals.next(rs));
  }

  searchFor(e: any): void {
    const current = this.filters.getValue();
    this.filters.next(new Filters(e.target.value, current.isShowAll, current.isSortByTravelling));
  }

  private shouldIncludeProposal(filters: Filters, p: ProposalWithMeta, self: Option<IdentifiablePerson>): boolean {
    return this.shouldIncludeSelf(filters, p, self.flatMap(x => x.getPersonId()).map(i => i.toString()));
  }

  private shouldIncludeSelf(input: Filters, proposal: ProposalWithMeta, self: Option<string>): boolean {
    const lowerInput = input.text.toLowerCase();
    return (input.isShowAll ||
        (self.isEmpty() || proposal.proposal.flatMap(x => x.agent).flatMap(a => a.id)
        .exists(id => self.exists(s => s === id.toString()))))
        && (proposal.proposal.flatMap(x => x.reference).exists(n => n.toLowerCase().includes(lowerInput))
            || proposal.proposal.flatMap(x => x.title).exists(n => n.toLowerCase().includes(lowerInput))
            || proposal.proposal.flatMap(x => x.status).exists(n => n.toLowerCase().includes(lowerInput))
            || proposal.proposal.flatMap(x => x.agent).flatMap(a => a.getFullName()).exists(n => n.toLowerCase().includes(lowerInput)));
  }

  private sortProposals(byTravelling: boolean, ps: List<ProposalWithMeta>): List<ProposalWithMeta> {
    if (byTravelling) {
      return ps.sort((a, b) => proposalTravellingTimeComparator.compare(a, b));
    } else {
      return ps.sort((a, b) => proposalCreatedComparator.compare(a, b));
    }
  }

  toggleMine(e): void {
    const current = this.filters.getValue();
    this.filters.next(new Filters(current.text, e.target.checked, current.isSortByTravelling));
  }

  toggleTravelling(e): void {
    const current = this.filters.getValue();
    this.filters.next(new Filters(current.text, current.isShowAll, e.target.checked));
  }
}
