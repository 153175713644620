import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { EntryCardSmallComponentModule } from '../entry-card-small/entry-card-small.component.module';
import { FloatingCardComponentModule } from '../floating-card/floating-card.component.module';
import { ImagePreloaderComponentModule } from '../image-preloader/image-preloader.component.module';
import { EntryCardMediumComponent } from './entry-card-medium.component';

@NgModule({
  imports: [
    CommonModule,
    EntryCardSmallComponentModule,
    ImagePreloaderComponentModule,
    FloatingCardComponentModule,
    IonicModule.forRoot(),
  ],
  declarations: [EntryCardMediumComponent],
  exports: [EntryCardMediumComponent],
})
export class EntryCardMediumComponentModule { }
