import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ProposalEntryWithMeta} from '@didgigo/lib-ts';
import {None, Option} from 'funfix-core';
import {AssetService} from '../../services/asset.service';
import {MediaService} from '../../services/media.service';
import {NavigatorService} from '../../services/navigator.service';

@Component({
    selector: 'app-entry-card-medium',
    templateUrl: './entry-card-medium.component.html',
    styleUrls: ['./entry-card-medium.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntryCardMediumComponent implements OnInit {

    constructor(
        readonly assets: AssetService,
        readonly mediaService: MediaService,
        readonly navigator: NavigatorService) {
    }

    @Input()
    displayDistances = false;

    @Input()
    mapCard = false;

    @Input()
    model: ProposalEntryWithMeta;

    @Input()
    parentEntry: Option<ProposalEntryWithMeta> = None;

    gotoEntryPage(): void {
        const proposalId = this.model.getProposalWithMeta().getProposalId();
        if (this.parentEntry.isEmpty()) {
            this.model.getId().forEach(a => this.navigator.gotoEntry(proposalId.get(), a));
        } else {
            Option.map2(this.model.getProductId(),
                this.parentEntry.flatMap(x => x.getId()),
                (a, b) => this.navigator.gotoSubproduct(proposalId.get(), b, a));
        }
    }

    ngOnInit() {
    }

}
