import {Injectable} from '@angular/core';
import {now, parseDate, parseList, parseObject, ProposalEntry} from '@didgigo/lib-ts';
import {None, Option} from 'funfix-core';
import {List} from 'immutable';
import {Moment} from 'moment';
import {from, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {RestService} from './rest.service';
import {UserSettingsService} from './user-settings.service';

const apiKey = '01f230b1ba5a7f63ad553232881c64f4';
const openWeatherApiUrl = `https://api.openweathermap.org/data/2.5/forecast`;

@Injectable({
  providedIn: 'root',
})
export class WeatherService {

  // TODO: Cache and store weather data
  constructor(
    readonly rest: RestService,
    readonly settings: UserSettingsService,
  ) { }

  private extractWeatherAtTime(m: Moment, forecast: List<object>): Option<object> {
    return Option.of(forecast.find(f => parseDate(f['dt']).exists(d => m.diff(d, 'h') < 3)));
  }

  getWeatherForecastForEntry(entry: ProposalEntry): Observable<Option<object>> {
    const startTime =
      entry.start
        .flatMap(s => s.time)
        .filter(m => now().diff(m, 'd') < 6);

    return Option.map2(startTime, this.getWeatherForecastForProduct(entry),
      (m, ow) => ow.pipe(map(w => this.extractWeatherAtTime(m, parseList(w['list'], parseObject)))))
      .getOrElse(of(None));
  }

  getWeatherForecastForProduct(entry: ProposalEntry): Option<Observable<object>> {
    const coords = entry.product.flatMap(p => p.location).flatMap(l => l.gpsCoords);
    const lat = coords.flatMap(c => c.latitude);
    const long = coords.flatMap(c => c.longitude);

    const units = this.settings.isMetric() ? 'metric' : 'imperial';

    return Option.map2(lat, long, (a, b) =>
        from(this.rest.getJsonFrom(`${openWeatherApiUrl}?lat=${a}&lon=${b}&APPID=${apiKey}&units=${units}`)));
  }
}
