import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ContextualImageSet, fiveSecondUpdateTimer, modelThrottle, OptionUtils, parseNumber, ProposalWithMeta} from '@didgigo/lib-ts';
import {AlertController} from '@ionic/angular';
import {List} from 'immutable';
import {Observable} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {SlideshowBackgroundComponent} from '../../components/slideshow-background/slideshow-background.component';
import {BaseComponent} from '../../lib-ionic/base-component';
import {AssetService} from '../../services/asset.service';
import {DialogService} from '../../services/dialog-service';
import {LoadingMonitorService} from '../../services/loading-monitor.service';
import {LoggingService} from '../../services/logging.service';
import {NavigatorService} from '../../services/navigator.service';
import {ProposalDownloaderService, ProposalDownloadStatus} from '../../services/proposal-downloader.service';
import {ProposalService} from '../../services/proposal.service';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-download',
  templateUrl: './download.page.html',
  styleUrls: ['./download.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadPage extends BaseComponent implements OnInit, AfterViewInit {

  constructor(
      private route: ActivatedRoute,
      readonly navigator: NavigatorService,
      readonly alert: AlertController,
      readonly assets: AssetService,
      readonly logger: LoggingService,
      readonly change: ChangeDetectorRef,
      readonly user: UserService,
      readonly proposalLoader: ProposalDownloaderService,
      readonly loading: LoadingMonitorService,
      readonly dialog: DialogService,
      readonly self: ElementRef,
      readonly proposalService: ProposalService) {
    super('download_page', change, self, loading);
  }

  model: ProposalWithMeta;

  @ViewChild(SlideshowBackgroundComponent, {static: false})
  slides: SlideshowBackgroundComponent;

  status: Observable<ProposalDownloadStatus>;

  private async downloadItinerary(): Promise<void> {
    const ps = this.route.snapshot.params;
    console.log('Downloading', ps.id);
    return this.proposalLoader.downloadProposal(ps.id)
        .then(success => {
          if (this.navigator.isActivePage(`/download/${ps.id}`)) {
            if (success) {
              this.navigator.gotoWelcome(true);
            } else {
              this.navigator.gotoItineraries();
            }
          }
        });
  }

  getImages(): List<ContextualImageSet> {
    return List.of(this.assets.getBlueBackground());
  }

  ngAfterViewInit(): void {
    this.status = this.proposalLoader.status
        .pipe(filter(x => x.newImageToShow.nonEmpty() || !x.hasStarted()))
        .pipe(modelThrottle(this.unsubscriberObs, () => fiveSecondUpdateTimer))
        .pipe(tap(x => this.slides?.addImages(OptionUtils.toList(x.newImageToShow))));
  }

  async ngOnInit(): Promise<void> {
    this.proposalLoader.reset();

    this.logger.setPage('download');
    const ps = this.route.snapshot.params;
    this.navigator.setProposalId(ps.id);

    this.downloadItinerary().catch(err => {
      this.dialog.showToast(`Failed to download proposal, try again shortly`);
      console.error(err);
    });

    this.model = await this.navigator.getCurrentProposalForIdNonOptional(+ps.id);

    this.logger.logEventWithProposalAndUser(
        'load_page',
        {page: 'download'},
        parseNumber(ps.id));
    super.ngOnInit();
  }
}
