import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NewLinePipe} from './new-line.pipe';

@NgModule({
    declarations: [
        NewLinePipe,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
    ],
    exports: [NewLinePipe],
})
export class NewLinePipeModule {
}
