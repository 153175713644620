import {Inject, Injectable} from '@angular/core';
import {DidgigoApi} from '@didgigo/lib-ts';

@Injectable({
    providedIn: 'root',
})
export class DidgigoApiService extends DidgigoApi {

    constructor(
        @Inject('apiKey') public apiKey: string,
        @Inject('apiServer') public apiServer: string,
    ) {
        super(apiKey, apiServer);
    }
}
