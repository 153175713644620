import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ContextualImageSet, RoomData } from '@didgigo/lib-ts';
import { Option } from 'funfix-core';
import { Moment } from 'moment';
import { AssetService } from '../../services/asset.service';
import { ChatService } from '../../services/chat.service';

@Component({
  selector: 'app-channel-card',
  templateUrl: './channel-card.component.html',
  styleUrls: ['./channel-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelCardComponent implements OnInit {

  @Input()
  model : RoomMessagePageData;

  constructor(
    readonly assets : AssetService,
    readonly chat : ChatService) {
  }

  ngOnInit() { }
}

export class RoomMessagePageData {
  toTrack = {
    title: this.title,
    subtitle: this.subtitle,
    unread: this.unreadCount,
    traveller: this.travellerCount,
    image: this.image,
  };

  constructor(
    readonly id : string,
    readonly underlying : RoomData,
    readonly title : string,
    readonly subtitle : Option<string>,
    readonly travellerCount : number,
    readonly unreadCount : number,
    readonly image : Option<ContextualImageSet>,
    readonly lastMessage : Option<Moment>,
    readonly isStaff : boolean,
    readonly type : string) {
  }

  getTracked() : any {
    return this.toTrack;
  }
}
