import { Component, Input, OnInit } from '@angular/core';
import { ContextualImageSet, IdentifiablePerson } from '@didgigo/lib-ts';
import { Option } from 'funfix-core';
import { AssetService } from '../../services/asset.service';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss'],
})
export class UserIconComponent implements OnInit {

  @Input()
  idx: number;

  @Input()
  sender: Option<IdentifiablePerson>;

  constructor(
    readonly assets: AssetService,
    readonly theme: ThemeService,
  ) { }

  ngOnInit() {
  }

  hasPhoto(): boolean {
    return this.sender.flatMap((s) => s.image).filter((i) => !i.image.uri.isEmpty()).nonEmpty();
  }

  getPhoto(): ContextualImageSet {
    return this.sender.flatMap((s) => s.image).get();
  }

  getTripigoImage() {
    return this.assets.getTripigoSvgLogo();
  }

  getStyle() {
    return this.theme.getFGBGColourStyle(this.idx);
  }
}
