import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { HeaderSpacerComponent } from './header-spacer.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule.forRoot(),
  ],
  declarations: [HeaderSpacerComponent],
  exports: [HeaderSpacerComponent],
})
export class HeaderSpacerComponentModule { }
