import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AssetService } from '../../services/asset.service';

@Component({
  selector: 'app-slider-card',
  templateUrl: './slider-card.component.html',
  styleUrls: ['./slider-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderCardComponent implements OnInit {

  @Input()
  clickthroughFiller = false;

  @Input()
  hideCircle = false;

  constructor(readonly assets : AssetService) { }

  ngOnInit() {
  }
}
