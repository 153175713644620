import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ContactCardComponentModule } from '../contact-card/contact-card.component.module';
import { HeaderSpacerComponentModule } from '../header-spacer/header-spacer.component.module';
import { ContactPanelComponent } from './contact-panel.component';

@NgModule({
  imports: [
    CommonModule,
    HeaderSpacerComponentModule,
    ContactCardComponentModule,
    IonicModule.forRoot(),
  ],
  declarations: [ContactPanelComponent],
  exports: [ContactPanelComponent],
})
export class ContactPanelComponentModule { }
