import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {
  agentPrefix,
  ChatMessage,
  ChatMessageData,
  ChatMessageSerializer,
  convertMapToObj,
  IdentifiablePerson,
  modelDebounce,
  now,
  OptionUtils,
  parseList,
  parseListSerializable,
  parseString,
  PromiseUtils,
  Proposal,
  proposalAgentRoomPrefix,
  RoomAndMessageData,
  RoomData,
  RoomDataJsonSerializer,
  travellerDidgigoPrefix,
  triggerSingleEffect,
} from '@didgigo/lib-ts';
import {AlertController} from '@ionic/angular';
import {AlertInput} from '@ionic/core';
import * as firebase from 'firebase';
import {None, Option, Some} from 'funfix-core';
import {List, Map, Set} from 'immutable';
import {Moment} from 'moment';
import {combineLatest, from, Observable, of, ReplaySubject} from 'rxjs';
import {catchError, filter, map, switchMap, take} from 'rxjs/operators';
import {StringCacheProviderService} from './cache-provider.service';
import {ConfigurationService} from './configuration.service';
import {ProposalService} from './proposal.service';
import {UserService} from './user.service';
import QuerySnapshot = firebase.firestore.QuerySnapshot;

@Injectable({
  providedIn: 'root',
})
export class ChatService {

  constructor(
    readonly configuration: ConfigurationService,
    readonly cacheProvider: StringCacheProviderService,
    readonly user: UserService,
    readonly alert: AlertController,
    readonly proposals: ProposalService,
    readonly db: AngularFirestore,
  ) {
  }

  cache = this.cacheProvider.getPrefixedCache('chat');

  rooms: Observable<Map<string, RoomAndMessageData>> =
    this.observeRoomMap()
      .pipe(modelDebounce());

  choosePeopleAndCreateRoomFor(proposalId: string, model: Proposal,
                               isCurrentlyAgent: boolean, cb: (rm: Option<string>) => any): Observable<void> {
    return from(this.getAllIdentitiesForProposal(proposalId, model))
      .pipe(map(ids =>
        ids.filter(id => !id.isGuest() && (isCurrentlyAgent || id.isTraveller()))
          .map<AlertInput>(i => {
            return {
              type: 'checkbox',
              cssClass: 'primaryToDark',
              label: (i.hasApp() ? '📱 ' : '') + i.getFullNameAndType(),
              value: i.identity.getOrElse(''),
            };
          })))
      .pipe(switchMap(ids => from(this.alert.create({
        header: 'Create group message',
        inputs: ids.toArray(),
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'primaryToDark',
            handler: () => {
            },
          },
          {
            text: 'Ok',
            cssClass: 'primaryToDark',
            handler: data => {
              this.user.currentIdentity
                .pipe(take(1))
                .subscribe(ou => {
                  const to = parseList(data, parseString).filter(s => s.trim().length !== 0);
                  const group = to.concat(OptionUtils.toList(ou.flatMap(u => u.identity)));
                  this.createPrivateMessageRoomIfNotPresent(ou.flatMap(u => u.identity).getOrElse('?'), group.toSet())
                    .subscribe(res => cb(Some(res)));
                });
            },
          }],
      }))))
      .pipe(switchMap(x => from(x.present())))
      .pipe(triggerSingleEffect());
  }

  // Creates a room if it does not exist, otherwise uses any existing room
  // Note: People should include the creator
  createPrivateMessageRoomIfNotPresent(creator: string, people: Set<string>): Observable<string> {
    return this.getPrivateMessageId(people)
      .pipe(switchMap(or => {
        if (or.nonEmpty()) {
          return of(or.get());
        } else {
          const id = creator + ':' + now().toISOString();
          const roomData = new RoomData(
            Some(id),
            None,
            Map(people.map<[string, Moment]>(i => [i, now()])));
          return from(this.db.collection(this.configuration.getFirestoreRoomsCollectionId())
            .doc(id)
            .set(RoomDataJsonSerializer.instance.toJson(roomData)))
            .pipe(switchMap(_ => of(id)));
        }
      }));
  }

  private createRoomInFirebase(roomTable, id: string): Observable<Option<RoomData>> {
    const roomData = new RoomData(Some(id), None, Map());
    return from(roomTable.set(RoomDataJsonSerializer.instance.toJson(roomData)))
      .pipe(map(_ => Some(roomData)));
  }

  // Note: Question Marks are just placeholders as its impossible
  // under normal circumstances for either of these fields to be that
  deleteMessage(room: string, chat: ChatMessageData): void {

    from(this.alert.create({
      header: 'Delete message?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'primaryToDark',
          handler: () => {
          },
        },
        {
          text: 'Ok',
          cssClass: 'primaryToDark',
          handler: data => {
            this.getMessageId(room, chat)
              .subscribe(oid => oid.forEach(i => this.updateMessage(room, i, chat.message, c => {
                return {
                  deleted: true,
                };
              })));
          },
        }],
    }))
      .pipe(switchMap(x => from(x.present())))
      .subscribe();

  }

  editMessage(room: string, chat: ChatMessageData): void {
    const input: AlertInput = {
      type: 'text',
      label: 'Message',
      value: chat.message.message.getOrElse(''),
    };

    from(this.alert.create({
      header: 'Edit Message',
      inputs: [input],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'primaryToDark',
          handler: () => {
          },
        },
        {
          text: 'Ok',
          cssClass: 'primaryToDark',
          handler: data => {
            this.getMessageId(room, chat)
              .subscribe(oi => oi.forEach(i => this.updateMessage(room, i, chat.message, c => {
                return {
                  message: data[0],
                };
              })));
          },
        }],
    }))
      .pipe(switchMap(x => from(x.present())))
      .subscribe();
  }

  editRoomName(room: RoomData): void {
    const input: AlertInput = {
      type: 'text',
      label: 'Room Name',
      value: room.name.getOrElse(''),
    };

    from(this.alert.create({
      header: 'Edit Room Name',
      inputs: [input],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'primaryToDark',
          handler: () => {
          },
        },
        {
          text: 'Ok',
          cssClass: 'primaryToDark',
          handler: data => {
            this.updateRoom(room, rm => {
              return {
                name: data[0],
              };
            });
          },
        }],
    }))
      .pipe(switchMap(x => from(x.present())))
      .subscribe();
  }

  ensureRoomExistsInFirebase(id: string): Observable<Option<RoomData>> {
    const room = this.db.collection(this.configuration.getFirestoreRoomsCollectionId()).doc(id);

    return room.get()
      .pipe(switchMap(doc => {
        if (doc.exists) {
          const data = RoomDataJsonSerializer.instance.fromJson(doc.data());
          return of(data);
        } else {
          return this.createRoomInFirebase(room, id);
        }
      }))
      .pipe(catchError(err => {
        console.error(err);
        return of(None);
      }))
      .pipe(triggerSingleEffect());
  }

  // Only Traveller identies and the potentially the agent

  async getAllIdentitiesForProposal(proposalId: string, model: Proposal): Promise<Set<IdentifiablePerson>> {
    const travellerSet: Set<IdentifiablePerson> =
        await PromiseUtils.allSet(model.people
            .map(p => this.user.createIdentityFromPerson(p, travellerDidgigoPrefix, false))
            .toSet());

    const agent = await PromiseUtils.allSet(
        OptionUtils.toSet(model.agent).map(p => this.user.createIdentityFromPerson(p, agentPrefix, false)));

    return this.observeRoom(proposalAgentRoomPrefix + proposalId)
      .pipe(take(1))
      .pipe(map(orm => {
        return orm.map(r => r.users.filter(u => u.isTraveller())).getOrElse(Set<IdentifiablePerson>());
      }))
      .pipe(map(ids =>
          OptionUtils.toSetFromCollection(
          ids.union(travellerSet).union(agent) // order important, use server identities first.
            .groupBy(x => x.identity)
            .map(x => Option.of(x.first())))))
        .toPromise();
  }

  private getFallback(id: string): Observable<Option<RoomAndMessageData>> {
    return this.observeRoomRaw(id)
      .pipe(switchMap(rm =>
        rm.map(r => this.populateMessagesAndUsers(r).pipe(map(x => Option.of(x))))
          .getOrElse(of(None))));
  }

  private getMessageId(room: string, chat: ChatMessageData): Observable<Option<string>> {
    return from(this.db.collection(this.configuration.getFirestoreRoomsCollectionId())
      .doc(room)
      .collection('messages')
      .ref
      .where('from', '==', chat.message.from.getOrElse('?'))
      .where('time', '==', chat.message.time.map(t => t.toISOString()).getOrElse('?'))
      .get())
      .pipe(map((res: any) => Option.of(res.docs[0]).map(d => d.id)));
  }

  getPrivateMessageId(people: Set<string>): Observable<Option<string>> {
    return this.rooms
      .pipe(take(1))
      .pipe(map(rm => Option.of(rm.valueSeq().find(r => r.isPMBetween(people)))))
      .pipe(map(rm => rm.flatMap(r => r.roomData.id)));
  }

  leaveRoom(room: RoomData, user: Option<IdentifiablePerson>): void {
    from(this.alert.create({
      header: 'Leave room?',
      message: room.getLeaveMessage(user),
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'primaryToDark',
          handler: () => {
          },
        },
        {
          text: 'Leave',
          cssClass: 'primaryToDark',
          handler: data => {
            this.updateRoom(room, rm => {
              const identity = user.flatMap(u => u.identity).getOrElse('?');
              const users = rm.joined.asImmutable().remove(identity);
              return {
                joined: convertMapToObj(users, m => m.toISOString()),
              };
            });
          },
        }],
    }))
      .pipe(switchMap(x => from(x.present())))
      .subscribe();

  }

  private observeJoinedRooms(id: string): Observable<QuerySnapshot> {
    const subj = new ReplaySubject<QuerySnapshot>(1);
    this.db.collection(this.configuration.getFirestoreRoomsCollectionId())
      .ref
      .where(`joined.${id}`, '>', '')
      .onSnapshot(a => subj.next(a));
    return subj.asObservable()
      .pipe(modelDebounce());
  }

  observeMessagesCountForRoom(room: string): Observable<number> {
    return combineLatest(this.user.currentIdentity, this.observeRoom(room))
      .pipe(map(([ou, orm]) =>
        Option.map2(ou.flatMap(u => u.identity), orm, (u, rm) => rm.getUnreadMessageCount(u)).getOrElse(0)))
      .pipe(modelDebounce());

  }

  observeMessagesForRoom(room: string): Observable<List<ChatMessage>> {
    return this.observeRoom(room)
      .pipe(map(orm => orm.map(r => r.messages).getOrElse(List())))
      .pipe(modelDebounce());

  }

  observeNewMessageCount(): Observable<number> {
    return combineLatest(this.rooms, this.user.currentIdentity)
      .pipe(map(([rms, ou]) =>
        rms.valueSeq()
          .reduce((a, b) => {
            const unreadCount: number =
              ou.flatMap(u => u.identity)
                .map(i => b.getUnreadMessageCount(i))
                .getOrElse(b.messages.size);
            return a + unreadCount;
          }, 0)))
      .pipe(modelDebounce());

  }

  private observeRawMessageStream(room: string): Observable<firebase.firestore.DocumentData[]> {
    return this.db.collection(this.configuration.getFirestoreRoomsCollectionId())
      .doc(room)
      .collection('messages')
      .valueChanges();
  }

  // Fallback to using live server value instead of cached

  observeRoom(id: string): Observable<Option<RoomAndMessageData>> {
    return this.rooms
      .pipe(map(r => Option.of(r.get(id))))
      .pipe(switchMap(orm =>
        orm.map(rm => of(Some(rm)))
          .getOrElse(
            this.getFallback(id))))
      .pipe(modelDebounce());
  }

  private observeRoomAndMessageData(): Observable<List<RoomAndMessageData>> {
    return this.user.currentIdentity
      .pipe(switchMap(ident => ident
        .flatMap(id => id.identity)
        .map(id => this.observeRoomAndMessageDataForIdentity(id))
        .getOrElse(of(List()))))
      .pipe(modelDebounce());
  }

  private observeRoomAndMessageDataByRoomList(roomList: RoomData[]): Array<Observable<RoomAndMessageData>> {
    return roomList.map(r => this.populateMessagesAndUsers(r));
  }

  private observeRoomAndMessageDataForIdentity(id: string): Observable<List<RoomAndMessageData>> {
    return this.observeJoinedRooms(id)
      .pipe(map(snap => snap.docs.map(docData => docData.data())))
      .pipe(map(dataList => OptionUtils.toList(...dataList.map(d => RoomDataJsonSerializer.instance.fromJson(d))).toArray()))
      .pipe(filter(rms => rms.length !== 0))
      .pipe(map(rms => combineLatest(this.observeRoomAndMessageDataByRoomList(rms))))
      .pipe(switchMap(rms => rms))
      .pipe(map(x => List(x)))
      .pipe(modelDebounce());
  }

  private observeRoomMap(): Observable<Map<string, RoomAndMessageData>> {
    return this.observeRoomAndMessageData()
      .pipe(map(data => Map(data.map<[string, RoomAndMessageData]>(d => [d.roomData.id.getOrElse('Unknown'), d]))))
      .pipe(modelDebounce());
  }

  private observeRoomRaw(id: string): Observable<Option<RoomData>> {
    return this.db
      .collection(this.configuration.getFirestoreRoomsCollectionId())
      .doc(id)
      .valueChanges()
      .pipe(map(x => RoomDataJsonSerializer.instance.fromJson(x)))
      .pipe(modelDebounce());
  }

  observeTravellerCountInRoom(id: string): Observable<number> {
    return this.observeRoom(id)
      .pipe(map(rm => rm.map(c => c.roomData.getTravellersJoinedCount()).getOrElse(0)))
      .pipe(modelDebounce());

  }

  private populateMessagesAndUsers(room: RoomData): Observable<RoomAndMessageData> {
    const id = room.id.get();
    return combineLatest(
      this.user.observeUsers(room.getUserIds()),
      this.observeRawMessageStream(id))
      .pipe(map(([users, messages]) =>
        new RoomAndMessageData(
          room,
          users,
          parseListSerializable(messages, ChatMessageSerializer.instance)
            .filter((m: ChatMessage) => !m.deleted.contains(true)))))
      .pipe(modelDebounce());
  }

  registerInChannel(room: string, id: string): void {
    this.ensureRoomExistsInFirebase(room)
      .pipe(switchMap(optRm => optRm.map(rm => this.updateRoom(rm, r => {
        if (r.joined.has(id)) {
          return {};
        }
        return {
          joined: convertMapToObj(r.joined.asImmutable().set(id, now()), m => m.toISOString()),
        };
      })).getOrElse(of())))
      .pipe(triggerSingleEffect());
  }

  sendMessage(room: string, chatMessage: ChatMessage): void {
    this.db.collection(this.configuration.getFirestoreRoomsCollectionId()).doc(room)
      .collection('messages')
      .add(ChatMessageSerializer.instance.toJson(chatMessage))
      .catch(err => console.error('Error sending message:', err));
  }

  // Yuck, the partial concept only works with primitives....
  updateMessage(room: string, id: string, msg: ChatMessage, data: (ip: ChatMessage) => Partial<any>): Observable<void> {
    return from(
      this.db.collection(this.configuration.getFirestoreRoomsCollectionId())
        .doc(room)
        .collection('messages')
        .doc(id)
        .update(data(msg)));
  }

  // Yuck, the partial concept only works with primitives....
  updateRoom(room: RoomData, data: (ip: RoomData) => Partial<any>): Observable<void> {
    return from(
      this.db.collection(this.configuration.getFirestoreRoomsCollectionId())
        .doc(room.id.get())
        .update(data(room)));
  }
}
