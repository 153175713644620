import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FloatingHeaderComponentModule } from '../../components/floating-header/floating-header.component.module';
import { HeaderSpacerComponentModule } from '../../components/header-spacer/header-spacer.component.module';
import { ImagePreloaderComponentModule } from '../../components/image-preloader/image-preloader.component.module';
import { LoadingComponentModule } from '../../components/loading/loading.component.module';
import { ProposalCardComponentModule } from '../../components/proposal-card/proposal-card.component.module';
import { ProposalsPage } from './proposals.page';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ProposalCardComponentModule,
    ImagePreloaderComponentModule,
    HeaderSpacerComponentModule,
    FloatingHeaderComponentModule,
    LoadingComponentModule,
    RouterModule.forChild([
      {
        path: '',
        component: ProposalsPage,
      },
    ]),
  ],
  declarations: [ProposalsPage],
})
export class ProposalsPageModule { }
