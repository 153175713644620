import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FloatingCardComponent } from './floating-card.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule.forRoot(),
  ],
  declarations: [FloatingCardComponent],
  exports: [FloatingCardComponent],
})
export class FloatingCardComponentModule { }
