import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SmartTextAreaComponentModule} from '@didgigo/lib-angular';
import {IonicModule} from '@ionic/angular';
import {ContactPanelComponentModule} from '../../components/contact-panel/contact-card.component.module';
import {EntryDetailsLargeComponentModule} from '../../components/entry-details-large/entry-details-large.component.module';
import {FloatingFooterComponentModule} from '../../components/floating-footer/floating-footer.component.module';
import {FloatingHeaderComponentModule} from '../../components/floating-header/floating-header.component.module';
import {FooterSpacerComponentModule} from '../../components/footer-spacer/footer-spacer.component.module';
import {HeaderSpacerComponentModule} from '../../components/header-spacer/header-spacer.component.module';
import {LoadingComponentModule} from '../../components/loading/loading.component.module';
import {MapComponentModule} from '../../components/map/map.component.module';
import {SliderCardComponentModule} from '../../components/slider-card/slider-card.component.module';
import {SlideshowBackgroundComponentModule} from '../../components/slideshow-background/slideshow-background.component.module';
import {EntryPage} from './entry.page';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        SlideshowBackgroundComponentModule,
        FloatingFooterComponentModule,
        FooterSpacerComponentModule,
        FloatingHeaderComponentModule,
        MapComponentModule,
        ContactPanelComponentModule,
        EntryDetailsLargeComponentModule,
        HeaderSpacerComponentModule,
        SliderCardComponentModule,
        LoadingComponentModule,
        RouterModule.forChild([
            {
                path: '',
                component: EntryPage,
            },
        ]),
        SmartTextAreaComponentModule,
    ],
  declarations: [EntryPage],
})
export class EntryPageModule { }
