import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FloatingCardComponentModule } from '../floating-card/floating-card.component.module';
import { ImagePreloaderComponentModule } from '../image-preloader/image-preloader.component.module';
import { ProposalCardDetailsComponentModule } from '../proposal-card-details/proposal-card-details.component.module';
import { ProposalCardComponent } from './proposal-card.component';

@NgModule({
  imports: [
    CommonModule,
    FloatingCardComponentModule,
    ImagePreloaderComponentModule,
    ProposalCardDetailsComponentModule,
    IonicModule.forRoot(),
  ],
  declarations: [ProposalCardComponent],
  exports: [ProposalCardComponent],
})
export class ProposalCardComponentModule { }
