import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {parseString, Person, travellerCustomPrefix, travellerDidgigoPrefix} from '@didgigo/lib-ts';
import {ModalController} from '@ionic/angular';
import {Components} from '@ionic/core';
import {None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import IonInput = Components.IonInput;
import IonSelect = Components.IonSelect;

export class WhoAreYouModalData {
  constructor(
      readonly person: Person,
      readonly prefix: string) {
  }
}

@Component({
  selector: 'app-who-are-you-modal',
  templateUrl: './who-are-you-modal.component.html',
  styleUrls: ['./who-are-you-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhoAreYouModalComponent implements OnInit {

  constructor(readonly modal: ModalController) {
  }

  checkIfFirstPassenger = true;

  @Input()
  passengers: List<Person>;

  getContinuingName(select: IonSelect, input: IonInput): Option<string> {
    const inputValue = parseString(input.value).getOrElse('').trim();
    const inputEmpty = inputValue.length === 0;
    const selectValue = Option.of((select.value as Person));
    if (!inputEmpty) {
      return Some(inputValue);
    } else {
      return selectValue.flatMap(p => p.getFullName())
          .orElse(Option.of(this.passengers.first()).flatMap(p => p.getFullName()));
    }
  }

  getFirstPaxName(): string {
    return Option.of(this.passengers.first())
        .flatMap(p => p.getFullName())
        .getOrElse('Anonymous');
  }

  ngOnInit(): void {
    this.checkIfFirstPassenger = !this.passengers.isEmpty();
  }

  async proceed(select: IonSelect, input: IonInput): Promise<boolean> {
    if (parseString(input.value).getOrElse('').trim().length !== 0) {
      const name = this.getContinuingName(select, input).getOrElse('Anonymous');
      return this.proceedAsCustom(name);
    } else if (Option.of(select.value).nonEmpty()) {
      return this.proceedAsDidgigoTraveller(select.value);
    } else {
      return this.proceedAsFirstPax();
    }
  }

  private proceedAsCustom(name): Promise<boolean> {
    return this.modal.dismiss(new WhoAreYouModalData(new Person(None, Some(name)), travellerCustomPrefix));
  }

  private proceedAsDidgigoTraveller(u): Promise<boolean> {
    return this.modal.dismiss(new WhoAreYouModalData(u, travellerDidgigoPrefix));
  }

  proceedAsFirstPax(): Promise<boolean> {
    return this.proceedAsDidgigoTraveller(this.passengers.first());
  }

  shouldShowSelect(): boolean {
    return this.passengers.size > 1;
  }
}
