import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {Plugins} from '@capacitor/core';
import {EntryTypeFilter, parseNumber, ProposalEntryWithMeta, ProposalWithMeta} from '@didgigo/lib-ts';
import {List} from 'immutable';
import {BehaviorSubject} from 'rxjs';
import {BaseComponent} from '../../lib-ionic/base-component';
import {AssetService} from '../../services/asset.service';
import {LoadingMonitorService} from '../../services/loading-monitor.service';
import {LoggingService} from '../../services/logging.service';
import {MediaService} from '../../services/media.service';
import {NavigatorService} from '../../services/navigator.service';
import {ProposalService} from '../../services/proposal.service';
import {UserService} from '../../services/user.service';

const { Keyboard } = Plugins;

export class SnapshotSubproductPageModel {
    constructor(
        readonly proposal: ProposalWithMeta,
        readonly entry: ProposalEntryWithMeta, // Entries here for caching purposes
        readonly subproducts: List<ProposalEntryWithMeta>, // Entries here for caching purposes
        readonly searchText: string,
        readonly searchType: EntryTypeFilter) {
    }

    isHidden(e: ProposalEntryWithMeta): boolean {
        return !(e.getEntry().matchesTypeFilter(this.searchType) && e.getEntry().matchesFuzzyTextFilter(this.searchText));
    }
}

@Component({
    selector: 'app-snapshot-subproducts',
    templateUrl: './snapshot-subproducts-page.page.html',
    styleUrls: ['./snapshot-subproducts-page.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnapshotSubproductPage extends BaseComponent implements OnInit, AfterViewInit {

    constructor(
        private route: ActivatedRoute,
        readonly mediaService: MediaService,
        readonly navigator: NavigatorService,
        readonly assets: AssetService,
        readonly user: UserService,
        readonly logger: LoggingService,
        readonly loading: LoadingMonitorService,
        readonly change: ChangeDetectorRef,
        readonly self: ElementRef,
        readonly proposalService: ProposalService,
    ) {
        super('subproducts_page', change, self, loading);
    }

    model: BehaviorSubject<SnapshotSubproductPageModel> = new BehaviorSubject<SnapshotSubproductPageModel>(
        new SnapshotSubproductPageModel(new ProposalWithMeta(), new ProposalEntryWithMeta(), List(), '', 'All'));

    hideKeyboard(): void {
        Keyboard.hide();
    }

    async ngOnInit(): Promise<void> {
        const ps = this.route.snapshot.params;
        this.logger.setPage('subproducts');
        this.navigator.setProposalId(ps.id);
        this.navigator.setEntryId(ps.eid);
        this.logger.logEventWithProposalAndUser('load_page', {page: 'subproducts'}, parseNumber(ps.id));
        const proposal = await this.navigator.getCurrentEntryForIdNonOptional(+ps.id, +ps.eid);
        this.model.next(new SnapshotSubproductPageModel(proposal.getProposalWithMeta(), proposal, proposal.getSortedSubproductsAsEntries(), '', 'All'));
        super.ngOnInit();
    }

    searchFor(e: any): void {
        const value = this.model.getValue();
        if (value.searchText !== e.target.value) {
            this.model.next(new SnapshotSubproductPageModel(
                value.proposal, value.entry, value.subproducts, e.target.value, value.searchType));
        }
    }

    showType(value: EntryTypeFilter): void {
        const model = this.model.getValue();
        if (model.searchType !== value) {
            this.model.next(new SnapshotSubproductPageModel(model.proposal, model.entry, model.subproducts, model.searchText, value));
        }
    }
}
