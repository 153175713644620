import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ContactPanelComponentModule } from '../../components/contact-panel/contact-card.component.module';
import { FloatingCardComponentModule } from '../../components/floating-card/floating-card.component.module';
import { FloatingHeaderComponentModule } from '../../components/floating-header/floating-header.component.module';
import { HeaderSpacerComponentModule } from '../../components/header-spacer/header-spacer.component.module';
import { ImageBackgroundComponentModule } from '../../components/image-background/image-background.component.module';
import { ContactPage } from './contact.page';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ContactPanelComponentModule,
    ImageBackgroundComponentModule,
    FloatingHeaderComponentModule,
    HeaderSpacerComponentModule,
    FloatingCardComponentModule,
    RouterModule.forChild([
      { path: '', component: ContactPage },
    ]),
  ],
  declarations: [ContactPage],
})
export class ContactPageModule { }
