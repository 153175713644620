import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {ContextualImageSet, modelDebounce, ProposalWithMeta, RoomAndMessageData, twoSecondUpdateTimer} from '@didgigo/lib-ts';
import {IonItemSliding, Platform} from '@ionic/angular';
import {None, Option} from 'funfix-core';
import {combineLatest, Observable, of} from 'rxjs';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {RoomMessagePageData} from '../../components/channel-card/channel-card.component';
import {BaseComponent} from '../../lib-ionic/base-component';
import {AssetService} from '../../services/asset.service';
import {ChatService} from '../../services/chat.service';
import {ConfigurationService} from '../../services/configuration.service';
import {LoadingMonitorService} from '../../services/loading-monitor.service';
import {LoggingService} from '../../services/logging.service';
import {MediaService} from '../../services/media.service';
import {NavigatorService} from '../../services/navigator.service';
import {ProposalService} from '../../services/proposal.service';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.page.html',
  styleUrls: ['./messages.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagesPage extends BaseComponent implements OnInit {

  constructor(
    readonly logging: LoggingService,
    readonly proposals: ProposalService,
    readonly navigator: NavigatorService,
    readonly platform: Platform,
    readonly loading: LoadingMonitorService,
    readonly chat: ChatService,
    readonly user: UserService,
    readonly assets: AssetService,
    readonly media: MediaService,
    readonly change: ChangeDetectorRef,
    readonly configuration: ConfigurationService,
    readonly self: ElementRef) {
    super('messages_page', change, self, loading);
  }

  rooms: Observable<RoomMessagePageData[]> =
    this.observeRooms()
      .pipe(modelDebounce(this.unsubscriberObs, () => twoSecondUpdateTimer));

  private convertToUiModel(rm: RoomAndMessageData): Observable<RoomMessagePageData> {
    return combineLatest(this.user.currentIdentity, this.getProposalData(rm.getProposalId()))
      .pipe(map(([ou, op]) =>
        new RoomMessagePageData(
          rm.roomData.id.getOrElse('Unknown'),
          rm.roomData,
          rm.roomData.name
              .orElse(op.flatMap(x => x.proposal).map(p => p.getProposalDisplayTitle(ou.exists(u => u.isStaff()))))
            .orElse(Option.of('Private Group').filter(_ => rm.isPrivateGroupChannel()))
            .getOrElse(rm.getType(ou)),
            op.flatMap(x => x.proposal)
                .flatMap(p => p.title)
            .filter(_ => ou.exists(u => u.isStaff())),
          rm.roomData.getTravellersJoinedCount(),
          ou.flatMap(u => u.identity)
            .map(i => rm.getUnreadMessageCount(i))
            .getOrElse(0),
            op.flatMap(x => x.proposal)
                .flatMap(p => p.welcome)
            .flatMap(w => w.image)
            .map(i => this.media.getWelcomeImage(i))
            .filter(_ => ou.exists(u => u.isTraveller())),
          rm.getLastMessageTime(),
          ou.exists(u => u.isStaff()),
          rm.getType(ou))));
  }

  editChannel(sliding: IonItemSliding, item: RoomMessagePageData): void {
    sliding.close()
      .then(_ => this.chat.editRoomName(item.underlying));
  }

  getImages(): ContextualImageSet {
    return this.assets.getBlueBackground();
  }

  private getProposalData(proposalId: Option<string>): Observable<Option<ProposalWithMeta>> {
    if (proposalId.isEmpty()) {
      return of(None);
    }
    // TODO: Reimplent getMinimalInfo
    return this.proposals.observe(proposalId.get());
  }

  getTestingMessage(): string {
    return 'Note: Currently in the testing channels for chat, ' +
      'sending messages here will not affect the production system';
  }

  leaveChannel(sliding: IonItemSliding, item: RoomMessagePageData): void {
    sliding.close()
      .then(_ =>
        this.user.currentIdentity
          .pipe(take(1))
          .subscribe(ou => this.chat.leaveRoom(item.underlying, ou)));
  }

  ngOnInit(): void {
    this.logging.setPage('messages_page');
    this.logging.logEventWithProposalAndUser(
      'load_page',
        {page: 'messages'});
    super.ngOnInit();
  }

  observeRooms(): Observable<RoomMessagePageData[]> {
    return combineLatest(this.user.currentIdentity, this.chat.rooms)
      .pipe(modelDebounce())
      .pipe(map(([ou, rms]) => rms.valueSeq().filter(rm => rm.shouldShowChannel(ou))))
      .pipe(filter(rms => !rms.isEmpty()))
      .pipe(switchMap(rms => combineLatest(...rms.map(rm => this.convertToUiModel(rm)).toArray())))
      .pipe(modelDebounce());
  }

  // Return only the data we should rerender if changed
  // This prevents flickering when receiving new messages etc.
  // Note: we could turn message counts and last active into
  // observables to remove the flicker further
  tracker(idx: number, item: RoomMessagePageData): any {
    return item.getTracked();
  }
}
