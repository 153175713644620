import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ProposalEntryWithMeta} from '@didgigo/lib-ts';
import {LaunchNavigator} from '@ionic-native/launch-navigator/ngx';
import {None, Option} from 'funfix-core';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AssetService} from '../../services/asset.service';
import {LocationService} from '../../services/location.service';
import {UserSettingsService} from '../../services/user-settings.service';

@Component({
    selector: 'app-entry-card-small',
    templateUrl: './entry-card-small.component.html',
    styleUrls: ['./entry-card-small.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntryCardSmallComponent implements OnInit {

    constructor(
        readonly assets: AssetService,
        readonly settings: UserSettingsService,
        readonly loc: LocationService,
        readonly launchNavigator: LaunchNavigator) {
        this.settings.isMetric().then(b => this.isMetric = b);
    }

    @Input()
    displayDistances = false;

    distanceFromUser: Observable<Option<string>> =
        from(this.loc.getLatLongLocation())
            .pipe(map(x =>
                x
                    .flatMap(v => this.model.getStraightLineDistance(v))
                    .map(v => v.getDistanceString(this.isMetric))
                    .map(v => '~' + v)));

    @Input()
    icon = true;

    isMetric = true;

    @Input()
    mapCard = false;

    @Input()
    model: ProposalEntryWithMeta;

    @Input()
    parentEntry: Option<ProposalEntryWithMeta> = None;

    navigateNow(event: Event): void {
        event.stopPropagation();
        this.model.getLatLongLocation()
            .flatMap(l => l.getPointTuple())
            .forEach(v => this.launchNavigator.navigate(v));
    }

    ngOnInit(): void {
    }
}
