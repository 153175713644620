import {AgmMap} from '@agm/core';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {
  DirectionLeg,
  Directions,
  EntryTypeFilter,
  LatLongLocation,
  MapMarkerWithMeta,
  OptionUtils,
  ProposalEntryWithMeta,
  ProposalWithMeta,
} from '@didgigo/lib-ts';
import {LaunchNavigator} from '@ionic-native/launch-navigator/ngx';
import {ModalController, Platform} from '@ionic/angular';
import {None, Option} from 'funfix-core';
import {List, Set} from 'immutable';
import {Moment} from 'moment';
import {NgxSpinnerService} from 'ngx-spinner';
import {BehaviorSubject} from 'rxjs';
import {BaseComponent} from '../../lib-ionic/base-component';
import {AssetService} from '../../services/asset.service';
import {LoadingMonitorService} from '../../services/loading-monitor.service';
import {LocationService} from '../../services/location.service';
import {MediaService} from '../../services/media.service';
import {UserSettingsService} from '../../services/user-settings.service';
import {MapMarkerModalComponent} from '../map-marker-modal/map-marker-modal.component';

export type MapMode = 'Day' | 'Proposal' | 'Product';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent extends BaseComponent implements OnInit {

    mode = "Product";

  constructor(
      readonly platform: Platform,
      readonly media: MediaService,
      readonly assets: AssetService,
      readonly loadingMonitor: LoadingMonitorService,
      readonly settings: UserSettingsService,
      readonly change: ChangeDetectorRef,
      readonly self: ElementRef,
      readonly modals: ModalController,
      readonly loc: LocationService,
      private spinner: NgxSpinnerService,
      readonly launchNavigator: LaunchNavigator) {
    super('map', change, self, loadingMonitor, false);
  }

  @ViewChild('AgmMap', {static: false})
  agmMap: AgmMap;

  clustered: List<MapMarkerWithMeta>;
  clusteredNonSubproduct: List<MapMarkerWithMeta>;

  @Input()
  day: Option<Moment> = None;
  directions: List<MapMarkerWithMeta>;

  directionsOptions: any = {
    suppressMarkers: true,
    preserveViewport: true,
    polylineOptions: {
      strokeColor: '#00a1e4',
      strokeWidth: 2,
    },
  };

  isMetric: boolean;
  lat: Option<number>;
  legs: List<MapMarkerWithMeta>;
  long: Option<number>;
  mapMarkers: List<MapMarkerWithMeta>;

  markerSize = 42;

  @Input()
  mapMode: MapMode;

  myLocation: Option<LatLongLocation> = None;

  @Input()
  pmeta: ProposalWithMeta;

  @Input()
  proposalEntryParentWithMeta: Option<ProposalEntryWithMeta> = None;

  @Input()
  proposalEntryWithMeta: Option<ProposalEntryWithMeta> = None;

  proposalMapWhiteList: BehaviorSubject<Set<EntryTypeFilter>> = new BehaviorSubject(Set.of('Accommodation'));
  sdEnd: List<MapMarkerWithMeta>;
  sdStart: List<MapMarkerWithMeta>;

  selfDriveEndMarkerSize = 31;
  selfDriveStartMarkerSize = 39;
  show = new BehaviorSubject(false);

  sortedMapMarkersOnDay: List<MapMarkerWithMeta>;

  subproductMarkerSize = 31;
  userMarkerSize = 31;
  zoom: number = 13;

  directToPoint(tuple: number[]): void {
    this.launchNavigator.navigate(tuple);
  }

  directViaMaps(): void {
    this.proposalEntryWithMeta
        .flatMap(x => x.getLatLongLocation())
        .flatMap(l => l.getPointTuple())
        .forEach(v => this.launchNavigator.navigate(v));
  }

  getAllMarkers(): List<MapMarkerWithMeta> {
    return this.getClusteredNonSubproductMapMarkers()
        .concat(this.getClusteredMapMarkers())
        .concat(this.getSelfDriveStartMapMarkers())
        .concat(this.getSelfDriveEndMapMarkers())
        .concat(this.getMapMarkers());
  }

  getClusteredMapMarkers(): List<MapMarkerWithMeta> {
    switch (this.mapMode) {
      case 'Day':
        return this.day.map(d => this.pmeta.getUsefulSubproductMapMarkersWithMetaOnDay(d)).getOrElse(List());
      case 'Proposal':
        return this.pmeta.getMapMarkersWithMeta().filter(x => !x.isEmpty());
      case 'Product':
        return this.proposalEntryWithMeta.map(x => x.getSubproductMapMarkersWithMeta()).getOrElse(List());
    }
  }

  getClusteredNonSubproductMapMarkers(): List<MapMarkerWithMeta> {
    switch (this.mapMode) {
      case 'Day':
        return this.day.map(d => this.pmeta.getUsefulMapMarkersOnDay(d)).getOrElse(List());
      case 'Proposal':
      case 'Product':
        return List();
    }
  }

  // Uses the whole routes, rather then leg by leg, may want to change that
  getDirections(): List<Directions> {
    switch (this.mapMode) {
      case 'Day':
        return this.pmeta.getSortedEntries()
            .filter(x => this.day.nonEmpty() && x.getEntry().isDuring(this.day.get()))
            .flatMap(x => OptionUtils.toList(x.getDisplayProposalEntryDirections()))
            .filter(x => !x.isEmpty());
      case 'Proposal':
        return this.pmeta.getSortedEntries()
            .flatMap(x => OptionUtils.toList(x.getDisplayProposalEntryDirections()))
            .filter(x => !x.isEmpty());
      case 'Product':
        if (this.proposalEntryWithMeta.exists(x => x.isSelfDrive())) {
          return OptionUtils.toList(this.proposalEntryWithMeta.flatMap(x => x.getProduct()).flatMap(x => x.getDirections()));
        }
        return List();
    }
  }

  getDistanceString(m: MapMarkerWithMeta): Option<string> {
    return this.myLocation
        .flatMap(l => m.getStraightLineDistance(l))
        .map(l => '~' + l.getDistanceString(this.isMetric));
  }

  getLegs(): List<DirectionLeg> {
    return this.getDirections().flatMap(x => x.getSortedLegs());
  }

  getMapLatitude(): Option<number> {
    switch (this.mapMode) {
      case 'Day':
        return None;
      case 'Proposal':
        return this.pmeta.getProposal().getMap().flatMap(x => x.latitude);
      case 'Product':
        if (this.proposalEntryWithMeta.exists(x => x.isSelfDrive())) {
          return this.proposalEntryWithMeta.flatMap(x => x.getProduct()).flatMap(x => x.getDirections()).flatMap(x => x.latitude);
        }
        return this.proposalEntryWithMeta.flatMap(x => x.getProduct()).flatMap(x => x.getMap()).flatMap(x => x.latitude);
    }
  }

  getMapLongitude(): Option<number> {
    switch (this.mapMode) {
      case 'Day':
        return None;
      case 'Proposal':
        return this.pmeta.getProposal().getMap().flatMap(x => x.longitude);
      case 'Product':
        if (this.proposalEntryWithMeta.exists(x => x.isSelfDrive())) {
          return this.proposalEntryWithMeta.flatMap(x => x.getProduct()).flatMap(x => x.getDirections()).flatMap(x => x.longitude);
        }
        return this.proposalEntryWithMeta.flatMap(x => x.getProduct()).flatMap(x => x.getMap()).flatMap(x => x.longitude);
    }
  }

  getMapMarkers(): List<MapMarkerWithMeta> {
    switch (this.mapMode) {
      case 'Day':
        return List();
      case 'Proposal':
        return List();
      case 'Product':
        return this.proposalEntryWithMeta.map(x => x.getMapMarkersWithMeta(this.proposalEntryParentWithMeta)).getOrElse(List());
    }
  }

  getMapZoom(): Option<number> {
    switch (this.mapMode) {
      case 'Day':
      case 'Proposal':
        return None;
      case 'Product':
        if (this.proposalEntryWithMeta.exists(x => x.isSelfDrive())) {
          return this.proposalEntryWithMeta.flatMap(x => x.getProduct()).flatMap(x => x.getDirections()).flatMap(x => x.zoom);
        }
        return this.proposalEntryWithMeta.flatMap(x => x.getProduct()).flatMap(x => x.getMap()).flatMap(x => x.zoom);
    }
  }

  getNonEmptyProposalEntryWithMeta(): ProposalEntryWithMeta {
    return this.proposalEntryWithMeta.getOrElse(new ProposalEntryWithMeta());
  }

  getSelfDriveEndMapMarkers(): List<MapMarkerWithMeta> {
    switch (this.mapMode) {
      case 'Day':
        return this.pmeta.getSortedEntries()
            .filter(x => this.day.nonEmpty() && x.getEntry().isDuring(this.day.get()))
            .flatMap(x => OptionUtils.toList(x.getSelfDriveEndMapMarkerWithMeta())).filter(x => !x.isEmpty());
      case 'Proposal':
        return List();
      case 'Product':
        return OptionUtils.toList(this.proposalEntryWithMeta.flatMap(x => x.getSelfDriveEndMapMarkerWithMeta()));
    }
  }

  getSelfDriveStartMapMarkers(): List<MapMarkerWithMeta> {
    switch (this.mapMode) {
      case 'Day':
        return this.pmeta.getSortedEntries()
            .filter(x => this.day.nonEmpty() && x.getEntry().isDuring(this.day.get()))
            .flatMap(x => OptionUtils.toList(x.getSelfDriveStartMapMarkerWithMeta())).filter(x => !x.isEmpty());
      case 'Proposal':
        return List();
      case 'Product':
        return OptionUtils.toList(this.proposalEntryWithMeta.flatMap(x => x.getSelfDriveStartMapMarkerWithMeta()));

    }
  }

  hasAValidLocation(): boolean {
    return this.proposalEntryWithMeta.exists(x => x.getLatLongLocation().exists(l => l.getPoints().nonEmpty()));
  }

  hasValidDirections(): boolean {
    return this.proposalEntryWithMeta.exists(x => x.getDirectionMarkers().size !== 0);
  }

  isHidden(e: MapMarkerWithMeta, wl: Set<EntryTypeFilter>): boolean {
    return !(e.entry.getEntry().matchesTypeFilterSet(wl));
  }

  navigateNow(marker: MapMarkerWithMeta): void {
    marker.entry.getLatLongLocation()
        .flatMap(l => l.getPointTuple())
        .forEach(v => this.launchNavigator.navigate(v));
  }

  async ngOnInit(): Promise<void> {
    this.spinner.show();
    if (this.mapMode === 'Proposal') {
      this.setWhiteList(Set.of('Accommodation'));
    } else {
      this.setWhiteList(Set.of('Tours', 'Accommodation', 'Destination', 'SelfDrive'));
    }
    this.sdEnd = this.getSelfDriveEndMapMarkers();
    this.sdStart = this.getSelfDriveStartMapMarkers();
    this.clustered = this.getClusteredMapMarkers();
    this.clusteredNonSubproduct = this.getClusteredNonSubproductMapMarkers();
    this.mapMarkers = this.getMapMarkers();
    this.lat = this.getMapLatitude();
    this.long = this.getMapLongitude();
    this.zoom = this.getMapZoom().orElse(this.proposalEntryWithMeta.flatMap(x => x.getDisplayProposalEntryMapZoom())).getOrElse(13);
    this.isMetric = await this.settings.isMetric();

    if (this.mapMode === 'Day' && this.day.nonEmpty()) {
      this.sortedMapMarkersOnDay = this.pmeta.getAllUsefulSubproductAndNormalMapMarkersOnDay(this.day.get())
          .sort((a: MapMarkerWithMeta, b: MapMarkerWithMeta) => {
            return this.myLocation.isEmpty() ? 0 : MapMarkerWithMeta.compareTo(this.myLocation.get(), a, b);
          });
    }

    this.show.next(true);
    try {
        this.myLocation = await this.loc.getLatLongLocation();
    } catch (err) {
        console.error(err);
        this.myLocation = None;
    }

      super.ngOnInit();
  }

  async openModal(marker: MapMarkerWithMeta): Promise<void> {
    const modal = await this.modals.create({
      component: MapMarkerModalComponent,
      componentProps: {mapType: this.mapMode, model: marker.entry, parentEntry: marker.parent},
      animated: true,
      showBackdrop: true,
      backdropDismiss: true,
    });
    await modal.present();
  }

  async resize(map: any): Promise<void> {

    const markers = this.getAllMarkers();
    const current = await map.getBounds();
    if (current !== undefined) {
      const boundsFine = markers.every(mm => current.contains(new google.maps.LatLng(mm.getLatitude().get(), mm.getLongitude().get())));

      if (boundsFine) {
        return;
      }
    }

    const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
    markers.forEach(mm =>
        bounds.extend(new google.maps.LatLng(mm.getLatitude().get(), mm.getLongitude().get())));
    map.fitBounds(bounds);
    this.change.detectChanges();
  }

  setWhiteList(s: Set<EntryTypeFilter>): void {
    this.proposalMapWhiteList.next(s);
  }
}
