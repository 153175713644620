import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ContextualImageSet, modelThrottle, StringUtils} from '@didgigo/lib-ts';
import {List} from 'immutable';
import {BaseComponent} from '../../lib-ionic/base-component';
import {AssetService} from '../../services/asset.service';
import {LoadingMonitorService} from '../../services/loading-monitor.service';
import {LoggingService} from '../../services/logging.service';
import {MediaService} from '../../services/media.service';
import {NavigatorService} from '../../services/navigator.service';
import {ProposalService} from '../../services/proposal.service';
import {UserService} from '../../services/user.service';

class Release {
  constructor(
    readonly version: string,
    readonly title: string,
    readonly date: string,
    readonly mode: 'internal' | 'beta' | 'release',
    readonly features: string,
    readonly improvements: string,
    readonly bugfixes: string) {
  }
}

const version120: Release =
    new Release(
        '1.2.0',
        'Languages development build',
        'August 2019',
        'internal',
        '',
        StringUtils.stripMargin(
            `- Ported to the new API
                |- Security updates`),
        '');

const version118: Release =
    new Release(
        '1.1.8',
        'Eighth Chat beta (Tweaks)',
        'May 2019',
        'beta',
        '',
        StringUtils.stripMargin(
            `- Upgraded to Angular 8
                |- Added new Demo Proposals
                |- Demo Proposals no longer attempt to log in users
                |- Descriptions: Always uses whats in the Entry first, then falls back to our product descriptions`),
        StringUtils.stripMargin(`- Menu: Fixed an issue with bottom row of buttons appearing too low
                        |- Contact: Fixed an issue where parent company was showing twice`));

const version117: Release =
    new Release(
        '1.1.7',
        'Seventh Chat beta (Tweaks)',
        'May 2019',
        'beta',
        '- Added support for parent child logos and names',
        StringUtils.stripMargin(
            `- Background Slideshows: Now use fade instead of slide transition
                |- Day by Day/Entry:
                |    - Learned to show 'CHECK_IN' custom note
                |    - Learned to show 'CHECK_OUT' custom note
                |    - Learned to show 'CHECKINOUT_INFO' custom note`),
        StringUtils.stripMargin(`- Landing Page: Fix an issue with background image layering
                      |- Landing Page: Fix an issue with logo position landscape on iPhone X/XS
                      |- Proposals Page: Fix an issue with background image layering
                      |- Proposals Page: Fix an issue with logo position landscape on iPhone X/XS
                      |- Day by Day: Fixed a bug where it would show 'Sydney' to 'Sydney'
                      |- Info Page: Learnt to disappear when no data would be shown
                      |- Download Page: Fixed an issue where redownload was not working on the same itinerary you had just downloaded`),
    );

const version116: Release =
    new Release(
        '1.1.6',
        'Sixth Chat beta (Tweaks)',
        'May 2019',
        'beta',
        '',
        StringUtils.stripMargin(
            `- Day by Day/Entry:
                |  - Learned to show a start and end remark
                |  - Learned to show 'VOUCHER_NUMBER' custom note
                |  - Learned to show 'REMARKS' custom note
                |  - Learned to show 'EXTRAS' custom note`),
        '',
    );

const version115: Release =
    new Release(
        '1.1.5',
        'Fifth Chat beta (Tweaks)',
        'May 2019',
        'beta',
        '',
        StringUtils.stripMargin(
            `- Simple software updates`),
        '',
    );

// @ts-ignore
const version114: Release =
  new Release(
    '1.1.4',
    'Fourth Chat beta (Tweaks)',
    'Mar 2019',
    'beta',
    '',
      StringUtils.stripMargin(
      `- Day Page: Title text updated
            |- Day Page: Hotel comments only display on the first morning at that hotel
            |- Day Page: Tweaked first day of the itinerary comment to make it more formal. This still needs work.
            |- Entry and Day Page: Leisure days using Date word has been tweaked
            |- Entry and Day Page: Flights no longer use the term Checkin for pickup times as it could be misleading.`),
    '',
  );

// @ts-ignore
const version113: Release =
  new Release(
    '1.1.3',
    'Third Chat beta (iOS Fixes)',
    'Jan 2019',
    'beta',
    '',
    '',
      StringUtils.stripMargin(
      `- iOS: Some Floating cards were not rounded
            |- iOS: Chat cards were not showing corners correctly
            |- iOS: chat text would appear invisible until scrolled
            |- Notch phones: Improve padding on some pages
            |- iOS: Fix paste working weirdly in itineraries page
            |- Fixed issues where chat wasn't syncing`),
  );

// @ts-ignore
const version112: Release =
  new Release(
    '1.1.2',
    'Second Chat beta',
    'Jan 2019',
    'beta',
    '',
      StringUtils.stripMargin(
      `- Performance application wide improved slightly (mostly caching)
         | - Messages Page: Private messages no longer mention the current user in their name.
         | - Messages Page: Private messages between 2 people appear differently to those between 3 or more.
         | - Download Page: Now warns when there is not enough storage space left on the device
         | - Menu: No longer shows day by day if today/tomorrow is exists
         | - Overall: Top and Bottom padding are now device category specific
         | - Phones with the 'Notch': we now respect the device top/bottom "Safe areas"`),
      StringUtils.stripMargin(
      `- Day Page: Checkout time no longer appears if midnight
         | - Day Page: Hire car dropoff time was not appearing when there was no morning accommodation
         | - Day Page: Hire car dropoff no longer appears if midnight
         | - Proposal Data: Fixed an issue where start and end date was calculated incorrectly
         |   - Note: We no always use first/last entry date time, falling back to start/end time as it start/end time is not always accurate
         | - Proposal Data: Fixed an issue where number of days was using number of nights instead
         | - Download Page: Fixed an issue where navigating away during download would still navigate to welcome once finished
         | - Entry/DayByDay/Snapshot Page: Fixed an issue where long option names were not displaying correctly
         | - Smart Text areas: Fixed an issue where first and last paragraph would have too much padding
         | - Messages Page: Stop flickering
         | - Download Page: Fixed an issue where logging in during download would prevent the rooms from being joined.
         | - Contact/Agent Page: Fixed an issue you could not choose a custom
         | - Messages Page: Fixed an issue where private message with agent could show a blank name
         | - Messaging backend: Fixed an issue where an agent could be messaged before there account was created
         | - Messaging backend: Fixed an issue where messages would not send to a newly created room.
         | - Chat/Itineraries Page: Regression where input button styles broke
         | - Who are you dialog: Regression where dialog took up whole screen
         | - Who are you dialog: Tweaked formatting, spacing and colouring
         | - iOS: SVG Background's no longer have a "Border" on the top and bottom`),
  );

const version111: Release =
  new Release(
    '1.1.1',
    'First Chat beta',
    'Dec 2018',
    'beta',
      StringUtils.stripMargin(
      `- Private message channels can be created
            |- You can now edit messages (up to 5 mins after sending)
            |- You can now edit channel names
            |- Agents edit agent announcements
            |- Travellers edit traveller chat
            |- Creator can edit private message groups
            |- Agent photos`),
      StringUtils.stripMargin(`- Agents show photos in chat`),
      StringUtils.stripMargin(
      `- Deleting messages now works (Can do this anytime)
            |- First Message always has name and time`));

// @ts-ignore
const version110: Release =
  new Release(
    '1.1.0',
    'First Chat Internal test',
    'Nov 2018',
    'internal',
      StringUtils.stripMargin(
      `- Agent page
            |- Private messaging
            |- Group messaging
            |- Agent broadcasts to all travellers on an itinerary
            |- Staff can see how many travellers have the app`),
      StringUtils.stripMargin(
      `- Proposals Page: Downloaded proposals come before demo card
            |- Proposals Page: Tweaked Input box styling
            |- Numbered lists: Now have a custom styling
            |- Day by day and Entry Page: Now show day of week.
            |- Day by day and Entry Page: Pickup does not have as much space between time and remark.
            |- Menu: Adjusted padding so it sits in between header and footer
            |- Itineraries Page: Cards are now the right size initially, preventing the "shrinking" effect
            |- Localization: Auto-detect en-US devices and default to imperial units in self drive.
            |- Internal: Observing serialized data is now cached in memory, speeding up the app overall
            |- AutoNavigate: Now auto navigates in the following circumstances
            |  1. App has been just been loaded
            |  2. App has been resumed *and* user was not active today
            |  This prevents switching between apps from redirecting to welcome/today/tomorrow
            |  Note: AutoNavigate might not work perfectly while offline as it uses cached data`),
      StringUtils.stripMargin(
      `- Issue where while an image was loading, the entire box went blue
            |- Issue where refresh would not update snapshot ordering
            |- Fixed a backend issue with the potential for data not to load correctly or the app appear to hang.
            |- Fixed a backend issue where editing the same storage cache in quick succession would reject the edit
            |- Fixed an issue where dismissing the download dialog without selecting an option could prevent progress
            |- Fixed a potential issue where on first load of the app some data would not be populated correctly
            |- Fixed an issue where changing the same piece of data multiple times would not be reflected in the UI. (It would still be saved)
            |    - Example hitting "Refresh proposal" multiple times...
            |    - Example changing from metric to imperial and back again (Would stay as imperial)
            |- Fixed an issue where slideshow images were appearing out of order
            |- Fixed an issue where the download page would appear to "Hop around" images`));

const version105: Release =
  new Release(
    '1.0.5',
    'First full release',
    'Oct 2018',
    'release',
    '',
      StringUtils.stripMargin(
      `- Tripigo Page shows version
            |- Agent name now appears when downloading image
            |- Now correctly downloads 10 images per product`),
      StringUtils.stripMargin(`- Regression where Deep Links were not working.`));

const version104: Release =
  new Release(
    '1.0.4',
    'Initial release third beta',
    'Oct 2018',
    'beta',
    '',
    '- Download slideshow is swipeable',
    '- Images failing to load on iOS');

const version103: Release =
  new Release(
    '1.0.3',
    'Initial release second beta',
    'Oct 2018',
    'beta',
    '',
    '',
    '- Switch from testing database to live database');

// @ts-ignore
const version102: Release =
  new Release(
    '1.0.2',
    'Initial release second beta',
    'Oct 2018',
    'beta',
      StringUtils.stripMargin(
      ` - Offline Images
            | - Welcome: Proposal reference now shows
            | - Welcome: Pax names now show
            | - Entry Page: Itinerary field now appears`),
      StringUtils.stripMargin(
      `- Entry Contact: Supplier contact details do not appear on quotes
            | - Day Page: More details is easier to read
            | - Entry/Day Page: Pickup time is no preferred over start time for transfers
            | - Entry/Day Page: Reference has been renamed Confirmation Reference for clarity
            | - Entry/Day Page: Content has been re-ordered
            | - Itineraries Page: It is now easier and clearer to enter a QuickstartId
            | - Improved display when Pickup/Dropoff text is not present but Start/End is for accommodation
            | - Back button no longer disabled on download page. thus if there's an issue you can go back.`),
      StringUtils.stripMargin(
      `- iOS: Stuttering background images when scrolling
            | - Contact: Email now comes through correctly
            | - Dialogs: Are no longer yellow
            | - Entry Page: Issue where footer could dissapear
            | - Crash Reports: Fixed an issue on Android and iOS where crash reports were not working
            | - Analytics: Fixed an issue on Android where analytics were not working
            | - Download: Images not appearing
            | - Download: Could stall when downloading images
            | - Download: Could fail to start download
            | - Day by Day: Fixed an issue where it was possible for no image to appear on days with no entries.`));

const version101: Release =
  new Release(
    '1.0.1',
    'Initial release first beta',
    'Sep 2018',
    'beta',
      StringUtils.stripMargin(
      `- Rental Terms and Conditions now display (Where applicable)
            | - Smart text areas now support horizontal rules
            | - New Landing page. Will auto-direct to Today/Tomorrow`),
      StringUtils.stripMargin(
      `- Added 2 more types of bullet points
            | - Pickup and dropoff padding improvements
            | - Day page tonight's accommodation improvement
            | - Map Page: Made Direct now button more obvious
            | - Welcome only shows adult icon if no kids are on the trip
            | - Demo Itineraries has been shortened
            | - Demo's are no longer random`),
      StringUtils.stripMargin(
      `- Day by day page occasionally using the wrong start and end day
           | - Directions: slight right and slight left icons now appear`),
  );

const version100: Release =
  new Release(
    '1.0.0',
    'Intial release second alpha',
    'Sep 2018',
    'internal',
      StringUtils.stripMargin(
      `- Privacy Policy Page
            | - Terms and Conditions Page
            | - About Tripigo Page
            | - App now automatically loads on the today page when a trip is in progress
            | - App now automatically loads on the tomorrow page when a trip starts tomorrow
            | - App now automatically loads the welcome page of an itinerary if it is the only one.
            | - Resume: App will go to today page if the app has not been used today.
            | - Navigate to a service line direct from the day by day page`),
    '',
      StringUtils.stripMargin(
      `- Scroll content no longer goes through header and footer
            | - Download could hang
            | - Day card could overflow
            | - Slideshows could show the wrong images
            | - Slideshows were not looping`));

const version099: Release =
  new Release(
    '0.9.9',
    'Intial release first alpha',
    'Sep 2018',
    'internal',
      StringUtils.stripMargin(
      `- Fully Offline itineraries
            | - Info Page
            | - Contact Page
            | - Self Drive Directions
            | - Proposal Map Page
            | - Added branch links (android only at this stage)
            | - Added deep links (all platforms)`),
      StringUtils.stripMargin(
      `- Entry Page: Scroll resets to top of page pressing next or switching from map to contact etc.
            | - Day by Day Page: Scroll resets to top of page pressing next
            | - New and Improved Backgrounds
            | - Download bar no longer stutters
            | - Download speed increased
            | - Speed increase to Entry, Snapshot and Day by Day pages
            | - Transitions between pages have been adjusted
            | - Long descriptions now used by default`),
      StringUtils.stripMargin(
      `- Entry Page: Images not changing when pressing next
            | - Day by Day Page: Images not changing when pressing next
            | - Pages no longer flicker when transitioning`));

@Component({
  selector: 'app-about',
  templateUrl: './changelog.page.html',
  styleUrls: ['./changelog.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangelogPage extends BaseComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private media: MediaService,
    readonly navigator: NavigatorService,
    readonly assets: AssetService,
    readonly logger: LoggingService,
    readonly loading: LoadingMonitorService,
    readonly change: ChangeDetectorRef,
    readonly self: ElementRef,
    readonly user: UserService,
    readonly proposalService: ProposalService) {
    super('about_page', change, self, loading);
  }

  getImages(): ContextualImageSet {
    return this.assets.getBlueBackground();
  }

  getReleases(): List<Release> {
    return List.of(
        version120,
      version118,
      version117,
      version116,
      version115,
      version114,
      version113,
      version112,
      version111,
      version110,
      version105,
      version104,
      version103,
      version102,
      version101,
      version100,
      version099);
  }

  ngOnInit(): void {
    const f = () => super.ngOnInit();

    this.logger.setPage('changelog');
    this.route.params
      .pipe(modelThrottle(this.unsubscriberObs))
      .subscribe(ps => {
        this.logger.logEventWithProposalAndUser(
          'load_page',
            {page: 'changelog'});
        f();
      });
  }
}
