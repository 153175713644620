import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {DownloadSizeModalComponentModule} from '../../components/download-size-modal/download-size-modal.component.module';
import {FloatingCardComponentModule} from '../../components/floating-card/floating-card.component.module';
import {FloatingHeaderComponentModule} from '../../components/floating-header/floating-header.component.module';
import {HeaderSpacerComponentModule} from '../../components/header-spacer/header-spacer.component.module';
import {SlideshowBackgroundComponentModule} from '../../components/slideshow-background/slideshow-background.component.module';
import {DownloadPage} from './download.page';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SlideshowBackgroundComponentModule,
    FloatingCardComponentModule,
    HeaderSpacerComponentModule,
    DownloadSizeModalComponentModule,
    FloatingHeaderComponentModule,
    RouterModule.forChild([
      { path: '', component: DownloadPage },
    ]),
  ],
  declarations: [DownloadPage],
})
export class DownloadPageModule { }
