import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent<T> implements AfterViewInit, OnChanges {

  constructor() {
  }

  @Output()
  change: EventEmitter<T> = new EventEmitter<T>();

  @Input()
  chooseSelectionText = 'Please select a value';

  /**
   * Used to filter
   */
  @Input()
  fieldToCompare: (x: T) => Option<string>;

  filter: BehaviorSubject<string> = new BehaviorSubject(' ');

  @ViewChild('filter', {static: false})
  filterElem: ElementRef;

  @Input()
  initialSelection: Option<T> = None;

  @Input()
  inputData: List<T>;

  /**
   * Used for display purposes
   */
  @Input()
  labeller: (x: T) => string;

  selected: BehaviorSubject<Option<T>> = new BehaviorSubject<Option<T>>(None);
  selectedObs: Observable<string> = this.selected.asObservable()
      .pipe(map(sel => sel.map(this.labeller).getOrElse(this.chooseSelectionText)));

  @Input()
  tooltip = '';

  toShow: Observable<List<T>>;

  ngAfterViewInit(): void {
    this.populateToShow();
    this.initialSelection.forEach(x => {
      this.selected.next(Some(x));
      this.change.emit(x);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.selected.getValue().isEmpty() || this.selected.getValue().exists(x => !this.inputData.contains(x))) {
      this.selected.next(None);
      this.initialSelection.forEach(x => {
        this.selected.next(Some(x));
        this.change.emit(x);
      });
    }
    this.populateToShow();
  }

  private populateToShow(): void {
    this.toShow = this.filter
        .pipe(map(val => this.inputData.filter(
                (d: T) => val.trim() === '' || this.fieldToCompare(d).exists(l => l.toLowerCase().includes(val)))));
  }

  selectItem(t: T): void {
    this.change.emit(t);
    this.selected.next(Option.of(t));
    this.filter.next('');
    this.filterElem.nativeElement.value = '';
  }

  updateFilter(event): void {
    if (typeof event.target.value === 'string') {
      this.filter.next(event.target.value.toLowerCase().trim());
    }
  }
}
