import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ContextualImageSet, modelThrottle} from '@didgigo/lib-ts';
import {BaseComponent} from '../../lib-ionic/base-component';
import {AssetService} from '../../services/asset.service';
import {LoadingMonitorService} from '../../services/loading-monitor.service';
import {LoggingService} from '../../services/logging.service';
import {NavigatorService} from '../../services/navigator.service';
import {ProposalService} from '../../services/proposal.service';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.page.html',
  styleUrls: ['./privacy.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyPage extends BaseComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    readonly navigator: NavigatorService,
    readonly assets: AssetService,
    readonly logger: LoggingService,
    readonly loading: LoadingMonitorService,
    readonly change: ChangeDetectorRef,
    readonly user: UserService,
    readonly self: ElementRef,
    readonly proposalService: ProposalService) {
    super('privacy_policy_page', change, self, loading);
  }

  getImages(): ContextualImageSet {
    return this.assets.getBlueBackground();
  }

  ngOnInit(): void {
    const f = () => super.ngOnInit();

    this.logger.setPage('privacy');
    this.route.params
      .pipe(modelThrottle(this.unsubscriberObs))
      .subscribe(ps => {
        this.logger.logEventWithUser(
          'load_page',
          { page: 'privacy' },
          this.user.currentIdentity);
        f();
      });
  }
}
