import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {Plugins} from '@capacitor/core';
import {EntryTypeFilter, parseNumber, ProposalEntryWithMeta, ProposalWithMeta} from '@didgigo/lib-ts';
import {IonContent} from '@ionic/angular';
import {List} from 'immutable';
import {BehaviorSubject} from 'rxjs';
import {BaseComponent} from '../../lib-ionic/base-component';
import {AssetService} from '../../services/asset.service';
import {LoadingMonitorService} from '../../services/loading-monitor.service';
import {LoggingService} from '../../services/logging.service';
import {MediaService} from '../../services/media.service';
import {NavigatorService} from '../../services/navigator.service';
import {ProposalService} from '../../services/proposal.service';
import {UserService} from '../../services/user.service';

const { Keyboard } = Plugins;

export class SnapshotPageModel {
  constructor(
      readonly proposal: ProposalWithMeta,
      readonly entries: List<ProposalEntryWithMeta>, // Entries here for caching purposes
      readonly searchText: string,
      readonly searchType: EntryTypeFilter) {
  }

  isHidden(e: ProposalEntryWithMeta): boolean {
    return !(e.getEntry().matchesTypeFilter(this.searchType) && e.getEntry().matchesFuzzyTextFilter(this.searchText));
  }
}

@Component({
  selector: 'app-snapshot',
  templateUrl: './snapshot-page.page.html',
  styleUrls: ['./snapshot-page.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SnapshotPage extends BaseComponent implements OnInit, AfterViewInit {

  constructor(
      private route: ActivatedRoute,
      readonly mediaService: MediaService,
      readonly navigator: NavigatorService,
      readonly assets: AssetService,
      readonly user: UserService,
      readonly logger: LoggingService,
      readonly loading: LoadingMonitorService,
      readonly change: ChangeDetectorRef,
      readonly self: ElementRef,
      readonly proposalService: ProposalService,
  ) {
    super('snapshot_page', change, self, loading);
  }

  @ViewChild(IonContent, {static: false})
  content: IonContent;
  model: BehaviorSubject<SnapshotPageModel> = new BehaviorSubject<SnapshotPageModel>(new SnapshotPageModel(new ProposalWithMeta(), List(), '', 'All'));

  hideKeyboard(): void {
    Keyboard.hide();
  }

  async ngOnInit(): Promise<void> {
    const ps = this.route.snapshot.params;
    this.logger.setPage('snapshot');
    this.navigator.setProposalId(ps.id);
    this.logger.logEventWithProposalAndUser(
        'load_page',
        {page: 'snapshot'},
        parseNumber(ps.id));
    const proposal = await this.navigator.getCurrentProposalForIdNonOptional(parseNumber(ps.id).get());
    this.model.next(new SnapshotPageModel(proposal, proposal.getSortedEntries(), '', 'All'));
    super.ngOnInit();
  }

  searchFor(e: any): void {
    const value = this.model.getValue();
    if (value.searchText !== e.target.value) {
      this.model.next(new SnapshotPageModel(value.proposal, value.entries, e.target.value, value.searchType));
    }
  }

  showType(value: EntryTypeFilter): void {
    const model = this.model.getValue();
    if (model.searchType !== value) {
      this.model.next(new SnapshotPageModel(model.proposal, model.entries, model.searchText, value));
    }
  }
}
