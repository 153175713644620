import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FloatingCardComponentModule } from '../../components/floating-card/floating-card.component.module';
import { FloatingHeaderComponentModule } from '../../components/floating-header/floating-header.component.module';
import { HeaderSpacerComponentModule } from '../../components/header-spacer/header-spacer.component.module';
import { ImageBackgroundComponentModule } from '../../components/image-background/image-background.component.module';
import { LoadingComponentModule } from '../../components/loading/loading.component.module';
import { SettingsPage } from './settings.page';

@NgModule({
  imports: [
    LoadingComponentModule,
    FloatingCardComponentModule,
    HeaderSpacerComponentModule,
    ImageBackgroundComponentModule,
    CommonModule,
    FloatingHeaderComponentModule,
    IonicModule,
    RouterModule.forChild([
      { path: '', component: SettingsPage },
    ]),
  ],
  declarations: [SettingsPage],
})
export class SettingsPageModule { }
