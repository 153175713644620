import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-spacer',
  templateUrl: './footer-spacer.component.html',
  styleUrls: ['./footer-spacer.component.scss'],
})
export class FooterSpacerComponent implements OnInit {

  @Input()
  full = false;

  constructor() { }

  ngOnInit() {
  }

}
