import {HttpClientModule} from '@angular/common/http';
import {ErrorHandler, NgModule} from '@angular/core';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouteReuseStrategy, RouterModule} from '@angular/router';
import {SmartTextAreaComponentModule} from '@didgigo/lib-angular';
import {CallNumber} from '@ionic-native/call-number/ngx';
import {Dialogs} from '@ionic-native/dialogs/ngx';
import {FileTransfer} from '@ionic-native/file-transfer/ngx';
import {File} from '@ionic-native/file/ngx';
import {LaunchNavigator} from '@ionic-native/launch-navigator/ngx';
import {MobileAccessibility} from '@ionic-native/mobile-accessibility/ngx';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {ChartsModule} from 'ng2-charts';
import {LinkyPipe} from 'ngx-linky';
import {NgxSpinnerModule} from 'ngx-spinner';
import {environment} from '../../../epresentations/src/environments/environment';
import {AppComponent} from './app.component';
import {DownloadSizeModalComponentModule} from './components/download-size-modal/download-size-modal.component.module';
import {FloatingHeaderComponentModule} from './components/floating-header/floating-header.component.module';
import {MapMarkerModalComponentModule} from './components/map-marker-modal/map-marker-modal.component.module';
import {ProposalMenuComponentModule} from './components/proposal-menu/proposal-menu.component.module';
import {WhoAreYouModalComponentModule} from './components/who-are-you-modal/who-are-you-modal.component.module';
import {StorageCacheProviderService, StringCacheProviderService} from './services/cache-provider.service';
import {TripigoErrorHandler} from './services/error-service';
import {IonicGestureConfig} from './services/ionic-gesture-config.service';
import {getDefaultConfig} from './services/storage.service';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        FloatingHeaderComponentModule,
        ProposalMenuComponentModule,
        BrowserAnimationsModule,
        ChartsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        SmartTextAreaComponentModule,
        NgxSpinnerModule,
        WhoAreYouModalComponentModule,
        MapMarkerModalComponentModule,
        DownloadSizeModalComponentModule,
        AngularFireModule.initializeApp({
            apiKey: 'AIzaSyCeCZ1J-DwHDHvftSh8bt-aE1Bhk54f9bg',
            authDomain: 'tripigo-7d5d1.firebaseapp.com',
            databaseURL: 'https://tripigo-7d5d1.firebaseio.com',
            projectId: 'tripigo-7d5d1',
            storageBucket: 'tripigo-7d5d1.appspot.com',
            messagingSenderId: '632480224246',
        }),
        AngularFirestoreModule,
        RouterModule.forRoot([
                {path: '', redirectTo: 'landing', pathMatch: 'full'},
                {path: 'proposals', loadChildren: () => import('./pages/proposals/proposals.module').then(m => m.ProposalsPageModule)},
                {path: 'settings', loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)},
                {path: 'landing', loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingPageModule)},
                {path: 'tripigo', loadChildren: () => import('./pages/tripigo/tripigo.module').then(m => m.TripigoPageModule)},
                {path: 'privacy', loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyPageModule)},
                {path: 'terms', loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsPageModule)},
                {path: 'admin', loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminPageModule)},
                {path: 'day/:id/:date', loadChildren: () => import('./pages/day/day.module').then(m => m.DayPageModule)},
                {path: 'day-map/:id/:day', loadChildren: () => import('./pages/day-map/day-map.module').then(m => m.DayMapPageModule)},
                {path: 'snapshot/:id', loadChildren: () => import('./pages/snapshot/snapshot.module').then(m => m.SnapshotPageModule)},
                {
                    path: 'snapshot-subproducts/:id/:eid',
                    loadChildren: () => import('./pages/snapshot-subproducts/snapshot-subproducts.module').then(m => m.SnapshotSubproductsPageModule),
                },
                {path: 'welcome/:id', loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomePageModule)},
                {path: 'prices/:id', loadChildren: () => import('./pages/prices/prices.module').then(m => m.PricesPageModule)},
                {
                    path: 'proposal-terms/:id',
                    loadChildren: () => import('./pages/proposal-terms/proposal-terms.module').then(m => m.ProposalTermsPageModule),
                },
                {
                    path: 'helpful-hints/:id',
                    loadChildren: () => import('./pages/helpful-hints/helpful-hints.module').then(m => m.HelpfulHintsModule),
                },
                {
                    path: 'important-contacts/:id',
                    loadChildren: () => import('./pages/important-contacts/important-contacts.module').then(m => m.ImportantContactsModule),
                },
                {path: 'about/:id', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutPageModule)},
                {path: 'map/:id', loadChildren: () => import('./pages/map/map.module').then(m => m.MapPageModule)},
                {path: 'download/:id', loadChildren: () => import('./pages/download/download.module').then(m => m.DownloadPageModule)},
                {path: 'contact/:id', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactPageModule)},
                {path: 'entry/:id/:eid', loadChildren: () => import('./pages/entry/entry.module').then(m => m.EntryPageModule)},
                {path: 'entry/:id/:eid/:pid', loadChildren: () => import('./pages/entry/entry.module').then(m => m.EntryPageModule)},
                {path: 'chat/:room', loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule)},
                {path: 'messages', loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesPageModule)},
                {path: 'agent', loadChildren: () => import('./pages/agent/agent.module').then(m => m.AgentPageModule)},
                {path: 'changelog', loadChildren: () => import('./pages/changelog/changelog.module').then(m => m.ChangelogPageModule)},
                {path: '**', redirectTo: 'landing'},
            ],
            {
                useHash: true,
                enableTracing: false,
            }),
        IonicModule.forRoot({
            mode: 'ios',
        }),
    ],
    providers: [
        CallNumber,
        File,
        FileTransfer,
        Dialogs,
        LaunchNavigator,
        MobileAccessibility,
        {provide: LinkyPipe, useClass: LinkyPipe},
        {provide: StringCacheProviderService, useClass: StorageCacheProviderService},
        {provide: RouteReuseStrategy, useValue: new IonicRouteStrategy()},
        {provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig},
        {provide: ErrorHandler, useClass: TripigoErrorHandler},
        {provide: 'apiKey', useValue: environment.apikey},
        {provide: 'apiServer', useValue: environment.apiServer},
        {provide: 'storageConfig', useValue: getDefaultConfig()},
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {
        console.log(`Using ApiKey: ${environment.apikey}`);
        console.log(`Using ApiServer: ${environment.apiServer}`);
    }
}
