import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {ActivatedRoute} from '@angular/router';
import {
    ChatMessage,
    ChatMessageData,
    ContextualImageSet,
    convertMapToObj,
    IdentifiablePerson,
    IdentifiablePersonJsonSerializer,
    isMomentBetween,
    modelDebounce,
    now,
    OptionUtils,
    RoomAndMessageData,
    RoomData,
    StringUtils,
} from '@didgigo/lib-ts';
import {IonItemSliding, Platform} from '@ionic/angular';
import {Components} from '@ionic/core';
import {Option, Some} from 'funfix-core';
import {List, Map} from 'immutable';
import {combineLatest, from, Observable, of, ReplaySubject} from 'rxjs';
import {delay, distinctUntilChanged, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {BaseComponent} from '../../lib-ionic/base-component';
import {AssetService} from '../../services/asset.service';
import {ChatService} from '../../services/chat.service';
import {ConfigurationService} from '../../services/configuration.service';
import {LoadingMonitorService} from '../../services/loading-monitor.service';
import {LoggingService} from '../../services/logging.service';
import {NavigatorService} from '../../services/navigator.service';
import {ProposalService} from '../../services/proposal.service';
import {ThemeService} from '../../services/theme.service';
import {UserService} from '../../services/user.service';
import IonContent = Components.IonContent;
import IonTextarea = Components.IonTextarea;

@Component({
  selector: 'app-chat',
  templateUrl: './chat.page.html',
  styleUrls: ['./chat.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatPage extends BaseComponent implements OnInit, AfterViewInit {

  constructor(
    readonly logging: LoggingService,
    readonly proposals: ProposalService,
    readonly navigator: NavigatorService,
    readonly chat: ChatService,
    readonly platform: Platform,
    readonly loading: LoadingMonitorService,
    private route: ActivatedRoute,
    readonly db: AngularFirestore,
    readonly user: UserService,
    readonly assets: AssetService,
    readonly configuration: ConfigurationService,
    readonly theme: ThemeService,
    readonly change: ChangeDetectorRef,
    readonly self: ElementRef) {
    super('chat_page', change, self, loading);
  }

  chats: Observable<List<ChatMessageData>>;

  idSubj = new ReplaySubject<string>(1);

  @ViewChild('input', { static: false })
  input: IonTextarea;

  roomObservable: Observable<Option<RoomAndMessageData>> =
    this.idSubj.asObservable()
      .pipe(switchMap(i => this.chat.observeRoom(i)))
      .pipe(modelDebounce(this.unsubscriberObs))
      .pipe(tap(_ =>
        of(1)
          .pipe(delay(200))
          .subscribe(x => {
            if (Option.of(this.scroll).nonEmpty()) {
              this.scroll.scrollToBottom(100).catch(err => console.warn('Scroll Failed'));
            }
          })));

  @ViewChild('scroll', { static: false })
  scroll: IonContent;

  private convertToChatMessageData(
    message: ChatMessage,
    messages: List<ChatMessage>,
    msgIdx: number,
    users: List<IdentifiablePerson>,
    currentUser: Option<IdentifiablePerson>,
    rm: RoomData,
    sender: Option<IdentifiablePerson>): ChatMessageData {
    const senderIdx = sender.flatMap(s => s.identity).flatMap(i => rm.getJoinedIdx(i)).getOrElse(-1);
    const currentUserIdx = currentUser.flatMap(u => u.identity).flatMap(i => rm.getJoinedIdx(i)).getOrElse(-1);
    const usersWhoLastSawThis = this.getUsersWhoLastSawThisMessage(msgIdx, messages, rm, users).toSet();

    const usersIdx =
      usersWhoLastSawThis
        .map(userId => rm.getJoinedIdx(userId).getOrElse(-1))
        .remove(currentUserIdx);

    // Remove current user from the list, they know they saw the message
    return new ChatMessageData(message, sender, senderIdx, usersIdx);
  }

  deleteMessage(sliding: IonItemSliding, chat: ChatMessageData): void {
    sliding.close()
      .then(_ =>
        this.roomObservable
          .pipe(take(1))
          .subscribe(rm =>
            rm.flatMap(r => r.roomData.id)
              .forEach(id => this.chat.deleteMessage(id, chat))));
  }

  editMessage(sliding: IonItemSliding, chat: ChatMessageData): void {
    sliding.close()
      .then(_ =>
        this.roomObservable
          .pipe(take(1))
          .subscribe(rm =>
            rm.flatMap(r => r.roomData.id)
              .forEach(id => this.chat.editMessage(id, chat))));

  }

  private getChatMessageList(or: Option<RoomAndMessageData>): Observable<List<ChatMessageData>> {
    return this.user.currentIdentity
      .pipe(take(1))
      .pipe(map(currentUser => {
        if (or.isEmpty()) {
          return List();
        }
        const room = or.get();
        return OptionUtils.flattenList(room.getSortedMessages().map((message, msgIdx, msgs) =>
          message.from
            .flatMap(f => Option.of(room.getUserById(f)))
            .map(user =>
              this.convertToChatMessageData(
                message,
                msgs,
                msgIdx,
                room.users.valueSeq().toList(),
                currentUser,
                room.roomData,
                user))));
      }));
  }

  getDefaultChatText(): Observable<string> {
    return this.roomObservable
      .pipe(take(1))
      .pipe(map(rm => this.getDefaultRoomChatText(rm.map(r => r.roomData))));
  }

  getDefaultRoomChatText(rm: Option<RoomData>): string {
    if (rm.exists(r => r.isGeneralChat())) {
        return StringUtils.stripMargin(
        `Welcome to the Tripigo chat page! Here you can chat amongst the travellers on this trip.
            |Note: No travel staff are part of this chat channel`);
    } else if (rm.exists(r => r.isAgentGeneralChat())) {
      return `Welcome to the Tripigo chat page! Here you can chat with your travel agent`;
    } else {
      return 'Welcome to Tripigo chat';
    }
  }

  getImages(): ContextualImageSet {
    return this.assets.getBlueBackground();
  }

  private getRawUser(k): Promise<firebase.firestore.QuerySnapshot> {
    return this.db.collection(this.configuration.getFirestoreUsersCollectionId())
      .ref.where('identity', '==', k)
      .get();
  }

  private getUserMapKey(u: Option<IdentifiablePerson>): [string, IdentifiablePerson] {
    return [u.get().identity.getOrElse('Unknown'), u.get()];
  }

  getUsersInRoom(): Observable<Map<string, IdentifiablePerson>> {
    return this.roomObservable
      .pipe(switchMap(rm => {
        if (rm.isEmpty()) {
          return of([]);
        }
        return from(Promise.all(
          rm.get().roomData.joined.keySeq().toArray()
            .map(k => this.getRawUser(k))));
      },
      )).pipe(map(querySnapshot =>
        List(querySnapshot
          .map(documentSnapshot => documentSnapshot.docs.map(d => d.data())[0]) // DbProductResultSet always has 1 row
          .map(userData => IdentifiablePersonJsonSerializer.instance.fromJson(userData)))))
      .pipe(map(users => Map(users.filter(u => u.nonEmpty()).map(u => this.getUserMapKey(u)))));
  }

  // Return list of ids of people who have seen this message
  // Allows putting character portrait after this message
  // Note: This has a large algorithmic complexity... If we end up with large rooms this will be slow
  // A better solution would be to precalculate the index per user, rather then checking every user for every message
  getUsersWhoLastSawThisMessage(
    idx: number, chats: List<ChatMessage>, rm: RoomData, users: List<IdentifiablePerson>): List<string> {
    const currentChatTime = Option.of(chats.get(idx)).flatMap(c => c.time);
    const nextChatTime = Option.of(chats.get(idx + 1)).flatMap(c => c.time);
    return users
      .valueSeq()
      .toList()
      .filter(u => u.identity.exists(id =>
        Option.of(rm.last_active.get(id))
          .exists(time =>
            this.isLastSeenChatMessage(currentChatTime, nextChatTime, time))))
      .map(u => u.identity.get());
  }

  private isLastSeenChatMessage(currentChatTime, nextChatTime, time): boolean {
    return isMomentBetween(currentChatTime, nextChatTime, time)
      || (currentChatTime.exists(t => time.isAfter(t)) && nextChatTime.isEmpty());
  }

  markActiveInRoom(): void {
    this.user.currentIdentity
      .pipe(take(1))
      .subscribe(optUser => {
        if (optUser.flatMap(ident => ident.identity).isEmpty()) {
          return;
        }

        const userId = optUser.flatMap(ident => ident.identity).get();

        this.idSubj.asObservable()
          .pipe(take(1))
          .pipe(switchMap(i => this.chat.ensureRoomExistsInFirebase(i)))
          .pipe(switchMap(rm => {
            if (rm.isEmpty()) {
              console.error('Failed to ensure room exists');
              return of();
            }

            return from(this.chat.updateRoom(rm.get(), room => {
              const n = now();
              if (!room.joined.has(userId)) {
                return {
                  joined: convertMapToObj(room.joined.asImmutable().set(userId, n), m => m.toISOString()),
                  last_active: convertMapToObj(room.last_active.asImmutable().set(userId, n), m => m.toISOString()),
                };
              }
              return {
                last_active: convertMapToObj(room.last_active.asImmutable().set(userId, n), m => m.toISOString()),
              };
            }));
          })).subscribe();
      });
  }

  ngAfterViewInit(): void {
    if (Option.of(this.scroll).nonEmpty()) {
      // Scroll can fail if this is not the active page
      this.scroll.scrollToBottom(100).catch(err => console.warn('Scroll Failed'));
    }
  }

  ngOnInit(): void {
    this.logging.setPage('chat');
    const f = () => super.ngOnInit();

    this.route.params
      .subscribe(ps => {
        this.logging.logEventWithProposalAndUser(
          'load_page',
            {page: 'chat'});
        this.idSubj.next(ps.room);

        this.markActiveInRoom();
        this.chats = this.observeChatMessages();
        this.navigator.observeIsActivePage(`/chat/${ps.room}`)
          .pipe(takeUntil(this.unsubscriberObs))
          .subscribe(active => {
            this.markActiveInRoom();
          });
        f();
      });
  }

  private observeChatMessages(): Observable<List<ChatMessageData>> {
    return this.roomObservable
      .pipe(switchMap(rm => this.getChatMessageList(rm)))
      .pipe(modelDebounce(this.unsubscriberObs))
      .pipe(x => {
        // Important: Only mark active when new messages come in
        x.pipe(distinctUntilChanged<List<ChatMessageData>>((a, b) => a.size === b.size))
          .subscribe(_ => {
            this.markActiveInRoom();
          });
        return x;
      })
      .pipe(modelDebounce(this.unsubscriberObs));
  }

  submitMessage(text): void {
    if (text.trim().length === 0) {
      return;
    }

    this.input.value = '';

    combineLatest(this.user.currentIdentity, this.idSubj.asObservable())
      .pipe(take(1))
      .subscribe(([ident, rm]) => {
        ident.forEach(u => u.identity.forEach(id => {
          const chatMessage = new ChatMessage(u.identity, Some(now()), Option.of(text), Option.of(false));
          this.chat.sendMessage(rm, chatMessage);
        }));
      },
      );
  }
}
