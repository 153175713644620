import {Injectable} from '@angular/core';
import {Crud, InMemoryCache, NoCache, PrefixedCrud, PrefixedSerializationFlatStorageCrud, SimpleJsonSerializer} from '@didgigo/lib-ts';
import {StorageCache} from '../lib-ionic/storage-cache';
import {UpdatingStorage} from '../lib-ionic/updating-storage';

export abstract class CacheProviderService {
  abstract getCache<K, V>(): Crud<K, V>;

  getPrefixedCache<V>(prefix: string): Crud<string, V> {
    return new PrefixedCrud(prefix, this.getCache());
  }
}

export abstract class StringCacheProviderService {
  abstract getCache(): Crud<string, string>;

  getPrefixedCache(prefix: string): Crud<string, string> {
    return new PrefixedCrud(prefix, this.getCache());
  }

  getPrefixedSerializableCache<V>(prefix: string, serializer: SimpleJsonSerializer<V>): Crud<string, V> {
    return new PrefixedSerializationFlatStorageCrud(prefix, serializer, this.getCache());
  }
}

@Injectable({
  providedIn: 'root',
})
export class StorageCacheProviderService extends StringCacheProviderService {

  constructor(
    readonly storage: UpdatingStorage) {
    super();
  }

  getCache(): StorageCache {
    return new StorageCache(this.storage);
  }
}

@Injectable({
  providedIn: 'root',
})
export class InMemoryCacheProviderService extends CacheProviderService {
  getCache<K, V>(): InMemoryCache<K, V> {
    return new InMemoryCache();
  }
}

@Injectable({
  providedIn: 'root',
})
export class NoCacheProviderService extends CacheProviderService {
  getCache<K, V>(): NoCache<K, V> {
    return new NoCache();
  }
}
