import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FloatingCardComponentModule } from '../floating-card/floating-card.component.module';
import { HeaderSpacerComponentModule } from '../header-spacer/header-spacer.component.module';
import { ImagePreloaderComponentModule } from '../image-preloader/image-preloader.component.module';
import { WhoAreYouModalComponent } from './who-are-you-modal.component';

@NgModule({
  imports: [
    CommonModule,
    HeaderSpacerComponentModule,
    ImagePreloaderComponentModule,
    FloatingCardComponentModule,
    IonicModule.forRoot(),
  ],
  declarations: [WhoAreYouModalComponent],
  exports: [WhoAreYouModalComponent],
  entryComponents: [WhoAreYouModalComponent],
})
export class WhoAreYouModalComponentModule { }
