import {Injectable} from '@angular/core';
import {Entry} from '@didgigo/lib-ts';
import {Option} from 'funfix-core';
import {Set} from 'immutable';
import {BehaviorSubject, from, Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {Storage} from '../services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class UpdatingStorage {

  constructor(readonly storage: Storage) {
  }

  lastUpdated: BehaviorSubject<string> = new BehaviorSubject('none');

  async clear(): Promise<void> {
    await this.storage.ready();
    await this.storage.clear();
  }

  // HACK: trigger entries when underlying store is updated
  async entries(): Promise<Set<Entry<string, string>>> {
    await this.storage.ready();
    return this.populateEntries();
  }

  async isClear(): Promise<boolean> {
    await this.storage.ready();
    const keys = await this.keys();
    return keys.isEmpty();
  }

  async keys(): Promise<Set<string>> {
    await this.storage.ready();
    const keys = await this.storage.keys();
    return Set(keys);
  }

  async length(): Promise<number> {
    await this.storage.ready();
    return this.storage.length();
  }

  observe(key: string): Observable<Option<string>> {
    const valueWhenCalled = this.lastUpdated.getValue();
    return this.lastUpdated
        .pipe(filter(x => Set.of(key, 'none', 'all', valueWhenCalled).contains(x)))
        .pipe(switchMap(_ => from(this.storage.ready())))
        .pipe(switchMap(_ => from(this.storage.get(key))))
        .pipe(map(x => Option.of(x)));
  }

  private async populateEntries(): Promise<Set<Entry<string, string>>> {
    const entries: Array<Entry<string, string>> = [];
    await this.storage.ready();
    await this.storage.forEach((v, k, i) => {
      entries.push(new Entry(k, v));
    });
    return Set(entries);
  }

  async remove(key: string): Promise<Option<string>> {
    await this.storage.ready();
    await this.storage.remove(key);
    return Option.of(key);
  }

  async set(key: string, value: string): Promise<Option<string>> {
    await this.storage.ready();
    await this.storage.set(key, value);
    this.triggerUpdate(key);
    return Option.of(value);
  }

  private triggerUpdate(key: string): void {
    this.lastUpdated.next(key);
  }
}
