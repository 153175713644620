import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ImagePreloaderComponentModule } from '../image-preloader/image-preloader.component.module';
import { UserIconComponent } from './user-icon.component';

@NgModule({
  imports: [
    CommonModule,
    ImagePreloaderComponentModule,
    IonicModule.forRoot(),
  ],
  declarations: [UserIconComponent],
  exports: [UserIconComponent],
  entryComponents: [UserIconComponent],
})
export class UserIconComponentModule { }
