import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {FloatingFooterComponentModule} from '../../components/floating-footer/floating-footer.component.module';
import {FloatingHeaderComponentModule} from '../../components/floating-header/floating-header.component.module';
import {FooterSpacerComponentModule} from '../../components/footer-spacer/footer-spacer.component.module';
import {MapComponentModule} from '../../components/map/map.component.module';
import {MapPage} from './map.page';

@NgModule({
    imports: [
        CommonModule,
        FloatingHeaderComponentModule,
        IonicModule,
        MapComponentModule,
        RouterModule.forChild([
            {path: '', component: MapPage},
        ]),
        FloatingFooterComponentModule,
        FooterSpacerComponentModule,
    ],
  declarations: [MapPage],
})
export class MapPageModule { }
