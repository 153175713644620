import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {LinkyPipe} from 'ngx-linky';

const numberedLines = /^[^\S\n]*\d+\.\s+(.*)/gm;
const unorderedLines = /^[^\S\n]*[-*•·]\s+(.*)/gm;
const headings = /^[^\S\n]*(.*):[^\S\n]*$/gm;
const inlineEmphasis = /^([a-zA-Z ]+: )/gm;
const horizontalRule = /^[- ]{3,}$/gm;

@Pipe({
    name: 'formatter',
})
export class FormatterPipe implements PipeTransform {

    constructor(readonly domSanitizer: DomSanitizer, readonly linky: LinkyPipe) {
    }

    addHeadings(s: string): string {
        return s.replace(headings, `<span mini-header>$1</span>`);
    }

    addInlineEmphasis(s: string): string {
        return s.replace(inlineEmphasis, `<span bolden>$1</span>`);
    }

    private addParagraphs(value: string): string {
        const paragraphs = value.trim().split('\n');
        if (paragraphs.length < 2) {
            return value;
        }

        const s = paragraphs
            .reduce((acc, current, idx) => {
                if (idx === 0) {
                    return '<p no-margin>' + current;
                } else if (current.startsWith('<')) {
                    return acc + '</p><p no-margin>' + current;
                } else if (idx === paragraphs.length - 1) {
                    return acc + '</p><p no-margin margin-top>' + current;
                }
                return acc + '</p><p>' + current;
            }, '') + '</p>';

        return s.replace('<p></p>', '');
    }

    private convertHorizontalRules(value: string): string {
        return value.trim()
            .split('\n')
            .map(x => {
                if (x.match(horizontalRule)) {
                    return '<hr hr/>';
                }
                return x;
            }).join('\n');
    }

    /**
     * Converts ordered and unordered lists to html
     *
     * First wrap lines in '<oli></oli>'
     * Second add start and end tags <ol></ol>
     * Third replace oli with li
     */
    private convertLists(value: string): string {
        const withOrdered = this.convertOrderedLines(value);
        const withUnOrdered = this.convertUnOrderedLines(withOrdered);
        const withEnds = withUnOrdered.split('\n')
            .reduce((a, b) => {
                const lastWasOli = a.endsWith('</oli>');
                const nextIsOli = b.startsWith('<oli>');
                const lastWasUli = a.endsWith('</uli>');
                const nextIsUli = b.startsWith('<uli>');
                if (lastWasOli && !nextIsOli) {
                    return a + '</ol>\n' + b;
                } else if (!lastWasOli && nextIsOli) {
                    return a + '\n<ol numbering>' + b;
                } else if (lastWasUli && !nextIsUli) {
                    return a + '</ul>\n' + b;
                } else if (!lastWasUli && nextIsUli) {
                    return a + '\n<ul>' + b;
                }
                return a + '\n' + b;
            }, '');

        return withEnds
            .replace(/<oli>|<uli>/g, '<li>')
            .replace(/<\/oli>\n|<\/uli>\n/g, '<\/li>');
    }

    private convertOrderedLines(value: string): string {
        return value.replace(numberedLines, `<oli>$1</oli>`);
    }

    private convertUnOrderedLines(value: string): string {
        return value.replace(unorderedLines, `<uli>$1</uli>`);
    }

    transform(value: string, args?: FormatterOptions): SafeHtml {
        if (!value) {
            return value;
        }
        const result =
            this.addParagraphs(
                this.addInlineEmphasis(
                    this.addHeadings(
                        this.convertLists(
                            this.convertHorizontalRules(value)))));
        return this.domSanitizer.bypassSecurityTrustHtml(this.linky.transform(result));
    }
}

export class FormatterOptions {
    constructor(
        readonly addParagraphSpacing: boolean = true,
        readonly convertNumberedLists: boolean = true) {
    }
}
