import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-floating-footer',
  templateUrl: './floating-footer.component.html',
  styleUrls: ['./floating-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatingFooterComponent implements OnInit {

  @Input()
  title : string;

  constructor() { }

  ngOnInit() {
  }

}
