import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ProposalEntryWithMeta} from '@didgigo/lib-ts';
import {AssetService} from 'app/services/asset.service';
import {NavigatorService} from '../../services/navigator.service';

@Component({
    selector: 'app-entry-details-large',
    templateUrl: './entry-details-large.component.html',
    styleUrls: ['./entry-details-large.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntryDetailsLargeComponent implements OnInit {

    constructor(
        readonly navigator: NavigatorService,
        readonly assets: AssetService) {
    }

    @Input()
    model: ProposalEntryWithMeta;

    /**
     * Affects specific custom entries, leisure/transit/insurance
     */
    @Input()
    showSpecialTimeDetails = true;

    isHiddenSpecial(): false | boolean {
        return !this.showSpecialTimeDetails && this.model.isSpecialCustom();
    }

    ngOnInit(): void {
    }

    shouldShowDropoff(): boolean {
        if (this.model === undefined) {
            return false;
        }

        return this.model.isProposalEntryDropoffRemarkVisible()
            && !this.model.isQuote()
            && !this.isHiddenSpecial();
    }

    shouldShowEnd(): boolean {
        if (this.model === undefined) {
            return false;
        }
        return this.model.isProposalEntryEndTimeVisible()
            && !this.model.isQuote()
            && !this.isHiddenSpecial();
    }

    shouldShowPickup(): boolean {
        if (this.model === undefined) {
            return false;
        }
        return this.model.isProposalEntryPickupRemarkVisible()
            && !this.model.isQuote()
            && !this.isHiddenSpecial();
    }

    shouldShowStart(): boolean {
        if (this.model === undefined) {
            return false;
        }
        return this.model.isProposalEntryStartTimeVisible()
            && !this.model.isQuote()
            && !this.isHiddenSpecial();
    }
}
