import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {SmartTextAreaComponentModule} from '../../../../../web-core/angular/src/components';
import {FloatingCardComponentModule} from '../../components/floating-card/floating-card.component.module';
import {FloatingHeaderComponentModule} from '../../components/floating-header/floating-header.component.module';
import {HeaderSpacerComponentModule} from '../../components/header-spacer/header-spacer.component.module';
import {ImageBackgroundComponentModule} from '../../components/image-background/image-background.component.module';
import {ImagePreloaderComponentModule} from '../../components/image-preloader/image-preloader.component.module';
import {LoadingComponentModule} from '../../components/loading/loading.component.module';
import {TripigoPage} from './tripigo.page';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        LoadingComponentModule,
        ImageBackgroundComponentModule,
        ImagePreloaderComponentModule,
        FloatingCardComponentModule,
        FloatingHeaderComponentModule,
        HeaderSpacerComponentModule,
        RouterModule.forChild([
            {path: '', component: TripigoPage},
        ]),
        SmartTextAreaComponentModule,
    ],
  declarations: [TripigoPage],
})
export class TripigoPageModule { }
