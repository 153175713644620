import {Location} from '@angular/common';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Plugins} from '@capacitor/core';
import {ColourPalette, disableSanitization, parseString} from '@didgigo/lib-ts';
import {MobileAccessibility} from '@ionic-native/mobile-accessibility/ngx';
import {Platform} from '@ionic/angular';
import {BranchInitEvent} from 'capacitor-branch-deep-links';
import {BaseComponent} from './lib-ionic/base-component';
import {BrowserService} from './services/browser.service';
import {ConfigurationService} from './services/configuration.service';
import {DialogService} from './services/dialog-service';
import {LoadingMonitorService} from './services/loading-monitor.service';
import {LoggingService} from './services/logging.service';
import {MenuService} from './services/menu.service';
import {NavigatorService} from './services/navigator.service';
import {NetworkService} from './services/network.service';
import {ProposalDownloaderService} from './services/proposal-downloader.service';
import {UserService} from './services/user.service';
import Color = require('color');

const { BranchDeepLinks, SplashScreen } = Plugins;

const whiteColor = Color('#FFFFFF');
const blackColor = Color('#000000');

// Read API can do the string sanitization, we dont have to.
disableSanitization();

const defaultPallette = new ColourPalette(
    '#FFDD00',
    '#00A1E4',
    '#00A1E4',
    '#454545');

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends BaseComponent {

  constructor(
      private platform: Platform,
      private location: Location,
      readonly menuService: MenuService,
      readonly browser: BrowserService,
      readonly navigator: NavigatorService,
      readonly user: UserService,
      readonly logging: LoggingService,
      readonly proposalService: ProposalDownloaderService,
      readonly network: NetworkService,
      readonly loadingMonitor: LoadingMonitorService,
      readonly configuration: ConfigurationService,
      readonly accessibility: MobileAccessibility,
      readonly change: ChangeDetectorRef,
      readonly dialog: DialogService,
      readonly self: ElementRef,
      readonly db: AngularFirestore) {
    super('app', change, self, loadingMonitor);
    this.platform.ready().then(() => {
      this.setupBackButton();
      this.accessibility.setTextZoom(100);
      this.setupAnalytics();
      this.setupFirebase();
      this.network.setupNetworkHandlers();
      this.user.createGuestIdentityIfNotLoggedIn();
      this.setupColourScheme();
    });
  }

  private contrast(c: Color): Color {
    return c.isLight() ? blackColor : whiteColor;
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  private setIonicVariables(type: 'primary' | 'secondary' | 'tertiary' | 'text', hex: string): void {
    const color = Color(hex);
    document.documentElement.style.setProperty(`--ion-color-${type}`, hex);
    document.documentElement.style.setProperty(`--ion-color-${type}-rgb`, color.rgb().array().toString());
    document.documentElement.style.setProperty(`--ion-color-${type}-contrast`, this.contrast(color).hex());
    document.documentElement.style.setProperty(`--ion-color-${type}-contrast-rgb`, this.contrast(color).rgb().array().toString());
    document.documentElement.style.setProperty(`--ion-color-${type}-shade`, color.darken(0.12).hex());
    document.documentElement.style.setProperty(`--ion-color-${type}-tint`, color.lighten(0.1).hex());
  }

  private setupAnalytics(): void {
    if (this.platform.is('cordova')) {
      this.setupBranch(true);
      this.platform.resume.subscribe(e => {
        this.user.markCurrentlyActive();
        this.logging.logEventWithUser('resume', {}, this.user.currentIdentity);
        this.setupBranch(false);
      });

    }
  }

  private setupBackButton(): void {
    if (this.platform.is('android')) {
      this.platform.backButton.subscribe(v => this.location.back());
    }
  }

  private setupBranch(isAppLoad: boolean): void {
    BranchDeepLinks.addListener('init', (event: BranchInitEvent) => {
      console.log('Coming from Branch');
      // Retrieve deeplink keys from 'referringParams' and evaluate the values to determine where to route the user
      // Check '+clicked_branch_link' before deciding whether to use your Branch routing logic
      if (event.referringParams['+clicked_branch_link']) {
        parseString(event.referringParams['qsid'])
            .forEach(d => this.proposalService.downloadByQsid(d));
      } else if (event.referringParams['+non_branch_link']) {
        parseString(event.referringParams['qsid'])
            .forEach(d => this.proposalService.downloadByQsid(d));
      } else {
        this.navigator.gotoBestPage(isAppLoad);
      }
    });

    BranchDeepLinks.addListener('initError', (error: any) => {
      console.error('Branch Error');
      console.error(error);
    });

    SplashScreen.hide();
  }

  private setupColourScheme(): void {
    this.navigator.observeMeta()
        .subscribe(meta => {
          const palette = meta.getColourPalette(defaultPallette);
          this.setIonicVariables('primary', palette.primary);
          this.setIonicVariables('secondary', palette.secondary);
          this.setIonicVariables('tertiary', palette.tertiary);
          this.setIonicVariables('text', palette.text);
        });
  }

  private setupFirebase(): void {
    this.db.firestore.enablePersistence()
        .catch(err => {
        if (err.code === 'failed-precondition') {
          console.warn('Failed to enable persistence for firebase, multiple tabs open');
        } else if (err.code === 'unimplemented') {
          console.warn('Failed to enable persistence for firebase, unimplemented');
        } else {
          console.warn('Failed to enable persistence for firebase, Unknown error');
          console.error(err);
        }
      });
  }
}
