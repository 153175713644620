import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ContactModel} from '@didgigo/lib-ts';
import {List} from 'immutable';

@Component({
  selector: 'app-contact-panel',
  templateUrl: './contact-panel.component.html',
  styleUrls: ['./contact-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactPanelComponent implements OnInit {

  @Input()
  cards: List<ContactModel>;

  constructor() { }

  ngOnInit() {
  }


  trackByFn(index: number) {
      return index;
  }

}
