import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';

import {Plugins} from '@capacitor/core';
import {
  AgentDetails,
  AgentDetailsJsonSerializer,
  agentPrefix,
  convertCollectionToArray,
  convertMapToObj,
  Crud,
  guestPrefix,
  IdentifiablePerson,
  IdentifiablePersonJsonSerializer,
  modelDebounce,
  now,
  OptionUtils,
  parseJsonFromString,
  parseSetSerializable,
  parseString,
  Person,
  tenSecondUpdateTimer,
  triggerSingleEffect,
  twoSecondUpdateTimer,
} from '@didgigo/lib-ts';
import {Dialogs} from '@ionic-native/dialogs/ngx';
import {AlertController, ModalController, Platform} from '@ionic/angular';
import {AlertInput} from '@ionic/core';
import {None, Option, Some} from 'funfix-core';
import {List, Map, Set} from 'immutable';
import {Moment} from 'moment';
import {combineLatest, from, Observable, of} from 'rxjs';
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';
import {WhoAreYouModalComponent, WhoAreYouModalData} from '../components/who-are-you-modal/who-are-you-modal.component';
import {StringCacheProviderService} from './cache-provider.service';
import {ConfigurationService} from './configuration.service';
import {LoggingService} from './logging.service';
import {MediaService} from './media.service';
import {RestService} from './rest.service';

const { Device } = Plugins;

@Injectable({
  providedIn: 'root',
})
export class UserService {

  constructor(
      readonly cacheProvider: StringCacheProviderService,
      readonly platform: Platform,
      readonly dialogs: Dialogs,
      readonly rest: RestService,
      readonly alert: AlertController,
      readonly media: MediaService,
      readonly configuration: ConfigurationService,
      readonly logging: LoggingService,
      readonly db: AngularFirestore,
      readonly modals: ModalController) {
    this.currentIdentity = this.observeCurrentIdentity()
        .pipe(modelDebounce(twoSecondUpdateTimer))
        .pipe(tap((x: Option<IdentifiablePerson>) => x.forEach(v => this.logging.currentUser.next(v))));
  }

  cache: Crud<string, string> = this.cacheProvider.getPrefixedCache('identity');

  currentIdentity: Observable<Option<IdentifiablePerson>>;

  identities: Observable<Set<IdentifiablePerson>> =
      this.cache.observe('identities')
          .pipe(map(ids => ids.flatMap(id => parseJsonFromString(id))))
          .pipe(map(ids => ids.map(is =>
              parseSetSerializable(is, IdentifiablePersonJsonSerializer.instance)).getOrElse(Set())))
          .pipe(modelDebounce());

  info = Device.getInfo();

  addDidgigoAdmin(id: string): any {
    throw new Error('Method not implemented.');
  }

  addDidgigoAgent(id: string): Promise<Option<IdentifiablePerson>> {
    return from(this.rest.getJsonFrom(this.configuration.readApiServer + '/agent_details/brief/' + id))
      .pipe(map(obj => AgentDetailsJsonSerializer.instance.fromJson(obj)))
      .pipe(switchMap(p => from(this.createAndAddIdentityFromAgentDetails(p))))
      .pipe(triggerSingleEffect())
      .toPromise();
  }

  // Adds the identity to firebase and locally
  // Can set the currently enabled identity
  addIdentity(ident: IdentifiablePerson): Promise<Option<IdentifiablePerson>> {
    return this.identities
      .pipe(take(1))
      .pipe(switchMap((ids: Set<IdentifiablePerson>) => {
        // Skip attempt at adding a second guest account.
        if (ident.isGuest() && ids.some(i => i.isGuest())) {
          return ident.identity.map(i => this.setIdentity(i)).getOrElse(of(Option.empty()));
        }

        if (ident.isGuest()) {
          return from(this.addToIdentitiesList(ids, ident))
              .pipe(switchMap(_ => {
                return ident.identity.map(i => this.setIdentity(i)).getOrElse(of(Option.empty()));
              }));
        }
        return from(this.ensureIdentityExistsInFirebase(ident))
            .pipe(switchMap(_ => from(this.addToIdentitiesList(ids, ident))))
          .pipe(switchMap(_ => ident.identity.map(i => this.setIdentity(i)).getOrElse(of(Option.empty()))));
      }))
      .pipe(switchMap(_ => this.currentIdentity.pipe(take(1))))
      .toPromise();
  }

  private addToIdentitiesList(ids: Set<IdentifiablePerson>, ident: IdentifiablePerson): Promise<Option<string>> {
    return this.cache.set(
        'identities',
        JSON.stringify(convertCollectionToArray(ids.add(ident), IdentifiablePersonJsonSerializer.instance)));
  }

  private async computeUUID(): Promise<string> {
    const i = await this.info;
    return this.platform.is('cordova') ? i.uuid : 'TME' + now().unix().toString();
  }

  async createAndAddIdentityFromAgentDetails(details: Option<AgentDetails>): Promise<Option<IdentifiablePerson>> {
    const newIdent = await this.createIdentityFromAgentDetails(details, true);
    return this.addIdentity(newIdent);
  }

  async createAndAddIdentityFromPerson(person: Person, prefix: string): Promise<Option<IdentifiablePerson>> {
    const newIdent = await this.createIdentityFromPerson(person, prefix, true);
    return this.addIdentity(newIdent);
  }

  createGuestIdentityIfNotLoggedIn(): Observable<Option<IdentifiablePerson>> {
    return this.currentIdentity
      .pipe(take(1))
      .pipe(switchMap(op => {
        if (op.isEmpty()) {
          return from(this.createIdentity('Anonymous', guestPrefix));
        } else {
          return of(op);
        }
      }))
      .pipe(triggerSingleEffect())
      .pipe(modelDebounce());
  }

  createIdentity(name: string, prefix: string): Promise<Option<IdentifiablePerson>> {
    return this.createAndAddIdentityFromPerson(new Person(None, None, Some(name)), prefix);
  }

  async createIdentityFromAgentDetails(details: Option<AgentDetails>, markActive: boolean): Promise<IdentifiablePerson> {
    const uuid = await this.computeUUID();
    const agent = details.flatMap(d => d.agent);
    const personId =
      agent.flatMap(a => a.id.map(i => agentPrefix + i))
        .getOrElse(agentPrefix + uuid);

    return new IdentifiablePerson(
      Some(personId),
      List.of(uuid),
      markActive ? Map({ tripigo: now() }) : Map(),
      agent.flatMap(a => a.image).map(i => this.media.getAgentImage(i)),
        details.flatMap(d => d.company)
            .flatMap(c => c.getLogo())
            .flatMap(c => c.getHref())
            .map(i => this.media.getProposalCompanyLogo(i)),
        details.flatMap(d => d.getCompany()),
      details.flatMap(d => d.agent));
  }

  async createIdentityFromPerson(person: Person, prefix: string, markActive: boolean): Promise<IdentifiablePerson> {
    const uuid = await this.computeUUID();
    const personId = person.id.map(i => prefix + i).getOrElse(prefix + uuid);
    return new IdentifiablePerson(
      Some(personId),
      List.of(uuid),
      markActive ? Map({ tripigo: now() }) : Map(),
      None,
      None,
      None,
      Some(person));
  }

  private async createIdentityInFirebase(userTable, ip: IdentifiablePerson): Promise<Option<IdentifiablePerson>> {
    await userTable.set(IdentifiablePersonJsonSerializer.instance.toJson(ip));
    return Some(ip);
  }

  async ensureAgentHasAccount(details: Option<AgentDetails>): Promise<Option<IdentifiablePerson>> {
      const ip = await this.createIdentityFromAgentDetails(details, false);
      return this.ensureIdentityExistsInFirebase(ip);
  }

  ensureIdentityExistsInFirebase(ip: IdentifiablePerson): Promise<Option<IdentifiablePerson>> {
    const peopleObs: Observable<Option<IdentifiablePerson>> = ip.identity.map(identity => {
      const users = this.db.collection(this.configuration.getFirestoreUsersCollectionId()).doc(identity);

      return users.get()
        .pipe(switchMap(doc => {
          if (doc.exists) {
            return of(Option.of(ip));
          } else {
            return this.createIdentityInFirebase(users, ip);
          }
        }));
    })
      .getOrElse(of(Option.empty()));

    return peopleObs
      .pipe(catchError(err => {
        console.error(err);
        return of(Option.empty());
      }))
      .pipe(triggerSingleEffect<Option<IdentifiablePerson>>())
      .toPromise();
  }

  getOrAskForIdentity(potentials: List<Person>): Observable<Option<IdentifiablePerson>> {
    return this.currentIdentity
      .pipe(take(1))
      .pipe(switchMap(ip => {
        if (ip.isEmpty() || ip.exists(i => i.isGuest())) {
          return this.showIdentityDialog(potentials);
        } else {
          return of(ip);
        }
      }));
  }

  hasCurrentIdentity(): Promise<boolean> {
    return this.cache.hasKey('current_identity');
  }

  makeGuestActive(): Observable<Option<string>> {
    return this.identities
      .pipe(take(1))
      .pipe(switchMap(ids =>
        Option.of(ids.find(ip => ip.isGuest()))
          .flatMap(id => id.identity)
          .map(id => this.setIdentity(id))
          .getOrElse(of(None))));
  }

  markCurrentlyActive(): void {
    return this.updateCurrentIdentity(ip => {
      // cap updates as once every minute
      const n = now();
      const lastAppActivity = ip.last_active.get('tripigo');
      if (Option.of(lastAppActivity).exists(m => m.diff(n, 'minute') === 0)) {
        return {};
      }

      return {
        last_active: convertMapToObj(ip.last_active.asImmutable().set('tripigo', n), m => m.toISOString()),
      };
    });
  }

  private observeCurrentIdentity(): Observable<Option<IdentifiablePerson>> {
    return combineLatest(this.identities, this.cache.observe('current_identity'))
      .pipe(map(([ids, idString]) =>
        idString
          .flatMap(id => Option.of(ids.find(identity => identity.identity.contains(id))))))
      .pipe(modelDebounce());
  }

  observeLastActivity(identifier: string): Observable<Option<Moment>> {
    return this.observeUser(identifier)
      .pipe(map(ido => ido.flatMap(id => id.getLastActiveTime())));
  }

  observeUser(identifier: string): Observable<Option<IdentifiablePerson>> {
    return this.db.collection(this.configuration.getFirestoreUsersCollectionId()).doc(identifier)
      .valueChanges()
      .pipe(map(v => IdentifiablePersonJsonSerializer.instance.fromJson(v)))
      .pipe(modelDebounce(tenSecondUpdateTimer));
  }

  observeUsers(identifiers: Set<string>): Observable<Set<IdentifiablePerson>> {
    if (identifiers.isEmpty()) {
      return of(Set());
    }

    return combineLatest(...identifiers.toArray().map(id => this.observeUser(id)))
      .pipe(map((arr: Array<Option<IdentifiablePerson>>) => OptionUtils.toSet(...arr)))
      .pipe(modelDebounce());
  }

  setIdentity(identity: string): Promise<Option<string>> {
    return this.cache.set('current_identity', identity);
  }

  async showIdentityDialog(potentials: List<Person>): Promise<Option<IdentifiablePerson>> {
    const modal = await this.modals.create({
      component: WhoAreYouModalComponent,
      componentProps: { passengers: potentials },
      animated: true,
      showBackdrop: false,
    });
    await modal.present();
    const res = await modal.onDidDismiss();
    const data = res.data as WhoAreYouModalData;
    return this.createAndAddIdentityFromPerson(data.person, data.prefix);
  }

  switchUser(): Observable<void> {
    return this.identities
      .pipe(map(ids =>
        ids.filter(id => !id.isGuest())
          .map<AlertInput>(i => {
            return {
              type: 'radio',
              cssClass: 'primaryToDark',
              label: i.getFullNameAndType(),
              value: i.identity.getOrElse(''),
            };
          })))
      .pipe(switchMap(ids => from(this.alert.create({
        header: 'Select User',
        inputs: ids.toArray(),
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'primaryToDark',
            handler: () => {
            },
          },
          {
            text: 'Ok',
            cssClass: 'primaryToDark',
            handler: data => {
              parseString(data).filter(s => s.trim().length !== 0)
                .forEach(id => this.setIdentity(id));
            },
          },
        ],
      }))))
      .pipe(switchMap(x => from(x.present())))
      .pipe(triggerSingleEffect());
  }

  private updateCurrentIdentity(data: (ip: IdentifiablePerson) => object): void {
    this.currentIdentity
      .pipe(map(ident =>
        ident.filter(id => !id.isGuest())
          .flatMap(id => id.identity)
          .forEach(id =>
            this.db.collection(this.configuration.getFirestoreUsersCollectionId())
              .doc(id)
              .update(data(ident.get())))))
      .pipe(triggerSingleEffect());
  }
}
