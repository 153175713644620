import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { EntryCardSmallComponent } from './entry-card-small.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule.forRoot(),
  ],
  declarations: [EntryCardSmallComponent],
  exports: [EntryCardSmallComponent],
})
export class EntryCardSmallComponentModule { }
