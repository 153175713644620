import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FloatingFooterComponent } from './floating-footer.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule.forRoot(),
  ],
  declarations: [FloatingFooterComponent],
  exports: [FloatingFooterComponent],
})
export class FloatingFooterComponentModule { }
