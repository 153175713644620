import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SmartTextAreaComponentModule} from '@didgigo/lib-angular';
import {IonicModule} from '@ionic/angular';
import {EntryCardMediumComponentModule} from '../entry-card-medium/entry-card-medium.component.module';
import {FloatingCardComponentModule} from '../floating-card/floating-card.component.module';
import {HeaderSpacerComponentModule} from '../header-spacer/header-spacer.component.module';
import {ImagePreloaderComponentModule} from '../image-preloader/image-preloader.component.module';
import {MapMarkerModalComponent} from './map-marker-modal.component';

@NgModule({
    imports: [
        CommonModule,
        HeaderSpacerComponentModule,
        ImagePreloaderComponentModule,
        FloatingCardComponentModule,
        IonicModule.forRoot(),
        SmartTextAreaComponentModule,
        EntryCardMediumComponentModule,
    ],
    declarations: [MapMarkerModalComponent],
    exports: [MapMarkerModalComponent],
    entryComponents: [MapMarkerModalComponent],
})
export class MapMarkerModalComponentModule {
}
