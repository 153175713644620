import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Capacitor} from '@capacitor/core';
import {ContextualImageSet, DownloadType} from '@didgigo/lib-ts';
import {File} from '@ionic-native/file/ngx';
import {Platform} from '@ionic/angular';
import {List, Set} from 'immutable';
import {BehaviorSubject} from 'rxjs';
import {BaseComponent} from '../../lib-ionic/base-component';
import {LoadingMonitorService} from '../../services/loading-monitor.service';

export class ImagePreloaderState {
  constructor(
      readonly images: List<SafeUrl>,
      readonly shown: number,
      readonly loaded: Set<SafeUrl>) {
  }

  public equals(obj: ImagePreloaderState): boolean {
    return obj.images === this.images
        && obj.shown === this.shown
        && obj.loaded === this.loaded;
  }

  isLoaded(idx: number): boolean {
    return this.loaded.contains(idx);
  }

  shouldHide(idx: number): boolean {
    return !this.isLoaded(idx);
  }
}

@Component({
  selector: 'app-image-preloader',
  templateUrl: './image-preloader.component.html',
  styleUrls: ['./image-preloader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagePreloaderComponent extends BaseComponent implements OnInit {

  constructor(
      readonly loading: LoadingMonitorService,
      readonly change: ChangeDetectorRef,
      readonly file: File,
      readonly sanitizer: DomSanitizer,
      readonly platform: Platform,
      readonly self: ElementRef) {
    super('image_preloader', change, self, loading, false);
  }

  @Input()
  cover: boolean;

  /**
   * Tl;dr, optimal = include high res
   * others will use
   */
  @Input()
  viewType: DownloadType;

  failed = Set();

  @Input()
  fallbackIcon = 'missing-image';

  @Input()
  images: ContextualImageSet;

  localizeImages: List<SafeUrl> = List();

  state: BehaviorSubject<ImagePreloaderState> = new BehaviorSubject(new ImagePreloaderState(this.localizeImages, -1, Set()));

  @Input()
  useStored = true;

  errored(i: SafeUrl, idx: number): void {
    this.failed = this.failed.add(idx);
    const state = this.state.getValue();
    if (idx > state.shown) {
      this.state.next(
          new ImagePreloaderState(this.localizeImages, state.shown, state.loaded));
    } else if (!this.localizeImages.get(idx + 1) && state.shown === -1) {
      this.state.next(
          new ImagePreloaderState(this.localizeImages, -2, state.loaded));
    }
  }

  getLocalizedImages(): List<SafeUrl> {
    if (this.images === undefined || this.images === null) {
      return List();
    }

    return this.images.getImagesUnderMB(this.platform.is('cordova') && this.useStored)
        .map(im => this.localizeImage(im));
  }

  loaded(i: SafeUrl, idx: number): void {
    const state = this.state.getValue();
    if (idx > state.shown && !this.failed.contains(idx)) {
      this.state.next(
          new ImagePreloaderState(
              this.localizeImages,
              idx, state.loaded.add(i)));
    }
  }

  trackByFn(index: number) {
    return index;
  }

  localizeImage(url: string): SafeUrl {
    if (Capacitor.isNative) {
      return this.sanitizer.bypassSecurityTrustUrl(Capacitor.convertFileSrc(url));
    } else {
      return this.sanitizer.bypassSecurityTrustUrl(url);
    }
  }

  ngOnInit(): void {
    this.localizeImages = this.getLocalizedImages();
    if (this.localizeImages.isEmpty()) {
      this.state.next(new ImagePreloaderState(this.localizeImages, -2, Set()));
    } else {
      this.state.next(new ImagePreloaderState(this.localizeImages, -1, Set()));
    }

    super.ngOnInit();
  }
}
