import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {BaseComponent} from '../../lib-ionic/base-component';
import {AssetService} from '../../services/asset.service';
import {ChatService} from '../../services/chat.service';
import {LoadingMonitorService} from '../../services/loading-monitor.service';
import {MenuService} from '../../services/menu.service';
import {NavigatorService} from '../../services/navigator.service';
import {ThemeService} from '../../services/theme.service';

@Component({
  selector: 'app-floating-header',
  templateUrl: './floating-header.component.html',
  styleUrls: ['./floating-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatingHeaderComponent extends BaseComponent {

  @Input()
  title: string;

  count: Observable<number>;

  constructor(
    readonly navigator: NavigatorService,
    readonly menu: MenuService,
    readonly loading: LoadingMonitorService,
    readonly assets: AssetService,
    readonly chat: ChatService,
    readonly change: ChangeDetectorRef,
    readonly self: ElementRef,
    readonly theme: ThemeService) {
    super('floating_header', change, self, loading, false);
    this.count =
        chat.observeNewMessageCount()
            .pipe(shareReplay(1));
  }

  toggleMenu() {
    this.menu.toggle();
  }
}
