import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ContextualImageSet, modelThrottle, StringUtils} from '@didgigo/lib-ts';
import {BaseComponent} from '../../lib-ionic/base-component';
import {AssetService} from '../../services/asset.service';
import {BrowserService} from '../../services/browser.service';
import {ConfigurationService} from '../../services/configuration.service';
import {LoadingMonitorService} from '../../services/loading-monitor.service';
import {LoggingService} from '../../services/logging.service';
import {NavigatorService} from '../../services/navigator.service';
import {ProposalService} from '../../services/proposal.service';

const tripigoText =
    StringUtils.stripMargin(`Born on the Coral Cays of the Great Barrier Reef where the turquoise waters from
the surrounding reefs lap over the golden sands, tripigo is your ideal travel companion.
                |
                |Tripigo has been made by a team who love to travel. `);

@Component({
  selector: 'app-tripigo',
  templateUrl: './tripigo.page.html',
  styleUrls: ['./tripigo.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripigoPage extends BaseComponent implements OnInit {

  constructor(
      private route: ActivatedRoute,
      readonly navigator: NavigatorService,
      readonly assets: AssetService,
      readonly logger: LoggingService,
      readonly browser: BrowserService,
      readonly configuration: ConfigurationService,
      readonly change: ChangeDetectorRef,
      readonly self: ElementRef,
      readonly loading: LoadingMonitorService,
      readonly proposalService: ProposalService) {
    super('tripigo_page', change, self, loading);
  }

  getImages(): ContextualImageSet {
    return this.assets.getBlueBackground();
  }

  getText(): string {
    return tripigoText;
  }

  gotoDidgigoSite(): void {
    this.browser.browseTo('https://didgigo.com');
  }

  gotoTripigoSite(): void {
    this.browser.browseTo('http://tripigo.com.au');
  }

  ngOnInit(): void {
    const f = () => super.ngOnInit();

    this.logger.setPage('tripigo');
    this.route.params
      .pipe(modelThrottle(this.unsubscriberObs))
        .subscribe(ps => {
        this.logger.logEvent(
          'load_page',
          { page: 'tripigo' });
        f();
      });
  }
}
