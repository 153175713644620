import {Injectable} from '@angular/core';
import {now, OptionUtils} from '@didgigo/lib-ts';
import {None, Option, Some} from 'funfix-core';
import {Moment} from 'moment';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {LoggingService} from './logging.service';
import {UserService} from './user.service';

export class LoadingState {
  constructor(
    readonly page: string,
    readonly prepare: Option<Moment> = None,
    readonly startNavigation: Option<Moment> = None,
    readonly endNavigation: Option<Moment> = None,
    readonly initPage: Option<Moment> = None,
    readonly afterView: Option<Moment> = None,
    readonly afterContent: Option<Moment> = None,
  ) { }

  hasJustBeenPrepared(): boolean {
    return this.prepare.nonEmpty() &&
      this.startNavigation.isEmpty() &&
      this.endNavigation.isEmpty() &&
      this.initPage.isEmpty() &&
      this.afterView.isEmpty() &&
      this.afterContent.isEmpty();
  }

  // We can assume everything is correct if the view was loaded
  hasLoaded(): this is Option<Moment> {
    return this.afterView.nonEmpty();
  }

  /**
   * Not every page will call afterContent
   */
  isLoading(): boolean {
    const size = OptionUtils.toList(this.initPage, this.afterView).size;
    return size === 1;
  }

  isNavigating(): boolean {
    const size = OptionUtils.toList(this.startNavigation, this.endNavigation).size;
    return size === 1;
  }
}

@Injectable({
  providedIn: 'root',
})
export class LoadingMonitorService {

  constructor(
    readonly logging: LoggingService,
    readonly user: UserService,
  ) {
  }

  last: BehaviorSubject<LoadingState> = new BehaviorSubject<LoadingState>(new LoadingState('none'));

  afterContentInit(page: string): void {
    this.ensureSameRoute(page);
    const value = this.last.getValue();
    this.last.next(
      new LoadingState(
        value.page,
        value.prepare,
        value.startNavigation,
        value.endNavigation,
        value.initPage,
        value.afterView,
        Some(now())));
  }

  /**
   * It is perfectly okay if this page has not been navigate to. It could be the first page loaded in the app
   */
  afterInitPage(page: string): void {
    if (this.last.getValue().page !== page) {
      this.prepareRoute(page);
    }
    const value = this.last.getValue();
    this.last.next(
      new LoadingState(
        value.page,
        value.prepare,
        value.startNavigation,
        value.endNavigation,
        Some(now()),
        value.afterView,
        value.afterContent,
      ));
    value.prepare.map(t => this.logging.logPerformance('page_load_init', page, t));
  }

  afterViewInit(page: string): void {
    this.ensureSameRoute(page);
    const value = this.last.getValue();
    this.last.next(
      new LoadingState(
        value.page,
        value.prepare,
        value.startNavigation,
        value.endNavigation,
        value.initPage,
        Some(now()),
        value.afterContent));
    value.prepare.map(t => this.logging.logPerformance('page_load_view', page, t));
  }

  canNavigate(): boolean {
    return !this.last.getValue().isNavigating();
  }

  private checkLastRouteCompleted(page: string): void {
    const value = this.last.getValue();
    if (value.isNavigating() && page !== value.page) {
      console.warn(`Already navigating to ${value.page}, will navigate to ${page}`);
    }

    if (value.isLoading() && page !== value.page) {
      console.warn(`Switching routes mid load of ${value.page}, will navigate to ${page}`);
    }
  }

  endNavigation(page: string): void {
    this.ensureSameRoute(page);
    const value = this.last.getValue();
    this.last.next(
      new LoadingState(
        value.page,
        value.prepare,
        value.startNavigation,
        Some(now()),
        value.initPage,
        value.afterView,
        value.afterContent,
      ));
    value.prepare.map(t => this.logging.logPerformance('page_load_navigate', page, t));
  }

  /**
   * Ensure current page is correct.
   */
  private ensureSameRoute(page: string): void {
    if (this.last.getValue().page !== page || this.last.getValue().hasLoaded()) {
      console.warn(`${page} is not ${this.last.getValue().page}, changing page`);
      this.prepareRoute(page);
    }
  }

  /**
   * Allow navigation if we are not in the middle of a route change.
   */
  observeCanNavigate(): Observable<boolean> {
    return this.last.asObservable().pipe(map(x => !x.isNavigating()));
  }

  private prepareRoute(page: string): void {
    this.checkLastRouteCompleted(page);
    this.last.next(new LoadingState(page, Some(now())));
  }

  /**
   * A number of routes do not have a prepare stage, we just run the prepare step here.
   */
  startNavigation(page: string): void {
    this.user.markCurrentlyActive();

    if (this.last.getValue().page !== page || !this.last.getValue().hasJustBeenPrepared()) {
      this.prepareRoute(page);
    }
    const value = this.last.getValue();
    this.last.next(
      new LoadingState(
        value.page,
        value.prepare,
        Some(now()),
        value.endNavigation,
        value.initPage,
        value.afterView,
        value.afterContent));
    value.prepare.map(t => this.logging.logPerformance('page_load_prepare', page, t));
  }
}
