import {Injectable} from '@angular/core';
import {ContextualImageSet} from '@didgigo/lib-ts';

@Injectable({
  providedIn: 'root',
})
export class AssetService {

  constructor() {
  }

  getBackground(): ContextualImageSet {
    return this.getSvgPathAsCtxImage('tripigo-background');
  }

  getBlueBackground(): ContextualImageSet {
    return this.getSvgPathAsCtxImage('tripigo-background-blue');
  }

  getDirectionsIconPath(manuever: string): string {
    return `assets/png/directions/direction_${manuever.replace(new RegExp('-', 'g'), '_')}.png`;
  }

  getIconPath(name: string): string {
    return `assets/svg/tripigo-icons/${name}.svg`;
  }

  getIoniconPath(name: string): string {
    return `assets/svg/ionicons/${name}.svg`;
  }

  getMarkerSvgPath(name: string): string {
    return `assets/svg/tripigo-icons/marker-${name}.svg`;
  }

  getSubproductMarkerSvgPath(name: string): string {
    return `assets/svg/tripigo-icons/marker-short-${name}.svg`;
  }

  getSvgPath(name: string): string {
    return `assets/svg/${name}.svg`;
  }

  getSvgPathAsCtxImage(name: string): ContextualImageSet {
    return ContextualImageSet.buildFromStaticUri(this.getSvgPath(name));
  }

  getTripigoLogo(): ContextualImageSet {
    return this.getSvgPathAsCtxImage('tripigo-butterfly');
  }

  getTripigoSvgLogo(): string {
    return this.getSvgPath('tripigo-butterfly');
  }
}
