import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class SizingService {

  constructor(
    readonly platform : Platform,
  ) { }

  getVh(percent : number) : number {
    return this.platform.height() * percent / 100;
  }

  getVw(percent : number) : number {
    return this.platform.width() * percent / 100;
  }
}
