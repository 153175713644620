import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Plugins } from '@capacitor/core';

const { Network } = Plugins;


@Injectable({
  providedIn: 'root',
})
export class NetworkService {

  constructor(
    readonly db: AngularFirestore,
  ) { }

  setupNetworkHandlers() {
      Network.addListener('networkStatusChange', (status) => {
        if (status.connected) {
            this.db.firestore.enableNetwork()
        } else {
            this.db.firestore.disableNetwork()
        }
      });
  }
}
