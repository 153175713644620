import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ContactModel, ContextualImageSet, Image, OptionUtils, parseNumber, ProposalEntry, ProposalEntryWithMeta} from '@didgigo/lib-ts';
import {IonContent} from '@ionic/angular';
import {None, Option} from 'funfix-core';
import {List} from 'immutable';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {SlideshowBackgroundComponent} from '../../components/slideshow-background/slideshow-background.component';
import {BaseComponent} from '../../lib-ionic/base-component';
import {AssetService} from '../../services/asset.service';
import {BrowserService} from '../../services/browser.service';
import {ConfigurationService} from '../../services/configuration.service';
import {LoadingMonitorService} from '../../services/loading-monitor.service';
import {LoggingService} from '../../services/logging.service';
import {MediaService} from '../../services/media.service';
import {NavigatorService} from '../../services/navigator.service';
import {UserSettingsService} from '../../services/user-settings.service';
import {UserService} from '../../services/user.service';

@Component({
    selector: 'app-entry',
    templateUrl: './entry.page.html',
    styleUrls: ['./entry.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntryPage extends BaseComponent implements OnInit, AfterViewInit {

    constructor(
        private route: ActivatedRoute,
        readonly browser: BrowserService,
        private logger: LoggingService,
        readonly navigator: NavigatorService,
        readonly configuration: ConfigurationService,
        readonly loading: LoadingMonitorService,
        readonly settings: UserSettingsService,
        readonly media: MediaService,
        readonly user: UserService,
        readonly assets: AssetService,
        readonly change: ChangeDetectorRef,
        readonly self: ElementRef) {
        super('entry_page', change, self, loading);
        this.settings.isMetric().then(x => this.isMetric = x);
    }

    contactCards: List<ContactModel>;

    @ViewChild('entryScroll', {static: false})

    content: IonContent;

    isMetric = true;

    model: ProposalEntryWithMeta;

    productId: Option<number> = None;

    shownPage: BehaviorSubject<string> = new BehaviorSubject('info');

    @ViewChild(SlideshowBackgroundComponent, {static: false})

    slides: SlideshowBackgroundComponent;

    canShowContact(cards: List<ContactModel>): boolean {
        return !cards.isEmpty();
    }

    canShowEfactchure(entry: ProposalEntry): boolean {
        if (entry === undefined) {
            return false;
        }
        return this.getEfact(entry).nonEmpty();
    }

    canShowLocationPage(m: ProposalEntryWithMeta): boolean {
        if (m === undefined) {
            return false;
        }

        return m.isProposalEntryMapVisible()
            || m.isProposalEntryLocationVisible()
            || m.isProposalEntryDirectionsVisible();
    }

    getEfact(entry: ProposalEntry): Option<string> {
        if (entry === undefined || entry === null) {
            return None;
        }
        return entry.product.flatMap(x => x.getQuickstartId()).map(x => `http://epresentations.didgigo.com/${x}`);
    }

    getImages(entry: ProposalEntryWithMeta): List<ContextualImageSet> {
        if (entry === undefined) {
            return List();
        }

        const entryImages = entry.getEntry().getBestImages(10)
            .map(i => this.media.getProductImage(i));
        if (!entryImages.isEmpty()) {
            return entryImages;
        }
        return OptionUtils.toList(entry.getProposalWithMeta()
            .getDisplayWelcomeImage())
            .map((i: Image) => this.media.getWelcomeImage(i));
    }

    hasNextEntry(): boolean {
        if (this.productId.nonEmpty()) {
           return this.model.getNextSubproductAsEntry(this.productId.get()).nonEmpty();
        }
        return this.model.getProposalWithMeta().getNextEntry(this.model.getId().get()).flatMap(v => v.getId()).nonEmpty();
    }

    hasPrevEntry(): boolean {
        if (this.productId.nonEmpty()) {
            return this.model.getPrevSubproductAsEntry(this.productId.get()).nonEmpty();
        }
        return this.model.getProposalWithMeta().getPrevEntry(this.model.getId().get()).flatMap(v => v.getId()).nonEmpty();
    }

    isShown(page: string): Observable<boolean> {
        return this.shownPage
            .pipe(map(p => p === page))
            .pipe(shareReplay(1));
    }

    nextEntry(): void {
        if (this.productId.nonEmpty()) {
            const pid = this.model.getNextSubproductAsEntry(this.productId.get());
            pid.forEach(i =>
                this.navigator.gotoSubproduct(
                    this.model.getProposalWithMeta().getProposalId().get(),
                    this.model.getId().get(),
                    i.getProductId().get()));
        }
        const id = this.model.getProposalWithMeta().getNextEntry(this.model.getId().get()).flatMap(v => v.getId());
        id.forEach(i => this.navigator.gotoEntry(this.model.getProposalWithMeta().getProposalId().get(), i));
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        this.shownPage.subscribe(x => this.content !== undefined ? this.content.scrollToTop(30) : {});
    }

    async ngOnInit(): Promise<void> {
        this.logger.setPage('entry');
        const ps = this.route.snapshot.params;
        this.navigator.setProposalId(ps.id);
        this.navigator.setEntryId(ps.eid);
        this.productId = parseNumber(ps.pid);
        this.logger.logEventWithProposalAndUser('load_page', {page: 'entry'});
        const entry = await this.navigator.getCurrentEntryForIdNonOptional(+ps.id, +ps.eid);
        const model = this.productId.isEmpty() ? entry : entry.getSubproductAsEntry(this.productId.get()).getOrElse(entry);
        this.model = model;
        this.contactCards = model.getDisplayContactCards();
        super.ngOnInit();
    }

    prevEntry(): void {
        const model = this.model;
        if (this.productId.nonEmpty()) {
            const pid = model.getPrevSubproductAsEntry(this.productId.get());
            pid.forEach(i =>
                this.navigator.gotoSubproduct(
                    model.getProposalWithMeta().getProposalId().get(),
                    model.getId().get(),
                    i.getProductId().get()));
        }
        const id = model.getProposalWithMeta().getPrevEntry(model.getId().get()).flatMap(v => v.getId());
        id.forEach(i => this.navigator.gotoEntry(model.getProposalWithMeta().getProposalId().get(), i));
    }

    showPage(page: string): void {
        this.shownPage.next(page);
    }
}
