import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ContextualImageSet, modelDebounce, secondUpdateTimer, untilNever} from '@didgigo/lib-ts';
import {IonSlides} from '@ionic/angular';
import {List} from 'immutable';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {delay, filter, tap} from 'rxjs/operators';
import {BaseComponent} from '../../lib-ionic/base-component';
import {AssetService} from '../../services/asset.service';
import {LoadingMonitorService} from '../../services/loading-monitor.service';

@Component({
    selector: 'app-slideshow-background',
    templateUrl: './slideshow-background.component.html',
    styleUrls: ['./slideshow-background.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideshowBackgroundComponent extends BaseComponent implements OnInit {

    constructor(
        readonly loadingMonitor: LoadingMonitorService,
        readonly assets: AssetService,
        readonly self: ElementRef,
        readonly change: ChangeDetectorRef) {
        super('slideshow', change, self, loadingMonitor, false);
    }

    images: BehaviorSubject<List<ContextualImageSet>> = new BehaviorSubject(List());

    @Input()
    imageSet: List<ContextualImageSet>;

    imagesObs: Observable<List<ContextualImageSet>> =
        this.images.asObservable()
            .pipe(modelDebounce(untilNever, _ => secondUpdateTimer))
            .pipe(tap(x => this.updateSlider()));

    slideOpts = {
        effect: 'fade',
        allowSlidePrev: true,
        allowSlideNext: true,
        direction: 'horizontal',
        autoplay: true,
        speed: 3000,
        on: {
            beforeInit() {
                const swiper = this;
                swiper.classNames.push(`${swiper.params.containerModifierClass}fade`);
                const overwriteParams = {
                    slidesPerView: 1,
                    slidesPerColumn: 1,
                    slidesPerGroup: 1,
                    watchSlidesProgress: true,
                    spaceBetween: 0,
                    virtualTranslate: true,
                };
                swiper.params = {...swiper.params, ...overwriteParams};
                swiper.params = {...swiper.originalParams, ...overwriteParams};
            },
            setTranslate() {
                const swiper = this;
                const {slides} = swiper;
                for (let i = 0; i < slides.length; i += 1) {
                    const $slideEl = swiper.slides.eq(i);
                    const offset$$1 = $slideEl[0].swiperSlideOffset;
                    let tx = -offset$$1;
                    if (!swiper.params.virtualTranslate) {
                        tx -= swiper.translate;
                    }
                    let ty = 0;
                    if (!swiper.isHorizontal()) {
                        ty = tx;
                        tx = 0;
                    }
                    const slideOpacity = swiper.params.fadeEffect.crossFade
                        ? Math.max(1 - Math.abs($slideEl[0].progress), 0)
                        : 1 + Math.min(Math.max($slideEl[0].progress, -1), 0);
                    $slideEl
                        .css({
                            opacity: slideOpacity,
                        })
                        .transform(`translate3d(${tx}px, ${ty}px, 0px)`);
                }
            },
            setTransition(duration) {
                const swiper = this;
                const {slides, $wrapperEl} = swiper;
                slides.transition(duration);
                if (swiper.params.virtualTranslate && duration !== 0) {
                    let eventTriggered = false;
                    slides.transitionEnd(() => {
                        if (eventTriggered) {
                            return;
                        }
                        if (swiper === undefined || swiper === null || swiper.destroyed) {
                            return;
                        }
                        eventTriggered = true;
                        swiper.animating = false;
                        const triggerEvents = ['webkitTransitionEnd', 'transitionend'];
                        for (let i = 0; i < triggerEvents.length; i += 1) {
                            $wrapperEl.trigger(triggerEvents[i]);
                        }
                    });
                }
            },
        },
    };

    @ViewChild(IonSlides, {static: false})
    slides: IonSlides;

    addImages(images: List<ContextualImageSet>): void {
        const value = this.images.getValue();

        const newImages = value.merge(images).filter(l => !l.isEmpty() && this.assets.getBlueBackground() !== l);
        this.images.next(newImages.takeLast(10));
    }

    next(stopAutoPlay: boolean = true): void {
        this.slides.stopAutoplay()
            .then(s => this.slides.slideNext(1000)
                .then(_ => {
                    if (!stopAutoPlay) {
                        this.slides.startAutoplay();
                    }
                }));
    }

    ngOnInit(): void {
        if (this.imageSet !== undefined) {
            this.images.next(this.imageSet);
        }

        super.ngOnInit();
    }

    prev(stopAutoPlay: boolean = true): void {
        this.slides.stopAutoplay()
            .then(s => this.slides.slidePrev(1000)
                .then(_ => {
                    if (!stopAutoPlay) {
                        this.slides.startAutoplay();
                    }
                }));
    }

    replaceImages(images: List<ContextualImageSet>): void {
        if (images.isEmpty()) {
            console.warn('Slideshow background empty. falling back to background');
            this.images.next(List.of(this.assets.getBlueBackground()));
        } else {
            this.images.next(images.filter(l => !l.isEmpty()));
        }
    }

    private updateSlider() {
        of('')
            .pipe(delay(50))
            .pipe(filter(x => this.slides !== undefined))
            .subscribe(_ => this.slides.update().then(() => {
                this.change.detectChanges();
                this.slides.startAutoplay();
            }));
    }
}
