import {Injectable} from '@angular/core';
import {parseListSerializable, ProposalJsonSerializer, proposalsKey, ProposalWithMeta} from '@didgigo/lib-ts';
import {None, Some} from 'funfix-core';
import {List} from 'immutable';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConfigurationService} from './configuration.service';
import {RestService} from './rest.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyProposalsService {

  constructor(
      readonly configuration: ConfigurationService,
      readonly rest: RestService,
  ) { }

  getProposals(agentId: string): Observable<List<ProposalWithMeta>> {
    return from(this.rest.getJsonFrom(`${this.configuration.readApiServer}/proposals/all/${agentId}`))
        .pipe(map(obj => parseListSerializable(obj[proposalsKey], ProposalJsonSerializer.instance)))
        .pipe(map(x => x.map(p => new ProposalWithMeta(Some(p), None))));
  }
}
