import {AfterContentInit, AfterViewInit, ChangeDetectorRef, ElementRef, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {LoadingMonitorService} from '../services/loading-monitor.service';

export class BaseComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit, OnChanges {
  unsubscriber = new Subject();
  unsubscriberObs = this.unsubscriber.asObservable();

  checkCount = 0;

  constructor(
      readonly name: string,
      readonly change: ChangeDetectorRef,
    readonly self: ElementRef,
    readonly loadingMonitor: LoadingMonitorService,
    readonly isPage: boolean = true) { }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  ngOnInit(): void {
    if (this.isPage) {
      this.loadingMonitor.afterInitPage(this.name);
    }
    this.change.detectChanges();
  }

  ngAfterContentInit(): void {
    if (this.isPage) {
      this.loadingMonitor.afterContentInit(this.name);
    }
  }

  ngAfterViewInit(): void {
    if (this.isPage) {
      this.loadingMonitor.afterViewInit(this.name);
      this.change.detectChanges();
      this.self.nativeElement.focus();
    }
  }

  ngOnChanges(): void {
    this.checkCount += 1;
  }
}
