import { Component, OnInit } from '@angular/core';
import { openCloseAnimation } from '../../animations/open-close';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    animations: [openCloseAnimation],

})
export class HeaderComponent implements OnInit {

    constructor() {
    }

    public isCollapsed = false;

    ngOnInit(): void {
    }

    toggleCollapse(): void {
        this.isCollapsed = !this.isCollapsed;
    }

}
