import { ErrorHandler, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class TripigoErrorHandler implements ErrorHandler {

  constructor(
    readonly platform: Platform) {
  }

  handleError(error: any): void {
    if (error instanceof DOMException) {
      const exception = error as DOMException;
      this.publishError('DOM Exception' +
        '\n' + exception.name +
        '\n' + exception.code +
        '\n' + exception.message);
    } else {
      this.publishError(error);
    }
  }

  publishError(error: any) {
    // TODO: Add firebase logging
    console.error(error);
  }
}
