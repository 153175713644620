import {Pipe, PipeTransform} from '@angular/core';

/**
 * Bolds a matching string.
 * Note: must use this in innerHtml.
 */
@Pipe({ name: 'highlightMatch' })
export class HighlightMatchPipe implements PipeTransform {
    transform(value: string, search: string): string {
        if (value === undefined || value === null || search.trim() === '') {
            return value;
        }
        // Case insentive multi-match
        const regExp = new RegExp(search, 'ig');
        return value.replace(regExp, '<strong>$&</strong>');
    }
}
