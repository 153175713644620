import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FloatingCardComponentModule } from '../floating-card/floating-card.component.module';
import { ImagePreloaderComponentModule } from '../image-preloader/image-preloader.component.module';
import { ContactCardComponent } from './contact-card.component';

@NgModule({
  imports: [
    CommonModule,
    ImagePreloaderComponentModule,
    FloatingCardComponentModule,
    IonicModule.forRoot(),
  ],
  declarations: [ContactCardComponent],
  exports: [ContactCardComponent],
})
export class ContactCardComponentModule { }
