import { Injectable } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService {

  constructor(readonly menuController: MenuController) { }

  close(): Observable<boolean> {
    return from(this.menuController.close(this.getId()));
  }

  toggle(): Observable<boolean> {
    return from(this.menuController.toggle(this.getId()));
  }

  open(): Observable<boolean> {
    return from(this.menuController.open(this.getId()));
  }

  getId(): string {
    return 'proposal-menu';
  }
}
