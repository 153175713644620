import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { modelDebounce, modelThrottle, ContextualImageSet } from '@didgigo/lib-ts';
import { ToastController } from '@ionic/angular';
import { combineLatest, from, Observable } from 'rxjs';
import { flatMap, map, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../lib-ionic/base-component';
import { UpdatingStorage } from '../../lib-ionic/updating-storage';
import { AssetService } from '../../services/asset.service';
import { LoadingMonitorService } from '../../services/loading-monitor.service';
import { LoggingService } from '../../services/logging.service';
import { NavigatorService } from '../../services/navigator.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';

export class SettingsModel {
  constructor(
    readonly canDownloadHighRes: boolean,
    readonly isMetric: boolean,
    readonly canDownloadHighResOnMobile: boolean) { }
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.page.html',
  styleUrls: ['./settings.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsPage extends BaseComponent implements OnInit {

  constructor(
    readonly settings: UserSettingsService,
    readonly toast: ToastController,
    readonly logger: LoggingService,
    readonly navigator: NavigatorService,
    readonly assets: AssetService,
    readonly user: UserService,
    readonly loading: LoadingMonitorService,
    readonly change: ChangeDetectorRef,
    readonly self: ElementRef,
    readonly storage: UpdatingStorage) {
    super('settings_page', change, self, loading);
  }

  clear: Promise<boolean> = this.storage.isClear();

  model: Observable<SettingsModel> = this.getModel().pipe(modelDebounce(this.unsubscriberObs));

  async clearCache(): Promise<void> {
    await this.storage.clear();
    const dialog = await this.toast.create({
      message: 'Cache successfully cleared',
      duration: 2000,
      position: 'bottom',
    });
    dialog.present();
    this.navigator.gotoItineraries();
  }

  getImages(): ContextualImageSet {
    return this.assets.getBlueBackground();
  }

  private getModel(): Observable<SettingsModel> {
    return combineLatest([
      this.settings.canDownloadHighRes(),
      this.settings.isMetric(),
      this.settings.canDownloadHighResOnMobile()])
      .pipe(map(arr => new SettingsModel(arr[0], arr[1], arr[2])))
      .pipe(shareReplay(1));
  }

  ngOnInit(): void {
    this.logger.setPage('settings');
    this.logger.logEventWithUser(
      'load_page',
      { page: 'contact' },
      this.user.currentIdentity);
    super.ngOnInit();
  }

  toggleCanDownloadHighRes(e: any): void {
    this.settings.setCanDownloadHighRes(e.target.checked);
  }

  toggleCanDownloadHighResOnMobile(e: any): void {
    this.settings.setCanDownloadHighResOnMobile(e.target.checked);
  }

  toggleMetrics(e: any): void {
    this.settings.setIsMetric(e.target.checked);
  }
}
