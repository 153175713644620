import {Injectable} from '@angular/core';
import {DelegatingCrud, ProposalWithMeta, ProposalWithMetaFallbackToProposalJsonSerializer} from '@didgigo/lib-ts';
import {StringCacheProviderService} from './cache-provider.service';
import {ConfigurationService} from './configuration.service';
import {MediaService} from './media.service';
import {RestService} from './rest.service';

@Injectable({
    providedIn: 'root',
})
export class ProposalService extends DelegatingCrud<string, ProposalWithMeta> {

    constructor(
        readonly rest: RestService,
        readonly configuration: ConfigurationService,
        readonly media: MediaService,
        cache: StringCacheProviderService,
    ) {
        super(cache.getPrefixedSerializableCache('proposal', ProposalWithMetaFallbackToProposalJsonSerializer.instance));
    }
}
