import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FloatingCardComponentModule } from '../floating-card/floating-card.component.module';
import { HeaderSpacerComponentModule } from '../header-spacer/header-spacer.component.module';
import { UserIconComponentModule } from '../user-icon/user-icon.component.module';
import { ProposalMenuComponent } from './proposal-menu.component';

@NgModule({
  imports: [
    CommonModule,
    HeaderSpacerComponentModule,
    FloatingCardComponentModule,
    UserIconComponentModule,
    IonicModule.forRoot(),
  ],
  declarations: [ProposalMenuComponent],
  exports: [ProposalMenuComponent],
})
export class ProposalMenuComponentModule { }
