import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ProposalEntryWithMeta} from '@didgigo/lib-ts';
import {ModalController} from '@ionic/angular';
import {None, Option} from 'funfix-core';
import {NavigatorService} from '../../services/navigator.service';
import {MapMode} from '../map/map.component';

@Component({
    selector: 'app-map-marker-modal',
    templateUrl: './map-marker-modal.component.html',
    styleUrls: ['./map-marker-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapMarkerModalComponent {

    constructor(
        readonly modal: ModalController,
        readonly navigator: NavigatorService,
    ) {
    }

    @Input()
    mapType: MapMode = 'Product';

    @Input()
    model: ProposalEntryWithMeta;

    @Input()
    parentEntry: Option<ProposalEntryWithMeta> = None;
}
