import {Injectable} from '@angular/core';
import {LatLongLocation} from '@didgigo/lib-ts';
import {Platform} from '@ionic/angular';
import {None, Option, Some} from 'funfix-core';

import { Plugins } from '@capacitor/core';

const { Geolocation } = Plugins;

@Injectable({
    providedIn: 'root',
})
export class LocationService {

    constructor(readonly platform: Platform) {
    }

    async getLatLongLocation(): Promise<Option<LatLongLocation>> {
        if (this.platform.is('cordova')) {
            const position = await Geolocation.getCurrentPosition();
            return Some(new LatLongLocation(Some(position.coords.latitude), Some(position.coords.longitude)));
        }

        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                resolve(None);
            }

            navigator.geolocation.getCurrentPosition(position => {
                resolve(Some(new LatLongLocation(Some(position.coords.latitude), Some(position.coords.longitude))));
            }, err => reject(err));
        });
    }
}
