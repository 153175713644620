import {Injectable} from '@angular/core';
import {Company, Product, User} from '@didgigo/lib-ts';
import {Either, Option} from 'funfix-core';
import {List} from 'immutable';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay} from 'rxjs/operators';
import {DidgigoApiService} from './didgigo-api-service';

@Injectable({
    providedIn: 'root',
})
export class UserService {

    constructor(readonly api: DidgigoApiService) {
    }

    userState: BehaviorSubject<User> = new BehaviorSubject(new User());

    getCurrentUser(): User {
        return this.userState.getValue();
    }

    getLoggedInUserManagedCompanies(): Observable<List<Company>> {
        return this.userState
            .pipe(distinctUntilChanged())
            .pipe(map(x => x.accessibleCompanies))
            .pipe(shareReplay(1));
    }

    getUsingAsCompany(): Observable<Company> {
        return this.userState
            .pipe(filter(x => x.isReady()))
            .pipe(map(x => x.usingAs.get()))
            .pipe(shareReplay(1));
    }

    getUsingAsCompanyId(): Observable<Option<number>> {
        return this.getUsingAsCompany()
            .pipe(map(x => x.getId()));
    }

    isCurrentUserAgent(): boolean {
        return this.getCurrentUser().isAgent();
    }

    isCurrentUserCompanyAdmin(): boolean {
        return this.getCurrentUser().isCompanyAdmin();
    }

    isCurrentUserSystemManager(): boolean {
        return this.getCurrentUser().isSystemManager();
    }

    isProductEditableByCurrentUser(p: Product): boolean {
        return this.getCurrentUser().isProductEditable(p);
    }

    private isValidRoleForProductivitySuite(u: User): boolean {
        return u.role.exists(x => x === 'System_Manager' || x === 'Proposal_Agent' || x === 'Company_Admin');
    }

    async login(email: string, password: string): Promise<Either<string, User>> {
        const user = await this.api.getDidgigoUser(email, password);
        const validUser = user.filterOrElse(u => this.isValidRoleForProductivitySuite(u), () => 'User not permitted to use productivity suite');
        validUser.forEach(x => this.userState.next(x.company.map(c => x.withUsingAs(c)).getOrElse(x)));
        return validUser;
    }

    switchUsingAsCompany(company: Company): void {
        const value = this.userState.getValue();

        if (value.getLoggedInCompany().contains(company)) {
            return;
        }
        this.userState.next(value.useAsCompany(company));
    }
}
