import {Injectable} from '@angular/core';
import {NavigationEnd, NavigationExtras, Router} from '@angular/router';
import {
  Image,
  isTomorrowBetween,
  Metadata,
  modelPassthrough,
  ProposalEntryWithMeta,
  ProposalWithMeta,
  today,
  tomorrow,
} from '@didgigo/lib-ts';
import {NavController} from '@ionic/angular';
import {None, Option, Some} from 'funfix-core';
import {List, Set} from 'immutable';
import {Moment} from 'moment';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import {filter, map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {ChatService} from './chat.service';
import {LoadingMonitorService} from './loading-monitor.service';
import {LoggingService} from './logging.service';
import {ProposalService} from './proposal.service';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root',
})
export class NavigatorService {

  constructor(
      readonly proposalService: ProposalService,
      readonly router: Router,
      readonly loadingMonitor: LoadingMonitorService,
      readonly user: UserService,
      readonly chat: ChatService,
      readonly logging: LoggingService,
      readonly nav: NavController) {

    this.proposalObservable = this.observeProposalId()
        .pipe(switchMap(id => {
          return id.map(i => this.proposalService.observe(i))
              .getOrElse(of(None));
        }))
        .pipe(tap((x: Option<ProposalWithMeta>) => x.forEach(v => this.logging.currentProposal.next(v))))
        .pipe(modelPassthrough());
  }

  eid: BehaviorSubject<Option<number>> = new BehaviorSubject(None);

  eidObservable: Observable<Option<number>> =
    this.eid.asObservable()
      .pipe(modelPassthrough());

  pid: BehaviorSubject<Option<string>> = new BehaviorSubject(None);

  pidObservable: Observable<Option<string>> =
    this.pid.asObservable()
      .pipe(modelPassthrough());

  proposalObservable: Observable<Option<ProposalWithMeta>>;

  private routingObservable: Observable<NavigationEnd> =
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(event => event as NavigationEnd))
      .pipe(tap(_ => this.user.markCurrentlyActive()))
      .pipe(self => { self.subscribe(); return self; }); // hack: ensure we always mark active

  canNavigate(): boolean {
    return this.loadingMonitor.canNavigate();
  }

  clearIds(): void {
    this.pid.next(None);
    this.eid.next(None);
  }

  async getCurrentEntry(): Promise<Option<ProposalEntryWithMeta>> {
    const proposal = await this.getCurrentProposalNonOptional();
    const entryId = await this.getCurrentEntryId();
    return entryId.flatMap(id => proposal.getProposalEntryById(id));
  }

  async getCurrentEntryForIdNonOptional(pid: number, eid: number): Promise<ProposalEntryWithMeta> {
    const proposal = await this.getCurrentProposalForId(pid);
    const entry = proposal.flatMap(v => v.getProposalEntryById(eid));
    if (entry.isEmpty()) {
      throw new Error(`Proposal ${pid} did not have entry of id ${eid}`);
    }

    return entry.get();
  }

  getCurrentEntryId(): Promise<Option<number>> {
    return this.eidObservable
        .pipe(take(1))
        .toPromise();
  }

  async getCurrentEntryNonOptional(): Promise<ProposalEntryWithMeta> {
    const entry = await this.getCurrentEntry();
    return entry.getOrElse(new ProposalEntryWithMeta());
  }

  getCurrentProposal(): Promise<Option<ProposalWithMeta>> {
    return this.proposalObservable
        .pipe(take(1))
        .toPromise();
  }

  getCurrentProposalForId(id: number): Promise<Option<ProposalWithMeta>> {
    return this.proposalObservable
        .pipe(filter((x: Option<ProposalWithMeta>) => x.exists(v => v.getProposalId().contains(id))))
        .pipe(take(1))
        .toPromise();
  }

  async getCurrentProposalForIdNonOptional(id: number): Promise<ProposalWithMeta> {
    const proposal = await this.getCurrentProposalForId(id);
    return proposal.getOrElse(new ProposalWithMeta());
  }

  getCurrentProposalId(): Observable<Option<string>> {
    return this.pidObservable
        .pipe(take(1));
  }

  async getCurrentProposalNonOptional(): Promise<ProposalWithMeta> {
    const currentProposal = await this.getCurrentProposal();
    return currentProposal.getOrElse(new ProposalWithMeta());
  }

  async getCurrentWelcomeImage(): Promise<Option<Image>> {
    const currentProposal = await this.getCurrentProposal();
    return currentProposal.flatMap(x => x.proposal).flatMap(p => p.welcome).flatMap(w => w.image);
  }

  async getMeta(): Promise<Metadata> {
    const proposal = await this.getCurrentProposalNonOptional();
    return proposal.getMeta();
  }

  private getOngoingProposals(proposals): List<ProposalWithMeta> {
    return proposals
        .filter(e => e.v.getProposal().isCurrentlyInProgress())
        .map(e => e.v);
  }

  private getTomorrowsProposals(proposals): List<ProposalWithMeta> {
    return proposals
        .filter(e => isTomorrowBetween(e.v.getProposal().getStartDateTime(), e.v.getProposal().getEndDateTime()))
      .map(e => e.v);
  }

  goBack(): void {
    return this.nav.back({animated: true, animationDirection: 'back'});
  }

  gotoAbout(): void {
    this.navigateForwardFromProposalId('about_page', id => ['/about', id]);
  }

  gotoAdmin(): void {
    this.navigateForward('admin_page', ['/admin']);
  }

  gotoAgent(): void {
    this.navigateForward('agent_page', ['/agent']);
  }

  gotoBestPage(isAppLoad: boolean = false): void {
    this.proposalService.entries()
      .then(proposals => {
        if (proposals.isEmpty()) {
          this.gotoItineraries();
        } else {
          if (this.getOngoingProposals(proposals).size === 1) {
            const first: ProposalWithMeta = this.getOngoingProposals(proposals).first();
            this.setProposalId(first.getProposalId().getOrElse(-1).toString());
            this.user.currentIdentity
              .pipe(take(1))
              .pipe(filter(ou => isAppLoad || ou.exists(u => !u.wasActiveToday())))
              .subscribe(_ => this.gotoToday());
          } else if (this.getTomorrowsProposals(proposals).size === 1) {
            const first: Option<ProposalWithMeta> = Option.of(this.getTomorrowsProposals(proposals).first());
            this.setProposalId(first.flatMap(x => x.getProposalId()).getOrElse(-1).toString());
            this.user.currentIdentity
              .pipe(take(1))
              .pipe(filter(ou => isAppLoad || ou.exists(u => !u.wasActiveToday())))
              .subscribe(_ => this.gotoTomorrow());
          } else if (proposals.size === 1) {
            const first: ProposalWithMeta = proposals.map(e => e.v).first();
            this.setProposalId(first.getProposalId().getOrElse(-1).toString());
            this.user.currentIdentity
              .pipe(take(1))
              .pipe(filter(ou => isAppLoad || ou.exists(u => !u.wasActiveToday())))
              .subscribe(_ => this.gotoWelcome());
          } else {
            this.gotoItineraries();
          }
        }
      });
  }

  gotoChangelog(): void {
    this.navigateForward('changelog_page', ['/changelog']);
  }

  gotoChat(room): void {
    this.navigateForward('chat_page', ['/chat', room]);
  }

  gotoContact(): void {
    this.navigateForwardFromProposalId('contact_page', id => ['/contact', id]);
  }

  gotoDayByDay(): Promise<void> {
    return this.navigateForwardFromProposal('day_page', p => ['/day', p.getProposalId().get(), p.getProposal().getStartDay().getOrElse(today()).format()]);
  }

  gotoDayByDayForDate(m: Moment): Promise<void> {
    return this.navigateForwardFromProposal('day_page', p => ['/day', p.getProposalId().get(), m.format()]);
  }

  gotoDayMap(day: Moment): void {
    this.navigateForwardFromProposalId('day_map', id => ['/day-map', id, day.format()]);
  }

  gotoDownload(): void {
    this.navigateForwardFromProposalId('download_page', id => ['/download', id], {skipLocationChange: true});
  }

  gotoEntry(id: number, eid: number): void {
    this.navigateForward('entry_page', ['/entry', id, eid]);
  }

  gotoHelpfulHints(): void {
    this.navigateForwardFromProposalId('helpful_hints_page', id => ['/helpful-hints', id]);
  }

  gotoImportantContacts(): void {
    this.navigateForwardFromProposalId('important_contacts_page', id => ['/important-contacts', id]);
  }

  gotoItineraries(direction: 'back' | 'forward' = 'back'): void {
    this.nav.navigateRoot(['/proposals'], {animated: true, animationDirection: direction, replaceUrl: true})
        .then(_ => this.loadingMonitor.endNavigation('proposal_page'));
  }

  gotoMap(): void {
    this.navigateForwardFromProposalId('map_page', id => ['/map', id]);
  }

  gotoMessages(): void {
    this.navigateForward('messages_page', ['/messages']);
  }

  gotoPricing(): void {
    this.navigateForwardFromProposalId('pricing_page', id => ['/prices', id]);
  }

  gotoPrivacyPolicy(): void {
    this.navigateForward('privacy_policy_page', ['/privacy']);
  }

  gotoPrivateMessage(people: Set<string>): void {
    this.user.currentIdentity
      .pipe(take(1))
      .pipe(switchMap(id =>
        this.chat.createPrivateMessageRoomIfNotPresent(id.flatMap(u => u.identity).getOrElse('?'), people)))
      .subscribe(rm => this.navigateForward('chat_page', ['/chat', rm]));
  }

  gotoProposalTerms(): void {
    this.navigateForwardFromProposalId('proposal_terms_page', id => ['/proposal-terms', id]);
  }

  gotoSettings(): void {
    this.navigateForward('settings_page', ['/settings']);
  }

  gotoSnapshot(): void {
    this.navigateForwardFromProposalId('snapshot_page', id => ['/snapshot', id]);
  }

  gotoSubproduct(id: number, eid: number, spid: number): void {
    this.navigateForward('entry_page', ['/entry', id, eid, spid]);
  }

  gotoSubproducts(id: number, eid: number): void {
    this.navigateForward('subproducts_page', ['/snapshot-subproducts', id, eid]);
  }

  gotoTermsAndConditions(): void {
    this.navigateForward('terms_and_conditions_page', ['/terms']);
  }

  gotoToday(): void {
    this.navigateForwardFromProposalId('day_page', id => ['/day', id, today().format()]);
  }

  gotoTomorrow(): void {
    this.navigateForwardFromProposalId('day_page', id => ['/day', id, tomorrow().format()]);
  }

  gotoTripigo(): void {
    this.navigateForward('tripigo_page', ['/tripigo']);
  }

  gotoWelcome(root: boolean = false): void {
    if (root) {
      this.navigateRootFromProposalId(id => ['/welcome', id]);
    } else {
      this.navigateForwardFromProposalId('welcome_page', id => ['/welcome', id]);
    }
  }

  isActivePage(url: string, exact: boolean = true): boolean {
    return this.router.isActive(url, exact);
  }

  private navigateForward(page: string, path: any[], extras?: NavigationExtras): void {
    this.loadingMonitor.startNavigation(page);
    const animation = {animated: true, animationDirection: 'left'};
    const opts: any = {...animation, ...extras};
    from(this.nav.navigateForward(path, opts))
        .subscribe(_ => this.loadingMonitor.endNavigation(page));
  }

  private async navigateForwardFromProposal(page: string, pathGen: (p: ProposalWithMeta) => any[], extras?: NavigationExtras): Promise<void> {
    const proposal = await this.getCurrentProposalNonOptional();
    this.navigateForward(page, pathGen(proposal), extras);
  }

  private navigateForwardFromProposalId(page: string, pathGen: (string) => any[], extras?: NavigationExtras): void {
    this.getCurrentProposalId()
        .pipe(filter(id => id.nonEmpty()))
        .subscribe(id => this.navigateForward(page, pathGen(id.get()), extras));
  }

  private navigateRootFromProposalId(pathGen: (string) => any[]): void {
    this.getCurrentProposalId()
        .pipe(filter(id => id.nonEmpty()))
        .subscribe(id => this.nav.navigateRoot(pathGen(id.get()), {animated: true, animationDirection: 'forward', replaceUrl: true}));
  }

  observeCanNavigate(): Observable<boolean> {
    return this.loadingMonitor.observeCanNavigate();
  }

  observeCurrentProposal(): Observable<Option<ProposalWithMeta>> {
    return this.proposalObservable;
  }

  observeCurrentProposalNonOptional(): Observable<ProposalWithMeta> {
    return this.proposalObservable.pipe(map(x => x.getOrElse(new ProposalWithMeta())));
  }

  observeEntryId(): Observable<Option<number>> {
    return this.eidObservable;
  }

  observeIsActivePage(url: string, exact: boolean = true): Observable<boolean> {
    return this.routingObservable
      .pipe(map(_ => this.router.isActive(url, exact)));
  }

  observeMeta(): Observable<Metadata> {
    return this.observeCurrentProposalNonOptional()
        .pipe(map(x => x.getMeta()))
        .pipe(shareReplay(1));
  }

  observeProposalId(): Observable<Option<string>> {
    return this.pidObservable;
  }

  setEntryId(id: number): void {
    this.eid.next(Some(id));
  }

  setProposalId(id: string): void {
    this.pid.next(Some(id));
  }
}
