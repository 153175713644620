import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormatterPipeModule} from '../../pipes';
import {SmartTextAreaComponent} from './smart-text-area.component';

@NgModule({
  imports: [
    CommonModule,
    FormatterPipeModule,
  ],
  declarations: [SmartTextAreaComponent],
  exports: [SmartTextAreaComponent],
})
export class SmartTextAreaComponentModule { }
