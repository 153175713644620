import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ContactModel, ContextualImageSet, Image, ProposalWithMeta} from '@didgigo/lib-ts';
import {AssetService} from '../../services/asset.service';
import {BrowserService} from '../../services/browser.service';
import {ContactService} from '../../services/contact.service';
import {MediaService} from '../../services/media.service';
import {NavigatorService} from '../../services/navigator.service';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactCardComponent implements OnInit {

  constructor(
      readonly browser: BrowserService,
      readonly assets: AssetService,
      readonly media: MediaService,
      readonly contact: ContactService,
      readonly navigator: NavigatorService) {
  }

  meta: ProposalWithMeta;

  @Input()
  model: ContactModel;

  getImage(s: string, type: 'Agent' | 'Company' | 'Supplier' | 'Product'): ContextualImageSet {
    switch (type) {
      case 'Agent':
        return this.media.getAgentImage(s);
      case 'Company':
        return this.media.getProposalCompanyLogo(s);
      case 'Supplier':
        return this.media.getSupplierLogo(Image.fromString(s));
      case 'Product':
        return this.media.getProductLogo(Image.fromString(s));
    }
  }

  async ngOnInit(): Promise<void> {
    this.meta = await this.navigator.getCurrentProposalNonOptional();
  }
}
